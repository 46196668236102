import { faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import styles from './../CompleteProfile.module.css';
import DocumentPreviewWrapper from './DocumentPreviewWrapper';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
// import PreviewFile from '../../../../GlobalComps/PreviewFile';
import { toast } from 'react-toastify';

export default function ProfileDocuments({ adminView, setdocumentsAcceptTnc, setFormData, FormData, mode }) {
    const [formData, setformData] = useState({});
    console.log("formdata==>", FormData)

    const allowedTypesport = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "video/mp4",
        "video/quicktime",
        "video/x-msvideo",
        "video/x-flv",
        "video/x-ms-wmv",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/msword", // MIME type for Word (.doc) files
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MIME type for Word (.docx) files
    ];

    const handleChangePortfolio = (e) => {

        if (e.target.type === "file") {
            const file = e.target.files[0];
            if (!allowedTypesport.includes(file.type)) {
                toast.error("Please upload a JPG, PNG, Word, PPT or PDF file.");
                e.target.value = "";
                return;
            }

            let allowedSize;
            let totalSize;
            const filesArray = Array.from(e.target.files);

            if (e.target.name === "portfolio") {
                allowedSize = 1024 * 1024 * 100; // 100MB
                totalSize = filesArray?.reduce((acc, curr) => acc + curr.size, 0);
            } else {
                allowedSize = 1024 * 1024 * 2; // 2MB
                totalSize = filesArray?.reduce((acc, curr) => acc + curr.size, 0);
            }

            if (totalSize > allowedSize) {
                toast.error(
                    `File(s) size exceeds limit. Please upload files smaller than ${allowedSize / (1024 * 1024)} MB.`
                );
                e.target.value = "";
                return;
            } else {
                if (filesArray.length < 3 && e.target.name === "portfolio") {
                    toast.error("minimum 3 file required!");
                    return;
                } else {
                    if (filesArray.length > 2 && e.target.name === 'certificate') {
                        toast.error("maximum 2 file required!");
                        return;
                    }
                    else {
                        // Updating local state
                        setformData({ ...formData, [e.target.name]: filesArray });
                        // Updating Parent State
                        setFormData(e.target.name, filesArray);
                        // setFileFieldUpdated(true);
                        return;
                    }
                }
            }
        }
    };

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            // Updating local state
            setformData({ ...formData, [e.target.name]: e.target.files[0] });

            // Updating Parent State
            setFormData(e.target.name, e.target.files[0]);
            return;
        }

        // Updating local state
        setformData({ ...formData, [e.target.name]: e.target.value });

        // Updating Parent State
        setFormData(e.target.name, e.target.value);
    }

    const handleRemove = ({ name }) => {
        // Updating local state
        setformData({ ...formData, [name]: null });

        // Updating Parent State
        setFormData(name, null);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <Typography variant="h5" className="fw-bold mb-2">Profile Add-Ons, Document and Terms</Typography>
            <form>
                <div className="mt-4">
                    <DocumentPreviewWrapper fileData={FormData?.portfolio}  handleRemove={()=>handleRemove({name:"portfolio"})}>
                        <Typography className={styles.inputTypograph}>Portfolio/ your sample work: Attach any Video/ Portfolio/ PPT/ PDF/ Images that may augment your profile or can showcase the quality of your work.
                            <strong>(must not contain Business name or contact details or any external links (max 100 MB add minimum 3 files))</strong>
                        </Typography>
                        {(FormData?.portfolio === null || FormData?.portfolio === "" || FormData?.portfolio === "null" || FormData?.portfolio === "N/A" || FormData?.portfolio === "undefined" || FormData?.portfolio === undefined) ? (
                            <input
                                id="portfolio-input"
                                type="file"
                                onChange={handleChangePortfolio}
                                name="portfolio"
                                multiple
                            />
                        ) : (
                            <Typography style={{ color: 'blue' }}>
                                {
                                    Array.isArray(FormData?.portfolio)
                                    ? <><FontAwesomeIcon icon={faFolder} /> {FormData?.portfolio?.length} Files Uploaded</>
                                    : <><FontAwesomeIcon icon={faFile} /> Portfolio Uploaded</>
                                }
                            </Typography>
                        )}


                    </DocumentPreviewWrapper>

                </div>

                <div className="mt-4">
                    <Typography className={styles.inputTypograph}>Submit Documents</Typography>
                    <p className='text-sm md:text-base'>
                        MrSolvo is committed to provide best and most genuine solutions to all its
                        customers and to ensure that, we allow only authentic/ verified consultants &amp; Service
                        Providers with true identity and enough capabilities and experience to provide the
                        proposed services. As such we require the following documents:
                    </p>
                </div>

                {/* ==== Id Proof Documents ===== */}
                <div className="mt-4">
                    <Typography className={styles.inputTypograph}>
                        <b>1. Identity Proof Documents</b>
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            {console.log("FORM DATA", FormData.profilePhoto)}
                            <DocumentPreviewWrapper fileData={FormData?.profilePhoto} handleRemove={() => handleRemove({ name: "profilePhoto" })}>
                                <Typography className={styles.inputTypograph}>Your Profile Photo  *</Typography>
                                {(FormData?.profilePhoto === null || FormData?.profilePhoto === "" || FormData?.profilePhoto === "null" || FormData?.profilePhoto === "N/A" || FormData?.profilePhoto === "undefined" || FormData?.profilePhoto === undefined)
                                    ?
                                        <input
                                            id="profile-photo-input"
                                            type="file"
                                            onChange={handleChange}
                                            name="profilePhoto"
                                        />
                                    :
                                        <Typography style={{ color: 'blue' }}>
                                            <FontAwesomeIcon icon={faFile} /> Profile Photo Uploaded
                                        </Typography>
                                }
                            </DocumentPreviewWrapper>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Grid item sm={12}>
                                <TextField type="input" name="panNumber" value={FormData?.panNumber} onChange={handleChange} label="PAN Number" />
                            </Grid>
                            <Grid item sm={12}>
                            <DocumentPreviewWrapper fileData={FormData?.identityProof} handleRemove={() => handleRemove({ name: "identityProof" })}>
                                <Typography className={styles.inputTypograph}>Your Photo Identity Proof *</Typography>
                                {(FormData?.identityProof === null || FormData?.identityProof === "" || FormData?.identityProof === "null" ||FormData?.identityProof ==="N/A" || FormData?.identityProof === "undefined" || FormData?.identityProof === undefined)
                                    ? <input
                                        id="identity-Proof-input"
                                        type="file"
                                        onChange={handleChange}
                                        name="identityProof"
                                        multiple
                                    />
                                    :
                                    <Typography style={{ color: 'blue' }}>
                                        <FontAwesomeIcon icon={faFile} /> Identity Proof Uploaded
                                    </Typography>
                                }
                            </DocumentPreviewWrapper>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <DocumentPreviewWrapper fileData={FormData?.selfie} handleRemove={() => handleRemove({ name: "selfie" })}>
                                <Typography className={styles.inputTypograph}>Selfie *</Typography>
                                {(FormData?.selfie === null || FormData?.selfie === "" || FormData?.selfie === "null" || FormData?.selfie === "N/A" || FormData?.selfie === "undefined" || FormData?.selfie === undefined)
                                    ? <input
                                        id="selfie-input"
                                        type="file"
                                        onChange={handleChange}
                                        name="selfie"
                                    />
                                    :
                                    <Typography style={{ color: 'blue' }}>
                                        <FontAwesomeIcon icon={faFile} /> Selfie Uploaded
                                    </Typography>
                                }
                            </DocumentPreviewWrapper>
                        </Grid>
                    </Grid>
                </div>

                <div className="mt-4">
                    <Typography className={styles.inputTypograph}>
                        <b>2. Capability/ Eligibility Mapping Documents</b>
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <DocumentPreviewWrapper fileData={FormData?.certificate} handleRemove={() => handleRemove({ name: "certificate" })}>
                                <Typography className={styles.inputTypograph}><b>Address Proof</b> (DL/ Aadhaar Card (Front + Back) OR Any Utility bill OR Any other Bill)</Typography>
                                {(FormData?.certificate === null || FormData?.certificate === "" || FormData?.certificate === "null" ||FormData?.certificate ==="N/A" || FormData?.certificate === "undefined" || FormData?.certificate === undefined)
                                    ? (
                                        <input
                                            id="certificate-input"
                                            type="file"
                                            onChange={handleChangePortfolio}
                                            name="certificate"
                                            accept="image/*"
                                            multiple
                                        />

                                    )
                                    : (
                                        <Typography style={{ color: 'blue' }}>
                                            <FontAwesomeIcon icon={faFile} /> Address Proof Uploaded
                                        </Typography>
                                    )
                                }


                            </DocumentPreviewWrapper>

                            <div className='col-span-7'>
                                <Typography className={`text-sm md:text-base mt-8`}>
                                    {/* <ReqStar /> */}
                                    Your professional registration/ certification no. or id:
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    // disabled={qualificationOption}
                                    className="mt-2"
                                    value={FormData?.certificationId}
                                    onChange={handleChange}
                                    id="outlined-basic"
                                    InputLabelProps={{ shrink: true }}
                                    label="Certification/ Registeration Id"
                                    name="certificationId"
                                    variant="standard" />
                            </div>

                            <div className="col-span-4">
                                <Typography className={`text-sm md:text-base mt-8 }`}>
                                    {/* <ReqStar /> */}
                                    Year of passing/registration
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    // required={qualificationOption}
                                    // disabled={!qualificationOption}
                                    className="mt-2"
                                    InputLabelProps={{ shrink: true }}
                                    value={FormData?.yearOfPassing}
                                    inputProps={{ maxLength: 4 }}
                                    onChange={handleChange}
                                    type="text"
                                    id="outlined-basic"
                                    label="Year Of Passing"
                                    name="yearOfPassing"
                                    variant="standard" />
                            </div>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <DocumentPreviewWrapper fileData={FormData?.companyResume} handleRemove={() => handleRemove({ name: "companyResume" })}>
                                {/* {console.log("vinay=>", !(FormData?.companyResume !== null && FormData?.companyResume !== "" && FormData?.companyResume !== "null"))} */}
                                <Typography className={styles.inputTypograph}>Professional/Company Profile or Resume (with company name etc)<br /></Typography>
                                <br />
                                {(FormData?.companyResume === null || FormData?.companyResume === "" || FormData?.companyResume === "null" ||FormData?.companyResume ==="N/A" || FormData?.companyResume === "undefined" || FormData?.companyResume === undefined)
                                    ? (
                                        <input
                                            id="company-resume-input"
                                            type="file"
                                            onChange={handleChange}
                                            name="companyResume"
                                        />
                                    )
                                    : (
                                        <Typography style={{ color: 'blue' }}>
                                            <FontAwesomeIcon icon={faFile} /> Company resume Uploaded
                                        </Typography>
                                    )
                                }

                            </DocumentPreviewWrapper>
                        </Grid>
                    </Grid>
                </div>

                {/* <div className="mt-4">
                    <Typography className={styles.inputTypograph}>Website/ LinkedIn URL/ Facebook Page Link</Typography>
                    <TextField id="outlined-basic"
                        fullWidth={true}
                        variant="standard"
                        onChange={handleChange}
                        label="Url"
                        name="websiteLink"
                        value={FormData?.websiteLink || ''}
                        type="text" />
                </div> */}

                {/* ===== GST Fields ==== */}
                <Fragment>
                    <div className="mt-4">
                        <Typography className={styles.inputTypograph}>Do you have GST registration? If yes, please provide:</Typography>
                        <TextField id="outlined-basic"
                            fullWidth={true}
                            name="gstNo"
                            value={FormData?.gstNo || ''}
                            label="GST No."
                            variant="standard"
                            onChange={handleChange}
                            type="text" />
                    </div>
                    <div className="mt-4">
                        <DocumentPreviewWrapper fileData={FormData?.gstCertificate} handleRemove={() => handleRemove({ name: "gstCertificate" })}>
                            <Typography className={styles.inputTypograph}>Copy of GST certificate: </Typography>
                            <TextField id="outlined-basic"
                                fullWidth={true}
                                name="gstCertificate"
                                // value={FormData?.gstCertificate || ''}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                onChange={handleChange}
                                type="file" />
                            <span style={{ fontSize: '11px' }}>(In case you have sales turn over of more than 20 lakhs per annum, GST registeration is a must to offer your
                                services on this platform)</span>
                        </DocumentPreviewWrapper>
                    </div>
                </Fragment>
            </form>
        </div>
    )
}
