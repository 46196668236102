import React, { useEffect, useState } from 'react';
import { GetAllCategories } from '../../API/CategoryAPI';
import { GetSettings } from '../../API/Settings';
// import { useSelector } from 'react-redux';
import AdminContext from './AdminContext';


const AdminState =(props)=> {
    const [settings, setsettings] = useState({});
    const [Categories, setCategories] = useState([]);

    const [adminUserData, setadminUserData] = useState({});

    //To stay logged In
    // const [adminUserData, setadminUserData] = useState(localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : {});

    const fetchSettings = async () =>{
		const resp = await GetSettings();
		if(resp){
			setsettings({...resp.data.data});
		}
	}

    const fetchAllCategories = async () => {
        console.log("AdminContext")
        const resp = await GetAllCategories('yes');
        if(resp){
            setCategories({...resp});
        }
    }

    useEffect(() => {
        fetchSettings();
        fetchAllCategories();
    }, [])

    const logOutAdmin = () => {

        setadminUserData({})
        localStorage.removeItem('adminInfo')
    }
    
    
    const state = {
        settings:settings,
        AllCategories: Categories,
        setadminUserData,
        adminUserData: adminUserData,
        logOutAdmin: logOutAdmin
    }

    return (
        <AdminContext.Provider value={{...state}}>
          {props.children}
        </AdminContext.Provider>
    );
  }
  
  export default AdminState;