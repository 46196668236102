// import styles from './../../provider/completeProfile/CompleteProfile.module.css';
import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
// import ClientContext from "../../../ContextAPI/ClientContext";
import styles from "./../CompleteProfile.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeItemFromArray } from "../../../../GlobalComps/Helper";
import DropdownSelect from "../../../../GlobalComps/DropdownSelect";
import { deliveryTimeArr } from "../../../../GlobalComps/Constant";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import DropdownGroupMultiselect from "../../../../GlobalComps/DropdownGroupMultiselect";

function AddConsultations(props) {
	const [State, setState] = useState({
		Categories: [],
		process: false,
		serviceTypes: ["Consultation", "Service", "AddOnService", "Retainership"],
		UserData:{},
		ServiceImgDisplay:"",
		FormData:[],
		ConsultationOfferings:[
			...props.offeringSetting
		]
  })

  const formChange = (name, val, index) => {
	let FormDataArr = State.FormData;
	FormDataArr[index] = {...FormDataArr[index], [name] : val}
	// setState({ FormData: [...FormDataArr || []] });
	props.updateState([...FormDataArr || []])
  }

  const addNewConsultationRecord = () => {
	if(State.FormData.length > 10){
		Swal.fire({
			title:"Max 10 Consultations are Allowed",
			icon:"error"
		});
		return;
	}
	setState(prevState => ({
		...prevState,
		FormData: [...prevState.FormData, {offerings:"", indiaPrice: ""}]
	}));
	props.updateState([...State?.FormData || [], {offerings:'', indiaPrice: ''}])
  }

  const removeServiceRecord = (index) => {
	let formData = State?.FormData;
	let newFormData = removeItemFromArray(formData, index, "index");
	setState(prevState => ({
		...prevState,
		FormData: [...newFormData]
	}));
	props.updateState([...newFormData])
  }


  useEffect(() => {
	try{
		const staticInitData = [
			{offerings:"", indiaPrice: ""},
			{offerings:"", indiaPrice: ""},
			{offerings:"", indiaPrice: ""},
			{offerings:"", indiaPrice: ""},
			{offerings:"", indiaPrice: ""}];
		const dataArr = [...props?.data || staticInitData];
		setState({ 
			FormData: [...dataArr ], 
			ConsultationOfferings: [...props?.offeringSetting || []] });
		}
		catch(err){
			console.error(err);
		}
  }, [props])

	const isSpecialtyEnabled = props?.specialtySetting?.enabled || false;
  

    return (
     <>
	<div className="content">
		<div className="row justify-content-center">
			<div className="col-span-12">
				<div className="section-header text-center">
					<h2 className="uppercase text-xl font-bold my-4 md:my-8">Add Consultation/ Advice/ Training Options (By Video/ Mobile Chat)</h2>
				</div>
				{
					State.process && <div className="alert alert-info">Proccessing Please Wait..</div>
				}
					<div className="service-fields mb-3">
						{/* Table */}
						<Table className="border border-gray-400 w-full table table-stripped">
							<TableHead className="bg-gray-300">
								<TableRow>
									<TableCell className="font-semibold text-base" style={{width:"30%"}}>Offerings</TableCell>
									{ isSpecialtyEnabled && <TableCell className="font-semibold text-base" style={{ width: "25%" }}>Specialities</TableCell> }
									<TableCell className="font-semibold text-base" style={{width:"40%"}}>Details</TableCell>
									<TableCell className="font-semibold text-base" style={{width:"15%"}}>Delivery Time</TableCell>
									<TableCell className="font-semibold text-base" style={{width:"15%"}}>Charges (INR)</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{
								( State?.FormData?.map((a, index) => 
									<TableRow key={index}>
										<TableCell className={styles.tableCell}>
											{ ((index!==State?.FormData?.length-1) || props.mode === "edit") &&
												<DropdownSelect 
													options={State.ConsultationOfferings}
													selectControlProps={{value:a?.offerings || ""}}
													// required={index===0?true:false}
													name="offerings" 
													width={'13rem'}
													label="Select Offerings" 
													value={a?.offerings || ""}
													onchange={ (e) => {formChange("offerings", e.target.value, index);} }
												/>
											}
											{
												((index===State?.FormData?.length-1) && props.mode !== "edit") && 
												<TextField  data-index={index} 
													type="text"
													size="small"
													fullWidth={true}
													variant="standard"
													defaultValue={a?.offerings || ""}
													placeholder='Create Your Own Option'
													onChange={ (e) => {formChange("offerings", e.target.value, index) } }
												/>
											}
										</TableCell>
										{/* ======= Specialities Field ======= */}
										{   isSpecialtyEnabled &&
												<TableCell className={styles.tableCell}>
													{((index !== State?.FormData?.length ) || props.mode === "edit") && (
														
															<DropdownGroupMultiselect
																options={props?.specialitiesObject}
																selectControlProps={{ value: a?.specialty || [] }}
																// required={index===0?true:false}
																name="specialty"
																width={'14rem'}
																label="Select Specialities"
																value={a?.specialty || []}
																onchange={(value) => formChange("specialty", value, index) }
															/>														
													)}
												</TableCell>
											}
										<TableCell className={styles.tableCell}>
											<TextField data-index={index} 
												name="description" 
												onChange={ (e) => {formChange("description", e.target.value, index) } }
												// required={index===0?true:false}
												multiline
												size="small"
												rows={2}
												value={a?.description || ""}
												placeholder="Enter Details" 
												className="form-control" 
												type="text" />
										</TableCell>
										<TableCell className={styles.tableCell}>
										<DropdownSelect 
											options={deliveryTimeArr}
											selectControlProps={{value:a?.deliveryTime || ""}}
											label="Select Time"
											type='text'
											name="deliveryTime" 
											value={a?.deliveryTime || ""}
											onchange={ (e) => {formChange("deliveryTime", e.target.value, index) } }
											// onChange is WRONG => onchange is CORRECT (inDropdownSelect)
										/>
										</TableCell>
										<TableCell className={styles.tableCell}>
											<TextField data-index={index} 
												label="Charges"
												variant="standard"
												size="small"
												value={a?.indiaPrice || ""} 
												onChange={ (e) => {formChange("indiaPrice", Number(e.target.value), index) } }
												className="form-control"
												placeholder="INR (₹)" 
												type="number" />
										</TableCell>
										<TableCell>
											<span role={'presentation'} data-index={index} className="font-bold text-lg" style={{cursor:"pointer"}} onClick={()=>removeServiceRecord(index)}>
											<FontAwesomeIcon icon={faTrashAlt} className='text-danger'/>
											</span>
										</TableCell>
									</TableRow>
								))
							}
							<TableRow>
								<TableCell colSpan={5} className="font-bold text-lg" style={{textAlign:"right"}}>
									<Button type="button" variant='outlined' onClick={addNewConsultationRecord}>+ Add Another Option</Button>
								</TableCell>
							</TableRow>
							</TableBody>
						</Table>

						{/* Table Ends */}
					</div>
				{/* </form> */}
			</div>
		</div>
		</div>       
        </>
    );
}

export default AddConsultations;