import React, { useEffect, useState } from "react";
import SidebarNav from "../sidebar";
import { GetBookingJourney } from "../../../API/BookingJourneyAPI";

// function TrackBookingJourney() {

//     const [bookingJourney, setBookingJourney] = useState([]);

//     useEffect(() => {
//         GetBookingJourney().then(res => {
//             setBookingJourney(res.data);
//         })
//     }, [])

//     return (
//         <>
//             <SidebarNav />
//             <div className="page-wrapper">
//                 <div className="content container-fluid">
//                     <div className="page-header">
//                         <div className="row">
//                             <div className="col">
//                                 <h3 className="page-title">Pending Bookings (Didn't Convert to Order)</h3>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="row">
//             <div className="col-md-12">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="table-responsive">
//                     <table className="table table-hover table-center mb-0">
//                       <thead>
//                         <tr>
//                           <th>Booking ID</th>
//                           <th>Service Name</th>
//                           <th>User</th>
//                           <th>Provider</th>
//                           <th>Journey Started At</th>
//                           <th>Actions</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {
//                             bookingJourney.map((item, index) => {
//                                 return (
//                                     <tr key={index}>
//                                         <td>{item.id}</td>
//                                         <td>
//                                             <h2 className="table-avatar">
//                                                 {/* <a href="profile" className="avatar avatar-sm mr-2"><img className="avatar-img" src="assets/img/user.jpg" alt="User Image" /></a> */}
//                                                 <p href="service_name">{item.service_name}</p>
//                                             </h2>
//                                         </td>
//                                         <td>
//                                             <h2 className="table-avatar">
//                                                 {/* <a href="profile" className="avatar avatar-sm mr-2"><img className="avatar-img" src="assets/img/user.jpg" alt="User Image" /></a> */}
//                                                 <p href="user_name">{item.user}</p>
//                                             </h2>
//                                         </td>
//                                         <td>
//                                             <h2 className="table-avatar">
//                                                 {/* <a href="profile" className="avatar avatar-sm mr-2"><img className="avatar-img" src="assets/img/user.jpg" alt="User Image" /></a> */}
//                                                 <p href="provider_name">{item.provider_username}</p>
//                                             </h2>
//                                         </td>
//                                         <td>{item.journey_started_at}</td>
//                                         <td>
//                                             <div className="actions">
//                                                 <a className="btn btn-sm bg-success-light" href="booking-details"><i className="fe fe-eye mr-1"></i> View</a>
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 )
//                             })
//                         }
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>
//             </div>
//             </div>
//             </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default TrackBookingJourney

// src/admin/components/track-booking-journey/index.jsx

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Modal,
  Paper,
  // Add any other MUI components you're using
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};


const getStepStatus = (journey) => {
  const steps = [
    {
      label: "Book Now Dialogue",
      completed: !!journey.dialogue_opened_at,
      timestamp: journey.dialogue_opened_at,
    },
    {
      label: "Basic Info",
      completed: !!journey.basic_info_completed_at,
      timestamp: journey.basic_info_completed_at,
    },
    {
      label: "Order Summary",
      completed: !!journey.order_summary_completed_at,
      timestamp: journey.order_summary_completed_at,
    },
    {
      label: "Payment",
      completed: !!journey.payment_completed_at,
      timestamp: journey.payment_completed_at,
    },
  ];

  // Find the active step (first incomplete step)
  const activeStep = steps.findIndex((step) => !step.completed);
  return { steps, activeStep: activeStep === -1 ? steps.length : activeStep };
};

const JourneyProgress = ({ journey }) => {
  const { steps, activeStep } = getStepStatus(journey);
  const [open, setOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);

  const styles = {
    stepper: {
      backgroundColor: "#f5f5f5",
      padding: "20px",
      borderRadius: 1,
    },
    completedStep: {
      "& .MuiStepLabel-root .Mui-completed": {
        color: "success.main", // or any color you prefer
      },
    },
    activeStep: {
      "& .MuiStepLabel-root .Mui-active": {
        color: "primary.main", // or any color you prefer
      },
    },
  };

  const handleOpen = (step) => {
    setSelectedStep(step);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Stepper activeStep={activeStep} sx={styles.stepper}>
        {steps.map((step, index) => {
          const labelProps = {};
          if (step.completed) {
            labelProps.optional = (
              <Typography variant="caption">
                {new Date(step.timestamp).toLocaleString()}
              </Typography>
            );
          }

          return (
            <Step
              key={step.label}
              completed={step.completed}
              sx={
                step.completed
                  ? styles.completedStep
                  : index === activeStep
                  ? styles.activeStep
                  : {}
              }
              onClick={() => handleOpen(step)}
            >
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="step-details-title"
        aria-describedby="step-details-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="step-details-title" variant="h6" component="h2">
            {selectedStep?.label} Details
          </Typography>
          <Typography id="step-details-description" sx={{ mt: 2 }}>
            {/* Display detailed information about the step */}
            {selectedStep?.completed
              ? `Completed at: ${new Date(selectedStep.timestamp).toLocaleString()}`
              : "Not completed yet."}
          </Typography>
          {selectedStep?.label === 'Basic Info' && journey.basic_info_data && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2"><strong>Basic Info Data:</strong></Typography>
              <pre>{JSON.stringify(journey.basic_info_data, null, 2)}</pre>
            </Box>
          )}
          {selectedStep?.label === 'Order Summary' && journey.order_summary_data && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2"><strong>Order Summary Data:</strong></Typography>
              <pre>{JSON.stringify(journey.order_summary_data, null, 2)}</pre>
            </Box>
          )}
          {selectedStep?.label === 'Payment' && journey.payment_data && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2"><strong>Payment Data:</strong></Typography>
              <pre>{JSON.stringify(journey.payment_data, null, 2)}</pre>
            </Box>
          )}
        </Paper>
      </Modal>
    </Box>
  );
};

const TrackBookingJourney = () => {
  const [journeyData, setBookingJourney] = useState([]);

  useEffect(() => {
    GetBookingJourney().then((res) => {
      setBookingJourney(res.data);
    });
  }, []);

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
                     <div className="page-header">
                         <div className="row">
                             <div className="col">
                                 <h3 className="page-title">Pending Bookings (Didn't Convert to Order)</h3>
                             </div>
                         </div>
                     </div>
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <Box>
                {journeyData.map((journey) => (
                  <Box
                    key={journey.id}
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: 1,
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {journey.service_name}
                    </Typography>
                    <Typography variant="body2" color="secondary" gutterBottom>
                      User: {journey.user_fullname}({journey.user_username} {journey.user_phone}) | Provider: {journey.provider_fullname} ({journey.provider_username} {journey.provider_phone})
                    </Typography>

                    <JourneyProgress journey={journey} />

                    <Typography variant="body2">
                      Started:{" "}
                      {new Date(journey.journey_started_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      Last Updated:{" "}
                      {new Date(journey.last_updated_at).toLocaleString()}
                    </Typography>

                    {/* Add any additional journey details you want to display */}
                  </Box>
                ))}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackBookingJourney;
