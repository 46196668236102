import styles from './AdminLogin.module.css';
import React, { useContext, useState } from 'react'
import { Card, Typography } from '@mui/material';
import Logo from './../../assets/images/logo-black.png';
import { toast } from 'react-toastify';
import { AdminLogin } from '../../../API/AdminUserAPI';
import AdminContext from '../../AdminContext/AdminContext';
import { useEffect } from 'react';

export default function AdminLoginPage({setisAdminLogin}) {
    const [formData, setformData] = useState({});
    const adminContext = useContext(AdminContext);

   
    
    const adminLoginHandler = async () => {
        const resp = await AdminLogin(formData.username, formData.password);
        console.log(resp)
        if(resp.status === 200){
            if(resp.data.adminStatus !== 'Active'){
                toast.error("Your Account is not Active");      
                return;
            }
            toast.success("Login Successful");
            adminContext.setadminUserData({...resp.data});
           
            //To Stay Logged In
            // localStorage.setItem('adminInfo', JSON.stringify(resp.data))

        
            setisAdminLogin(true);
        }
        else{
            toast.error(resp.data);
        }
    }

    const handleChange=(e) => {
        setformData({...formData, [e.target.name]: e.target.value});
    }

    // useEffect(()=>{

    //     console.log(first)

    // }, [])
  return (
    <div className={styles.adminLoginContainer}>
        <Card className={`card ${styles.adminLoginCard}`} onSubmit={adminLoginHandler}>
            <div className='text-center'>
                <img src={Logo} alt="MrSolvo Logo" className="mb-4" style={{height:'4rem'}}/> Admin
            </div>
            <form method="POST" action='javascript:void(0);'>
                <Typography>Admin Username</Typography>
                <input onChange={handleChange} name="username" value={formData?.username} className="form-control mb-4" required type="text" placeholder="Admin Username"/>

                <Typography>Admin Password</Typography>
                <input onChange={handleChange} name="password" value={formData?.password} className="form-control mb-4" required type="password" placeholder="Admin Password"/>
                <div className='text-center my-2'>
                    <button type='submit' className="m-2 btn btn-primary">Authorize Now</button>
                    <button type='submit' className="m-2 btn btn-danger">Cancel</button>
                </div>
            </form>
        </Card>
    </div>
  )
}
