import React, { useState } from 'react';
import { Modal, Typography, Box, Button, TextField, CircularProgress } from '@mui/material';
import { SendEmail } from '../../../API/EmailAPI';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500, // Increased width for better spacing
    bgcolor: 'background.paper',
    borderRadius: '8px', // Rounded corners for a more modern look
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content horizontally
  };


function EmailModal({openModal, handleClose, handleOpen, checkedUsers, emptyCheckedUsers, checkedUsersUserName}) {
    
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const [loading, setLoading] = useState(false);
  
    const handleSubjectChange = (e) => setSubject(e.target.value);
    const handleBodyChange = (e) => setBody(e.target.value);
  
    const handleSendEmail = async () => {
        if(!subject || !body) {
            return;
        }

    try{
      setLoading(true);
      await SendEmail(subject, body, checkedUsers);
      setLoading(false);
      // Reset the form
      setSubject('');
      setBody('');
      emptyCheckedUsers();
      handleClose(); // Close modal after sending email
      alert("Email sent!");
    }
    catch(err){
      console.log(err);
    }
    };


  return (
    <div>
        <Button onClick={handleOpen}
        variant="outlined"
        color='primary'
        >
        Open modal
        </Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
            Send Email
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 1, textAlign: 'center', color: 'text.secondary' }}>
            Send an email to the selected users.
          </Typography> */}


          {checkedUsersUserName.length > 0 && (
            <Typography sx={{ my: 3, fontWeight: 'bold' }}>
              Emails will be sent to: {checkedUsersUserName.join(', ')}
            </Typography>
          )}

          {/* Subject Input */}
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            sx={{ mt: 3 }}
            value={subject}
            onChange={handleSubjectChange}
            required
          />

          {/* Body Input */}
          <TextField
            label="Body"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            sx={{ mt: 3 }}
            value={body}
            onChange={handleBodyChange}
            required
          />

          {/* Send Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            sx={{ 
                mt: 4,
                width: '100%',
                padding: '10px',
             }}
            disabled={subject.trim() === '' || body.trim() === '' || checkedUsers.length === 0}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} /> // Show loading spinner
            ) : (
              'Send Email'
            )}
          </Button>
        </Box>
      </Modal>
    </div>

  )
}

export default EmailModal