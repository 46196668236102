import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetTermsAndConditionsById(id) {
  const response = await axiosInstanceWithSecret.get(`/clickwrap/clickwraps/${id}/`);
  return response;
}

export async function UpdateTermsAndConditions(id, body) {
    const response = await axiosInstanceWithSecret.put(`/clickwrap/clickwraps/${id}/`, body);
    return response;
}


export async function GetAllTermsAndConditions(body) {
  const response = await axiosInstanceWithSecret.get(`/clickwrap/clickwraps/`, body);
  return response;
}

export async function AddNewTermsAndConditions(body) {
  const response = await axiosInstanceWithSecret.post(`/clickwrap/clickwraps/`, body);
  return response;
}