import React from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DropdownSelect from "../../../../GlobalComps/DropdownSelect";
import { removeItemFromArray } from "../../../../GlobalComps/Helper";
import styles from './../CompleteProfile.module.css';

function AddAddOnService (props) {
	const [State, setState] = useState({
		Categories: [],
		process: false,
		serviceTypes: ['Consultation','Service', 'AddOnService', 'Retainership'],
		UserData:{},
		ServiceImgDisplay:'',
		FormData:[],
		ConsultationOfferings:[
		...props.offeringSetting || []
		]
	})

	
	// const contextState = useContext(ClientContext);

	// const submitConsultation = async () => {
	// 	setState({ process: true})
	// 	let respp=0;
	// 	for (let i = 0; i < 3; i++) {
	// 		try{
	// 			const form_data = State.FormData[i];
	// 			const payload = {
	// 				...form_data,
	// 				serviceProvider: contextState?.loggedinUser?.ProviderData?.serviceProvider_id,
	// 				category: contextState?.loggedinUser?.ProviderData?.categoryId,					
	// 				serviceType: 'AddOnServices'
	// 			}
	// 			const payloadFormdata = JsonToFormdata(payload);
	// 			const resp = await AddNewService(payloadFormdata);
	// 			respp = respp + resp.status;
	// 		}
	// 		catch(err){
	// 			console.log("Err ==>", err.message);
	// 		}
	// 	}
	// 	if(respp === 603){
	// 		toast.success("New Add-On Service Added successfully");
	// 		setState({ process: false})
	// 		props?.history?.push("/provider/provider-services") || console.log("push Fail");
	// 	}
	// }

//   const handleChange= (e) => {
// 	const Index = e.target.getAttribute("data-index");
// 	let FormDataArr = State.FormData;
// 	if(e.target.type === 'file'){
// 		FormDataArr[Index] = {...FormDataArr[Index], [e.target.name] : e.target.files[0]}
// 		setState({  
// 			ServiceImgDisplay: URL.createObjectURL(e.target.files[0]), 
// 			FormData: [...FormDataArr]
// 		});	
// 		return;
// 	}
// 	FormDataArr[Index] = {...FormDataArr[Index], [e.target.name] : e.target.value}
// 	setState({ FormData: [...FormDataArr] });
//   }

  const formChange = (name, val, index) => {
	let FormDataArr = State.FormData;
	FormDataArr[index] = {...FormDataArr[index], [name] : val}
	setState({ FormData: [...FormDataArr || []] });
	props.updateState([...FormDataArr || []])
  }

  

//   const handleWeekChange = (day, e) => {
// 	let availability = {...State.FormData?.availablity || {}};
// 	availability = {...availability, [day] : {...availability?.[day], [e.target.name]: e.target.value} };
// 	setState({ FormData: { ...State.FormData, availablity: availability} });
//   }

//   const setSelectedSubCategory=(id)=>{
// 	const ALlCats = [...State.SelectedCategory?.SubCategories || []];
// 	const subCatObj = ALlCats?.find(elem => elem.category_id === id);
// 	setState({SelectedSubCategory: {...subCatObj}});
//   }

  const addNewConsultationRecord = () => {
	if(State.FormData.length > 10){
		Swal.fire({
			title:'Max 10 Consultations are Allowed',
			icon:'error'
		});
		return;
	}
	setState(prevState => ({
		...prevState,
		FormData: [...prevState.FormData, {offerings:'', indiaPrice: ''}]
	}));
	props.updateState([...State.FormData, {offerings:'', indiaPrice: ''}])
  }

  const removeServiceRecord = (index) => {
	let formData = State.FormData;
	let newFormData = removeItemFromArray(formData, index, 'index');
	setState(prevState => ({
		...prevState,
		FormData: [...newFormData]
	}));
	props.updateState([...newFormData])
  }


  useEffect(() => {
    try{
		const staticInitData = [
			{offerings:''},
			{offerings:''},
			{offerings:''},
			{offerings:''},
			{offerings:''}];
			const dataArr = [...props?.data || staticInitData];
			setState({ FormData: [...dataArr ],  ServiceOfferings: [...props.offeringSetting]  });
		}
		catch(err){
			console.log(err);
		}
  }, [props])


    return (
		<>
			<div className="content">
				<div className="row justify-content-center">
					<div className="col-span-12">
						<div className="section-header text-center">
							<h2 className="uppercase text-xl font-bold my-4 md:my-8">Add On Service</h2>
						</div>
						{
							State.process && <div className="alert alert-info">Processing Please Wait..</div>
						}
						{/* eslint-disable-next-line no-script-url */}
							<div className="service-fields mb-3">
								{/* Table */}
								<TableContainer component={Paper}>
								<Table className="border border-gray-400 w-full table table-stripped">
									<TableHead className="bg-gray-300">
										<TableRow>
											<TableCell className="font-semibold text-base" style={{width:'35%'}}>Offerings</TableCell>
											<TableCell className="font-semibold text-base" style={{width:'35%'}}>Descriptions</TableCell>
											<TableCell className="font-semibold text-base" style={{width:'30%'}}>Extra Charges in India (in INR)</TableCell>
											<TableCell></TableCell>
											{/* <TableCell>Extra Charges for outside India (in US $)</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
									{
										State?.FormData?.map((a, index) => 
											<TableRow key={index}>
												<TableCell className={styles.tableCell}>
												{  ((index!==State?.FormData?.length-1) || props.mode === 'edit') &&
													<DropdownSelect data-index={index} 
														options={State.AddOnServiceOfferings}
														label="Select Offerings"
														selectControlProps={{value:a?.offerings || ''}}
														// required={index===0?true:false}
														width={'16rem'}
														name="offerings"  
														value={a?.offerings} 
														onchange={ (e) => {formChange('offerings', e.target.value, index);} }
													/>
												}
													{/* (<div>{a?.offerings}</div>) */}
												{
													((index===State?.FormData?.length-1) && props.mode !== 'edit') && 
														<TextField data-index={index} 
															type="text"
															fullWidth={true}
															size="small"
															variant="standard"
															defaultValue={a?.offerings || ''}
															placeholder='Create Your Own option'
															onChange={ (e) => {formChange('offerings', e.target.value, index) } }
														/>
													}
												</TableCell>
												<TableCell className={styles.tableCell}>
													<TextField data-index={index} 
														name="specialties"
														multiline
														size="small"
														rows={2} 
														onChange={ (e) => {formChange('specialties', e.target.value, index) } }
														value={a?.specialties || ''}
														placeholder="Enter Details" 
														className="form-control" 
														type="text" />
												</TableCell>
												<TableCell className={styles.tableCell}>
												<TextField data-index={index} 
														name="indiaPrice"
														fullWidth={true}
														variant="standard"
														size="small"
														label="Charges"
														value={a?.indiaPrice || ''} 
														onChange={ (e) => {formChange('indiaPrice', Number(e.target.value), index) } }
														className="form-control"
														placeholder="INR (₹)" 
														type="number" />
												</TableCell>
												<TableCell>
												<span role={'presentation'} data-index={index} style={{cursor:'pointer'}} onClick={()=>removeServiceRecord(index)}>
													<FontAwesomeIcon icon={faTrashAlt} className='text-danger'/>
												</span>
												</TableCell>
											</TableRow>
										)
									}
									<TableRow>
										<TableCell colSpan={5} style={{textAlign:'right'}}>
											<Button type="button" variant="outlined" onClick={addNewConsultationRecord}>+ Add Another Option</Button>
										</TableCell>
									</TableRow>
									</TableBody>
								</Table>
								</TableContainer>
								{/* Table Ends */}
							</div>
							
							{/* <div className="submit-section">
								<button className="btn btn-primary submit-btn" type="submit">Submit</button>
							</div> */}
					{/* </form> */}
					</div>
				</div>
		</div>       
        
          </>
    );
  }

export default AddAddOnService;
