import { Select, Table, InputLabel, Paper, TableContainer, TableHead, FormControl, FormControlLabel, Checkbox, Typography, MenuItem, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './../CompleteProfile.module.css';
import { GetAllPlans } from '../../../../API/PlanAPI';

export default function PricePlanForm({ setFormData, FormData }) {
  const [plan, setplan] = useState('');
  const [selectedPlan, setselectedPlan] = useState();
  const [AllPlans, setAllPlans] = useState([]);
  const [extraVerify, setextraVerify] = useState(false);
  const [totalPayableAmt, settotalPayableAmt] = useState(0);

  const handleChange = (e) => {
      setplan(e.target.value);
      setFormData({...FormData, PlanData: {...FormData.PlanData, plan: e.target.value} });
      setselectedPlan({...AllPlans?.find(el => el.plan_id === e.target.value) || {}});
  }

  const FetchPlans = async () => {
      const resp = await GetAllPlans();
      if(resp.status === 200){
          setAllPlans([...resp?.data?.data || []]);
      }
  }

  useEffect(() => {
    if(selectedPlan){
        if(extraVerify){
            settotalPayableAmt(totalPayableAmt + 500);
        }
        else{
            settotalPayableAmt(totalPayableAmt - 500);
        }
    }
  }, [extraVerify]);

  useEffect(() => {
    settotalPayableAmt(Number(selectedPlan?.charge) * Number(selectedPlan?.plan_validity)*1.18);
  }, [selectedPlan])
  
  

  useEffect(() => {
      FetchPlans();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
    
  
  return (
    <div>
        <Typography variant="h5" className="fw-bold mb-2">Choosen Plan </Typography>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Plan Name</TableCell>
                        <TableCell>Price/month</TableCell>
                        <TableCell>Validity</TableCell>
                        <TableCell>MrSolvos Service Fee </TableCell>
                        <TableCell>Profile Boosting Points </TableCell>
                        <TableCell>Approval Time (approx)</TableCell>
                        <TableCell>Total Charge (₹) </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {AllPlans.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.plan_name}</TableCell>
                        <TableCell>{row.charge}</TableCell>
                        <TableCell>{row.plan_validity} Months</TableCell>
                        <TableCell>{row?.service_fee || '-'} %</TableCell>
                        <TableCell>{row?.profile_boost}</TableCell>
                        <TableCell>{row?.max_approval} Day(s)</TableCell>
                        <TableCell>{Number(row?.charge) * Number(row?.plan_validity)*1.18}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <br/><br/>
            <FormControl className={styles.selectField} fullWidth={true} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Select Plan</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={plan}
                    onChange={handleChange}
                    label="Select Plan"
                    name="plan"
                >
                <MenuItem value="" disabled={true} selected={true}>Select Plan</MenuItem>
                {
                    AllPlans?.map((item, index) =>
                    <MenuItem key={index} value={item?.plan_id}>{item?.plan_name}</MenuItem>
                )}
                </Select>
            </FormControl>
                <br/>
            <FormControlLabel 
                control={<Checkbox style={{marginRight:'8px'}} 
                checked={extraVerify}
                onChange={()=>setextraVerify(!extraVerify)}
                name={`checked-tnc`} />} 
                label={'I want my submitted details to be reviewed, edited and improved upon for better visibility, conversion & revenues. Charges Rs.500 (USD $ 7)'} />
            <hr/>
            { selectedPlan &&
            <div className='d-flex justify-content-between'>
                <div>
                    <h5 className='fw-bold'>Amount Payable <h6 className='fw-normal'>({selectedPlan?.charge} x {selectedPlan?.plan_validity} Months)</h6></h5>
                    <h5>+ 18% GST</h5>
                    {extraVerify && <h5>+ ₹ 500</h5>}
                </div>
                    <h4>₹ {totalPayableAmt}</h4>
            </div>
            }
    </div>
  )
}
