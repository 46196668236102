import { FormControl, Checkbox, Input, InputLabel, ListItemText, MenuItem, Select, TextField, Typography, FormControlLabel, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react';
import { CountriesList, LanguagesList } from '../../../../GlobalComps/Constant';
import DropdownSelect from '../../../../GlobalComps/DropdownSelect';
import WeeklyTimeComp from '../elems/WeeklyTimeComp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import styles from './../CompleteProfile.module.css';

export default function ServicesForm({ PreviewData, setFormData, FormData, DocumentsFormData, setdocumentsFormData }) {
  const [allCountries, setAllCountries] = useState([]);
  const [servicesFormData, setservicesFormData] = useState({ countries: [] });

  //   const [languagesProficiency, setlanguagesProficiency] = useState([]);

  const langTableObj = {
    language: "",
    spoken: false,
    written: false,
    rating: "",
  };

  const handleChange = (e) => {
    setservicesFormData({ ...servicesFormData, [e.target?.name]: e.target?.value });
    setFormData((prevState) => ({
      ...prevState,
      ServiceData: { ...prevState.ServiceData, [e.target?.name]: e.target?.value },
    }));
  };

  //   const checkCountries = (e) => {
  //     let country = e.target.value.split(',');
  //     if(country.includes('All')){
  //         let SelectCountries = allCountries.join(',');
  //         setservicesFormData({...servicesFormData, [e.target.name]: SelectCountries });
  //         setFormData(prevState => ({...prevState, ServiceData: {...prevState.ServiceData, [e.target.name]: SelectCountries }} ));
  //     }
  //     else{
  //         handleChange(e);
  //     }
  //   }

  const FetchAllCountries = () => {
    const resp = CountriesList;
    if (resp) {
      setAllCountries(resp);
    }
  };

  const updateLangProficiencyData = (name, val, index) => {
    let obj = [...(servicesFormData?.languageProficiency || [])];
    obj[index] = { ...obj[index], [name]: val };
    // setlanguagesProficiency([...obj]);
    setservicesFormData({ ...servicesFormData, languageProficiency: [...obj] });
    setFormData((prevState) => ({
      ...prevState,
      ServiceData: { ...prevState.ServiceData, languageProficiency: [...obj] },
    }));
  };

  const addLanguage = () => {
    //   setlanguagesProficiency( prevData => [...prevData, langTableObj]);
    // updateLangProficiencyData('languageProficiency', langTableObj, )
    setservicesFormData((ps) => ({
      ...servicesFormData,
      languageProficiency: [...(ps?.languageProficiency || []), langTableObj],
    }));
  };

  const deleteLangProficiencyData= (index) => {
    let obj = [...(servicesFormData?.languageProficiency || [])];
    obj.splice(index, 1);
    setservicesFormData({ ...servicesFormData, languageProficiency: [...obj] });
    setFormData((prevState) => ({
      ...prevState,
      ServiceData: { ...prevState.ServiceData, languageProficiency: [...obj] },
    }));
}

  useEffect(() => {
    setservicesFormData({ ...FormData });
  }, [FormData]);

  useEffect(() => {
    FetchAllCountries();
    // setlanguagesProficiency([{...langTableObj}]);
    if ((FormData?.languageProficiency?.length || 0) < 1) {
      addLanguage();
    }
  });

  //   useEffect(() => {

  //   }, [languagesProficiency])

  const handleDocuments = (e) => {
    setdocumentsFormData(e.target.name, e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div>
        <Typography className="text-lg font-bold my-5" variant="h5">
          Offered Services & Charges
        </Typography>
        <form>
          <Typography className="text-sm md:text-base mt-8">
            Countries you would like to offer your services to: *
          </Typography>
          <FormControl fullWidth={true} required variant="standard">
            <InputLabel id="demo-mutiple-checkbox-label">Select Countries</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name="countries"
              required
              variant="standard"
              value={servicesFormData?.countries || []}
              onChange={(e) => {
                handleChange(e);
              }}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              <MenuItem key={-1} value={"All"}>
                <Checkbox checked={servicesFormData.countries?.indexOf("All") > -1} />
                <ListItemText primary={"All Countries"} />
              </MenuItem>
              {allCountries?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    checked={
                      servicesFormData.countries?.indexOf(name) > -1 || servicesFormData.countries?.indexOf("All") > -1
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <div className="mt-4">
                <Typography className='text-sm md:text-base mt-8'>PortFolio Link</Typography>
                <TextField
                    fullWidth={true} required
                    className="mt-2"
                    name="portfolioLink"
                    value={servicesFormData?.portfolioLink}
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Portfolio Link"
                    variant="standard" />
            </div> */}

          <div className="mt-4">
            <Typography className="text-sm md:text-base mt-8">
              Tag Line/ Highlighter for your profile *<br />
              <span className="text-gray-500 text-xs md:text-sm">
                (You can mention your biggest professional achievement/ no. of consultations given/ services delivered
                in your field. (5 to 20 Words)
              </span>
            </Typography>
            <TextField
              fullWidth={true}
              required
              className="mt-2"
              name="tagline"
              value={servicesFormData?.tagline}
              onChange={handleChange}
              id="outlined-basic"
              label="Tagline"
              variant="standard"
            />
          </div>

          <div className="mt-4">
            <Typography className="text-sm md:text-base mt-8">
              Core expertise/ key strengths or skills in which you have good experience *
            </Typography>
            <textarea
              required
              className="border border-gray-500 rounded mt-2 p-2"
              rows={3}
              name="skills"
              style={{ width: "100%" }}
              value={servicesFormData?.skills}
              onChange={handleChange}
              id="outlined-basic"
              label="Tagline"
              placeholder="Skills/ Strengths"
            />
            {/* <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Minimum 3 rows"
                        style={{ width: 200 }}
                        /> */}
          </div>
          {/* Detailed professional profile */}
          <div className="mt-4">
            <Typography className="font-semibold mt-8" component="h2">
              Detailed professional profile (50+ words)
            </Typography>
            <textarea
              required
              className="border border-gray-500 rounded mt-2 p-2"
              rows={3}
              name="companyProfile"
              style={{ width: "100%" }}
              value={
                DocumentsFormData?.companyProfile ||
                servicesFormData?.companyProfile ||
                ""
              }
              onChange={handleDocuments}
              id="outlined-basic"
              label="Professional Profile"
              placeholder="Company Profile"
            />
          </div>

          {/* Detailed professional profile end */}
          <br />
          {/* Language Proficiency */}
          <Typography className="text-sm md:text-base mt-8">Language proficiency *</Typography>
          <div className="overflow-x-auto">
            <Table
              className={`${styles.langTable} overflow-x-auto border border-gray-400 rounded my-3 w-full table table-stripped select-language-table`}>
              <TableHead>
                <TableRow className="bg-gray-300">
                  <TableCell>Select Language</TableCell>
                  <TableCell>Spoken/ Written</TableCell>
                  {/* <TableCell>Written</TableCell> */}
                  <TableCell>Rate your Proficiency in Language</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesFormData?.languageProficiency?.map((lng, index) => (
                  <TableRow key={index}>
                    <TableCell className="p-sm-1 p-md-3">
                      <DropdownSelect
                        options={LanguagesList}
                        label="Select Language"
                        value={lng?.language || ""}
                        onchange={(e) => updateLangProficiencyData("language", e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell className="p-sm-1 p-md-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lng.spoken}
                            onChange={() => updateLangProficiencyData("spoken", !lng.spoken, index)}
                            name=""
                            color="primary"
                          />
                        }
                        label="Spoken"
                      />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lng.written}
                            onChange={() => updateLangProficiencyData("written", !lng.written, index)}
                            name="written"
                            color="primary"
                          />
                        }
                        label="Written"
                      />
                    </TableCell>
                    <TableCell className="p-sm-1 p-md-3">
                      <FormControl fullWidth={true} required variant="standard">
                        <InputLabel id="demo-simple-select-helper-label">Select Rating</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={lng?.rating || ""}
                          fullWidth={true}
                          required
                          onChange={(e) => updateLangProficiencyData("rating", e.target.value, index)}
                          label="Rating"
                          placeholder="Select Rating">
                          <MenuItem selected disabled value={""}>
                            Select Rating
                          </MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className="p-sm-1 p-md-3">
                      <Button variant="outlined" onClick={() => deleteLangProficiencyData(index)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3} className="text-right right">
                    <Button variant="outlined" onClick={addLanguage}>
                      Add Language
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/* Ends === Language ====== */}

          {/* <div className="mt-4">
                <Typography className='text-sm md:text-base mt-8'>Please provide 1 pricing line to appear in the main listing *</Typography>
                <textarea
                    // fullWidth={true}
                    required
                    className="border border-gray-500 rounded mt-2 p-2"
                    rows={3}
                    style={{width:'100%'}}
                    value={servicesFormData?.catchyLine}
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Skills"
                    name="catchyLine"
                    placeholder="(eg Consultation starts ₹ 500 OR Service starts ₹ 400 / hr)"
                    // variant="standard"
                    /> */}
          {/* <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Minimum 3 rows"
                        style={{ width: 200 }}
                        /> */}
          {/* </div> */}

          <div className="mt-4">
            <Typography className="text-sm md:text-base mt-8">Your Current Status/ Occupation *</Typography>
            <Select
              fullWidth={true}
              required
              className="mt-2"
              value={servicesFormData?.occupation || ""}
              defaultValue=""
              onChange={handleChange}
              id="outlined-basic"
              name="occupation"
              variant="standard">
              <MenuItem value="" disabled>
                Select Occupation
              </MenuItem>
              <MenuItem value="company">Company</MenuItem>
              <MenuItem value="partner">Partner</MenuItem>
              <MenuItem value="proprietor"> Proprietor</MenuItem>
              <MenuItem value="practice">Practice</MenuItem>
              <MenuItem value="freelancer">Freelancer</MenuItem>
            </Select>
          </div>
          <div className="mt-4">
            <Typography className="text-sm md:text-base mt-8">Your Highest/ Major Qualifications *</Typography>
            <TextField
              fullWidth={true}
              required
              className="mt-2"
              value={servicesFormData?.qualification}
              onChange={handleChange}
              id="outlined-basic"
              label="Qualification"
              name="qualification"
              variant="standard"
            />
          </div>

          {/* { (servicesFormData?.countries?.length > 1 ||
                (!servicesFormData?.countries?.includes("India") && servicesFormData?.countries?.length === 1) )  &&
                <div className="mt-4">
                    <Typography className='text-sm md:text-base mt-8'>Global expertise/ skills which have international relevance (which enables you to offer services internationally) - Maximum 25 words</Typography>
                    <textarea
                        // fullWidth={true}
                        className="border border-gray-500 rounded mt-2 p-2"
                        rows={3}
                        style={{width:'100%'}}
                        value={servicesFormData?.globalSkills}
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Skills"
                        name="globalSkills"
                        placeholder="Global Skills/ Strengths"
                        // variant="standard"
                        />
                </div>
            } */}
          {/* Weekly Start & End Time */}
          <WeeklyTimeComp
            setVal={(val) => {
              setservicesFormData((prevState) => ({ ...prevState, preferred_days_time: val }));
              setFormData((prevState) => ({
                ...prevState,
                ServiceData: { ...prevState.ServiceData, preferred_days_time: val },
              }));
            }}
            Value={FormData?.preferred_days_time}
          />
        </form>
      </div>
    </Fragment>
  );
}
