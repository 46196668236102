import React, { Component } from "react";
import SidebarNav from "../sidebar";
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../paginationfunction";
import styles from './AdminUser.module.css';
import { GetAllAdminUsers } from "../../../API/AdminUserAPI";
import AddAdminUser from "./AddAdminUser";
import { Button } from "react-bootstrap";


class AdminUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: null,
          open: false,
          data: [],
          addAdminUserShow: false,
          selectedUser:{}
        };
      }
      handleShow() {
          this.setState({
            addAdminUserShow: !this.state.addAdminUserShow
          }) 
      }
  
  editUserHandler = (user) => {
    this.setState({
      selectedUser: {...user},
      addAdminUserShow: true
    })
  }

  fetchAllAdminUsers = async () => {
    const resp = await GetAllAdminUsers();
    this.setState({data: [...resp?.data]})
  }

  componentDidMount = async () => {
    this.fetchAllAdminUsers()
  }

  render() {

    const { data } = this.state;
    const columns = [
        {
          title: "Id",
          dataIndex: "adminUserId",
          render: (text, record) => (
          <span title={text} className={styles.userIdField}>{text}</span>
          ),
        },
        {
          title: "Username",
          dataIndex: "adminUsername",
          sorter: (a, b) => a.adminUsername.length - b.adminUsername.length,
        },
        {
          title: "Email",
          dataIndex: "adminEmail",
          sorter: (a, b) => a.adminEmail.length - b.adminEmail.length,
        },
        {
            title: "Role",
            dataIndex: "adminRole",
          },
          {
            title: "Access Roles",
            dataIndex: "adminAccessRoleSettings",
            render: (text, record) => (
             <center>
               {record?.adminAccessRoleSettings?.map((role) => <span>{role}</span>)}
              </center>
            )
          },
          {
            title: "Status",
            dataIndex: "adminStatus",
            render: (text, record) => (
             <>
               {<div className={`${styles.badge} ${styles?.[record.adminStatus]}`}>{text}</div>}
              </>
            ),
            sorter: (a, b) => a.adminStatus.length - b.adminStatus.length,
          },
          {
            title: "Action",
            dataIndex: "",
            render: (text, record) => (
              <>
                <Button onClick={()=>this.editUserHandler(record)}>Edit user</Button>
              </>
            )
          },
      ];
  
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			      <div className="content container-fluid">
                  <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title">Admin Users</h3>
                  </div>
                  <div className="col-auto text-right">
                    <a className="btn btn-white" onClick={() => this.handleShow()} href={"#!"} id="filter_search">
                      Add Admin User
                    </a>
                  </div>
                </div>
              </div>

                <div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
								<Table
                        className="table table-hover table-center mb-0 datatable"
                        style={{ overflowX: "auto", width:"100%"}}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                 
               
                                 </div>
                            </div>
                        </div> 
                    </div>
                </div>                       
              <AddAdminUser 
                editData={this.state.selectedUser}
                refreshRecords={this.fetchAllAdminUsers} 
                handleClose={()=>this.setState({addAdminUserShow: false})} 
                open={this.state.addAdminUserShow}/>
          </div>
       </div>
      </>
    );
  }
}

export default AdminUsers;
