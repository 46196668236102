import { MetaTitle } from "./Constant";

export function setDocumentTitle(title) {
    // data.title = title || 'Mr Solvo';
    // data.metaDescription = data.metaDescription || 'Mr Solvo';
  
    document.title = title;
    // document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }

export const getDocumentTitle = () => document.title;

export function resetDocumentTitle(){
  document.title = MetaTitle;
}