import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function AdminLogin(username, password){
    try{
        const body = {
            username: username,
            password: password
        }
        const response = await axiosInstanceWithSecret.post(`/login-admin-user`, body);
        return response;
    }
    catch(err){
        return err.response;
    }

 }


 export async function GetAllAdminUsers(){
    try{
        const response = await axiosInstanceWithSecret.get(`/get-all-admin-users`);
        return response;
    }
    catch(err){
        return err.response;
    }

 }

 export async function RegisterAdminUser(payload){
    try{
        const body = {
           ...payload
        }
        const response = await axiosInstanceWithSecret.post(`/register-admin-user`, body);
        return response;
    }
    catch(err){
        return err.response;
    }

 }

 export async function UpdateAdminUser(payload){
    try{
        const body = {
           ...payload
        }
        const response = await axiosInstanceWithSecret.put(`/update-admin-user`, body);
        return response;
    }
    catch(err){
        return err.response;
    }

 }