import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function AddNewPlan(body){
    const response = await axiosInstanceWithSecret.post(`/create-plan`, body);
    return response;
 }

 export async function GetAllPlans(){
    const response = await axiosInstanceWithSecret.get(`/get-all-plan`);
    return response;
 }

 export async function GetPlanById(planId){
    const response = await axiosInstanceWithSecret.get(`/get-plan-by-id`, { params: {plan_id: planId} });
    return response;
 }

 export async function UpdatePlan(planId, body){
    const response = await axiosInstanceWithSecret.put(`/update-plan`, body, {
        params:{
            plan_id: planId
        }
    });
    return response;
 }

 export async function RemovePlan(planId){
    const response = await axiosInstanceWithSecret.delete(`/delete-plan`, { params: {plan_id: planId} });
    return response;
 }