import React, { Component } from "react";
import SidebarNav from "../sidebar";
import {Link} from "react-router-dom";
import { GetPlanById, RemovePlan, UpdatePlan } from "../../../API/PlanAPI";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router";

class EditSubscriptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			EditPlanData:{},
			UpdatedPlanData:{}
		}
	}

	componentDidMount = async () => {
		const planId = this.props.match.params.planid;
		const resp = await GetPlanById(planId);
		if(resp.status === 200){
			this.setState({PlanId: planId, EditPlanData:{...resp.data.data}});
		}
		else{
			toast.error('Error Fetching Plan Details');
		}
	}
	handleChange = (e, field) => {
		this.setState({
			EditPlanData: {...this.state.EditPlanData, [e.target.name]: e.target.value},
			UpdatedPlanData: {...this.state.EditPlanData, [e.target.name]: e.target.value}
		});
	}

	updatePlanHandler = async (e) => {
		e.preventDefault()
		const resp = await UpdatePlan(this.state.PlanId, this.state.UpdatedPlanData);
		if(resp.status === 200){
			toast.success("Plan Details Updated Successfully");
		}
		else if(resp.status === 400){
			toast.error(resp.data);
		}
		else{
			toast.error("Some Error Occured");
		}
	}

	deletePlan = async () => {
		const { history } = this.props; 
		const resp = await RemovePlan(this.state.PlanId);
		if(resp.status === 200){
			toast.success("Plan Deleted Successfully");
			history.push("/admin/subscriptions");
		}
		else{
			toast.error("Some Error Occured");
		}
	}

  	render() {
		return (
		<>
			<SidebarNav />
		
			<div className="page-wrapper">
				<div className="content container-fluid">
					<div className="row">
						<div className="col-xl-8 offset-xl-2">

						<div className="page-header">
								<div className="row">
									<div className="col-sm-12">
										<h3 className="page-title">Edit Subscription</h3>
									</div>
								</div>
							</div>

							<div className="card">
								<div className="card-body">
									<form onSubmit={this.updatePlanHandler}>
										<div className="form-group">
											<label>Plan Name</label>
											<input required className="form-control" name="plan_name" type="text" value={this.state.EditPlanData?.plan_name} onChange={this.handleChange} />
											</div>
										<div className="form-group">
											<label>Plan Amount</label>
											<input required className="form-control" name="charge" type="number" value={this.state.EditPlanData?.charge}  onChange={this.handleChange}/>
										</div>
										<div className="form-group">
											<label>Mr Solvo Service Fee</label>
											<input required className="form-control" name="service_fee" type="number" min="0" max="100" value={this.state.EditPlanData?.service_fee} placeholder="in %" onChange={this.handleChange}/>
										</div>
										<div className="form-group">
											<label>Booster Points</label>
											<input required className="form-control" name="profile_boost" type="number" min="0" value={this.state.EditPlanData?.profile_boost} placeholder="0" onChange={this.handleChange}/>
										</div>
										<div className="form-group">
											<label>Plan Duration</label>
											<select name="plan_validity" value={this.state.EditPlanData?.plan_validity} onChange={this.handleChange} className="form-control select">
												<option value="" disabled selected>Select Duration</option>
												<option value="1">One Month</option>
												<option value="3">3 Months</option>
												<option value="6">6 Months</option>
												<option value="12">One Year</option>
												<option value="24">2 Years</option>
											</select>
										</div>
										<div className="form-group">
											<label>Approval Time</label>
											<select name="max_approval" value={this.state.EditPlanData?.max_approval} onChange={this.handleChange} className="form-control select">
												<option value="" disabled selected>Select Max Approval Time</option>
												{
													Array(30).fill('day')?.map((d, index) =>
														<option value={index+1}>{index+1} Day(s)</option>
													)
												}
											</select>
										</div>
										<div className="form-group">
											<label className="d-block">Subscription Status</label>
											<div className="custom-control custom-radio custom-control-inline">
												<input type="radio" name="status" value="on" checked={ this.state.EditPlanData?.status === 'on'} id="edit_subscription_active" onChange={this.handleChange} className="custom-control-input"  />
												<label className="custom-control-label" htmlFor="edit_subscription_active">Active</label>
											</div>
											<div className="custom-control custom-radio custom-control-inline">
												<input type="radio" name="status" value="off" checked={this.state.EditPlanData?.status === "off"} id="edit_subscription_inactive" onChange={this.handleChange} className="custom-control-input" />
												<label className="custom-control-label" htmlFor="edit_subscription_inactive">Inactive</label>
											</div>
										</div>
										<div className="mt-4">
											<button className="btn btn-primary" type="submit">Save Changes</button>
											<Link to="/admin/subscriptions" className="btn btn-link">Cancel</Link>
											<span onClick={this.deletePlan} className="btn text-secondary btn-link"><FontAwesomeIcon icon={faTrashRestore}/> Delete This Plan</span>
										</div>
									</form>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>                

		</>
		);
  }
}

export default withRouter(EditSubscriptions);
