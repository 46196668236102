import React, { Component } from "react";
import SidebarNav from "../sidebar";
import {Link} from "react-router-dom";
import { GetAllPlans } from "../../../API/PlanAPI";
import { toast } from "react-toastify";

class Subscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PlanData:[]
    };
  }

  componentDidMount = async () => {
    const resp = await GetAllPlans();
    if(resp.status === 200){
      this.setState({PlanData: [...resp.data.data]});
    }
    else{
      toast.error("Error Fetching plan data");
    }
  }


  render() {
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Subscriptions</h3>
                </div>
                <div className="col-auto text-right">
                  <Link
                    to="/admin/add-subscriptions"
                    className="btn btn-primary add-button"
                  >
                    <i className="fas fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row pricing-box">
            { this.state.PlanData?.map((plan, index) =>
              <div key={index} className="col-md-6 col-lg-4 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="pricing-header">
                      <h2>{plan?.plan_name}</h2>
                      <p>Monthly Price</p>
                    </div>
                    <div className="pricing-card-price">
                      <h3 className="heading2 price">₹ {plan?.charge}</h3>
                      <p>
                        Duration: <span>{plan?.plan_validity}</span>
                      </p>
                    </div>
                    <ul className="pricing-options">
                      <li>
                        <i className="far fa-check-circle"></i> One listing
                        submission
                      </li>
                      <li>
                        <i className="far fa-check-circle"></i> {plan?.plan_validity}
                        expiration
                      </li>
                    </ul>
                    <Link
                      to={`/admin/edit-subscriptions/${plan.plan_id}`}
                      className="btn btn-primary btn-block"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Subscriptions;
