import React, { Component } from "react";
import SidebarNav from "../sidebar";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import { AddNewPlan } from "./../../../API/PlanAPI";
import { toast } from "react-toastify";

class AddSubscriptions extends Component {

  constructor(props) {
	super(props)

	this.state= {
		PlanFormData:{}
	};
  }
	
  handleChange = (e) => {
	this.setState({ PlanFormData: {...this.state.PlanFormData, [e.target.name] : e.target.value} });
  }

  submitsubscription = async (e) => {
	e.preventDefault()
	const {history} = this.props;
	const resp = await AddNewPlan(this.state.PlanFormData);
	if(resp.status === 201){
		toast.success("New Plan Addedd Successfully");
		history.push("/admin/subscriptions");
	}
	else if(resp.status === 400){
		toast.error(resp.data);
	}
	else{
		toast.error("Some Error Occured");
	}
	
  }

  render() {
    return (
      <>
        <SidebarNav />
    
        <div className="page-wrapper">
			<div className="content container-fluid">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">

                    <div className="page-header">
							<div className="row">
								<div className="col-sm-12">
                                <h3 className="page-title">Add Subscription</h3>
								</div>
							</div>
						</div>

                        <div className="card">
							<div className="card-body">
							
                            <form method="POST" onSubmit={this.submitsubscription}>
									<div className="form-group">
										<label>Plan Name</label>
										<input className="form-control" name="plan_name" type="text" required placeholder="Free Trial" onChange={this.handleChange} />
									</div>
									<div className="form-group">
										<label>Plan Monthly Amount</label>
										<input className="form-control" name="charge" type="number" required onChange={this.handleChange} />
									</div>
									<div className="form-group">
											<label>Plan Amount</label>
											<input required className="form-control" name="charge" type="number" value={this.state.PlanFormData?.charge}  onChange={this.handleChange}/>
										</div>
										<div className="form-group">
											<label>Mr Solvo Service Fee</label>
											<input required className="form-control" name="service_fee" type="number" min="0" max="100" value={this.state.PlanFormData?.service_fee} placeholder="in %" onChange={this.handleChange}/>
										</div>
										<div className="form-group">
											<label>Booster Points</label>
											<input required className="form-control" name="profile_boost" type="number" min="0" value={this.state.PlanFormData?.profile_boost} placeholder="0" onChange={this.handleChange}/>
										</div>
									<div className="form-group">
										<label>Subscription Durations</label>
										<select name="plan_validity" onChange={this.handleChange} required className="form-control select">
											<option selected disabled>Select Duration</option>
											<option value="One Month">One Month</option>
											<option value="3 Months">3 Months</option>
											<option value="6 Months">6 Months</option>
											<option value="One Year">One Year</option>
											<option value="2 Years">2 Years</option>
										</select>
									</div>
									<div className="form-group">
											<label>Approval Time</label>
											<select name="max_approval" value={this.state.PlanFormData?.max_approval} onChange={this.handleChange} className="form-control select">
												<option value="" disabled selected>Select Max Approval Time</option>
												{
													Array(30).fill('day')?.map((d, index) =>
														<option value={index+1}>{index+1} Day(s)</option>
													)
												}
											</select>
										</div>
									<div className="form-group">
										<label className="d-block">Subscription Status</label>
										<div className="custom-control custom-radio custom-control-inline">
											<input type="radio" name="status" id="subscription_active" className="custom-control-input" value="Active" onChange={this.handleChange} checked={this.state.PlanFormData.status === "Active"} />
											<label className="custom-control-label" htmlFor="subscription_active">Active</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline">
											<input type="radio" name="status" id="subscription_inactive" className="custom-control-input" value="Inactive" onChange={this.handleChange}  checked={this.state.PlanFormData.status === "Inactive"}/>
											<label className="custom-control-label" htmlFor="subscription_inactive">Inactive</label>
										</div>
									</div>
									<div className="form-group">
										<label>Service Fee</label>
										<input className="form-control" name="service_fee" type="number" onChange={this.handleChange} required/>
									</div>
									<div className="form-group">
										<label>Profile Boost</label>
										<input className="form-control" name="profile_boost" type="number" onChange={this.handleChange} required/>
									</div>
									<div className="mt-4">
										<button className="btn btn-primary" type="submit">Submit</button>
										<Link to="/admin/subscriptions" className="btn btn-link">Cancel</Link>
									</div>
								</form>
							</div>
						</div>

                    </div>
                </div>
            </div>
        </div>                

      </>
    );
  }
}

export default withRouter(AddSubscriptions);
