import { FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, Select } from '@mui/material'
import React from 'react'
// import PropTypes from 'propTypes'

export default function DropdownSelect({ width, selectControlProps, required, value, onchange, options, label}) {
  return (
    <FormControl fullWidth={true} variant="standard">
        <InputLabel className='hide-in-mobile' id={`demo-simple-select-outlined-${label}`}>{label}</InputLabel>
        <Select
            labelId={`demo-simple-select-outlined-${label}`}
            value={value}
            style={{ width: width}}
            onChange={onchange}
            defaultValue={value}
            required={required}
            {...selectControlProps}
            >

            {Array.isArray(options) ? (

                  options?.map((option, index) =>
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                  )

            ):(

              (()=>{

                const menuItems = [];

                Object.keys(options).forEach(subCatName => {
                  const arrayValues = options[subCatName]


                  menuItems.push(
                
                    <ListSubheader className='text-blue-500 font-bold -mt-2 bg-blue-200 flex items-center'>
                       <ListItemText primary={subCatName} />
                    </ListSubheader>
                  )
  
                  arrayValues?.map((offering, index)=>{

                    const uniqueKey = `${subCatName}_${index}`;
                    menuItems.push(
                      <MenuItem className='max-h-72' key={uniqueKey} value={offering} >
                        {offering}
                      </MenuItem>
                    )
                  })
                });
                return menuItems
              })()

            )}


            
        {/* {
            options?.map((option, index) =>
                <MenuItem key={index} value={option}>{option}</MenuItem>
            )
        } */}
        </Select>
    </FormControl>
  )
}

DropdownSelect.defaultProps = {
  width: '8rem'
}
// DropdownSelect.propTypes = {
//   value: PropTypes.any.isRequired,
//   onchange: PropTypes.func.isRequired,
//   options: PropTypes.array.isRequired,
//   required: PropTypes.bool,
//   selectControlProps: PropTypes.object
// }