import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";


// export async function GetAllFaqs() {
//     const response = await axiosInstanceWithSecret.get(`/get-/`);
//     return response;
// }

export async function AddNewFaq(body) {
    const response = await axiosInstanceWithSecret.post(`create-faq`, body);
    return response;
}


export async function GetFaqByCategoryId(categoryid) {
    const response = await axiosInstanceWithSecret.get(`get-faq-by-category-id/${categoryid}`);
    return response;
}

export async function GetFaqById(faqid) {
    const response = await axiosInstanceWithSecret.get(`faq-detail/${faqid}`);
    return response;
}

export async function UpdateFaq(id, body) {
    const response = await axiosInstanceWithSecret.put(`/faq-detail/${id}`, body);
    return response;
}

export async function DeleteFaq(id) {
    const response = await axiosInstanceWithSecret.delete(`faq-detail/${id}`);
    return response;
}
