import React from 'react'
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import styles from './../CompleteProfile.module.css'
// import { Input } from 'postcss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { GetAllCategories, GetCategoryById } from '../../../../API/CategoryAPI';


export default function CategoryForm({ ExpData, mode, setFormData, FormData}) {
    // const [loaded, setloaded] = useState(false);
    const [specialization, setSpecialization] = useState([{ specialization:'', experience:'' }]);
    const [specializationList, setSpecializationList] = useState([]);
    const [formDataCategory, setformDataCategory] = useState({});
    const [selectedCategoryHierarchy, setselectedCategoryHierarchy] = useState({});
    const [AllCategories, setAllCategories] = useState([]);
    const [SubSpecializationArr, setSubSpecializationArr] = useState([]);
    const [isLoading, setisLoading] = useState(false)
    // const cclient = useContext(ClientContext);


    const FetchCategories = async () => {
        setisLoading(true)
        const catResp = await GetAllCategories('no', false);
        const CategoryResp = catResp;
        let catArr = [];
        Object.keys(CategoryResp).forEach(key => {
            catArr = [...catArr, ...CategoryResp?.[key] || []]
        });
        setAllCategories([...catArr]);
        setisLoading(false)
    }    

    const handleChange = (e) => {
        if(e.target.name === 'category'){
            setSpecialization([{ specialization:'', experience:'' }])
            // setFormData('specialization', [{ specialization:'', experience:'', subSpecialization:[], subSpecializationExp:[] }]);
            setSpecializationList([]);
        }
        setformDataCategory({...formDataCategory, [e.target.name]: e.target.value});
        // setFormData({...FormData, CategoryData: {...FormData, [e.target.name]: e.target.value} });
        setFormData(e.target.name, e.target.value);
    }

    const CategoryHierarchyHandler = (type, obj) => {
        setSpecializationList([]);
        // if(loaded){
        //     setSpecialization([{ specialization:'', experience:'' }]);
        // }
        setselectedCategoryHierarchy({...selectedCategoryHierarchy, [type]: obj });
    }

    const specializationHandler = (index, name, value, subSpecIndx=-1) => {
        let arr = [...specialization];
        if(name === 'specialization'){
            arr[index] = {...arr[index], [name]: value};
            arr[index]={...arr[index], subSpecialization : [], subSpecializationExp : []};
            setSpecialization(arr);
            return
        }
        if(name === 'subSpecialization'){
            try{
                if(value.length > 6){
                    toast.error('Max 6 Sub Specialization can be selected');
                    return;
                }
            }
            catch(err){
                console.log('Error ==>', err.message)
            }
        }

        if(name === 'subSpecializationExp'){
            let subSpecArr = arr[index]?.subSpecializationExp || [];
            if(subSpecIndx <= subSpecArr?.length - 1)
                subSpecArr[subSpecIndx] = value;
            else
                subSpecArr.push(value);
            arr[index] = {...arr[index], subSpecializationExp: [...subSpecArr]};
            console.log(arr);
            setSpecialization(arr);
            return;
        }
        arr[index] = {...arr[index], [name]: value};
        setSpecialization(arr);
    }

    const addSpecializationField = () => {
        let arr = [...specialization];
        arr.push({specialization: '', experience: ''})
        setSpecialization(arr);
    }

    const removeSpecializationField = (index) => {
        let arr = [...specialization];
        arr.splice(index, 1);
        setSpecialization(arr);
    }

    const getSubSpecialization = (data) =>{
        const subCatId = data;
        try{
        if( AllCategories?.length > 0) {
            const selectedSub = AllCategories?.find( elem => elem.category_id === FormData?.category);
            const selectedSpec = selectedSub?.SubCategories?.find( el => el.category_id === subCatId);
            const catArr = (selectedSpec?.SubSubCategories || []);
            // setSubSpecializationArr(catArr);
            return catArr;
        }
        }
        catch(err) {console.log('Error ==>', err); return [];}
        return [];
    }


    useEffect(() => {
        setFormData('specialization', specialization);
    }, [specialization])

    useEffect(() => {
        if(FormData?.specialization !== '' && typeof(FormData) !== 'undefined'){
            const spp = FormData?.specialization;
            let specializationSubArr = [];
            for (let i = 0; i < spp?.length; i++) {
                const spId = spp[i]?.specialization;
                if(spId !== ''){
                    let arr = getSubSpecialization(spId);
                    specializationSubArr.push(arr);
                }
            }
            setSubSpecializationArr(specializationSubArr);
        }
    }, [FormData, specializationList, AllCategories])

    useEffect(() => {
        setSpecializationList([]);
        if(selectedCategoryHierarchy?.category?.SubCategories?.length > 0){
            setSpecializationList([...selectedCategoryHierarchy?.category?.SubCategories || []]);
        }
        if(selectedCategoryHierarchy?.subCategory?.SubSubCategories?.length > 0){
            setSpecializationList([...selectedCategoryHierarchy?.subCategory?.SubSubCategories || []]);
        }
    }, [selectedCategoryHierarchy])
    
    const UpdateCategory = async (FormData) => {
        if(FormData.category){
            const resp = await GetCategoryById(FormData.category);
            CategoryHierarchyHandler('category',resp.data.Data);
        }
    }

    const handleTotalExperience = (e, key) => {
        const totalExpObj = {
            ...FormData.totalExperience,
            [key]: e.target.value
        }
        setFormData('totalExperience', totalExpObj);
    }

    useEffect(() => {
        if(AllCategories.length <= 0){
            FetchCategories();
        }
        // setloaded(true);
    }, [])
   

    // This will trigger when Form in edit mode
    useEffect(() => {
        if(FormData && formDataCategory)
        if(FormData.category !== formDataCategory.category){
            setformDataCategory({...FormData});
            FetchCategories();
            if(FormData?.specialization){
                setSpecialization([
                    ...FormData?.specialization || []
                ])
            }

            if( mode === 'edit' || FormData.category !== ''){
                // setformDataCategory({...formDataCategory, [e.target.name]: e.target.value});
                UpdateCategory(FormData);
                
            }
            setformDataCategory(ps => ({
                ...ps, 
                totalExperience: {
                    ...ExpData
                } 
            }) );
            // setloaded(true);
        }
    }, [FormData, ExpData]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
        


  return (
    <>
        <div>
        <Typography variant="h5" className="font-bold mb-2">Fill Category & Specializations </Typography>
        { !isLoading
        ? <>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Typography variant='body1'>Total Experience</Typography>
                        <FormControl className={styles.selectField} fullWidth={true} variant="standard">
                            <InputLabel id="total-exp-year-label">Select No. Of Year</InputLabel>
                            <Select
                                labelId="total-exp-year-label"
                                value={FormData?.totalExperience?.years || 0}
                                onChange={(e)=>handleTotalExperience(e, 'years')}
                                required
                                size="small"
                                name="totalExperienceYears"
                            >
                            { Array(30).fill('yr')?.map((yr, index) =>
                                <MenuItem key={`${index}-${yr}`} value={index}>{index}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
            </Grid>
            <FormControl mt={5} className={styles.selectField} fullWidth={true} variant="standard">
                <InputLabel id="demo-simple-select-outlined-label">Select Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formDataCategory?.category || ''}
                    onChange={handleChange}
                    label="Select Category"
                    required
                    name="category"
                    // defaultValue={formDataCategory?.category || ""}
                >
                <MenuItem value="" disabled={true} selected={true}>Select Category</MenuItem>
                {
                    AllCategories?.map((item, index) =>
                    <MenuItem 
                        selected={item.category_id === formDataCategory?.category} 
                        onClick={()=>CategoryHierarchyHandler('category',item)} 
                        key={index} 
                        value={item.category_id}>
                            {item.categoryName}
                    </MenuItem>
                )}
                </Select>
            </FormControl>
            
                <br/>
                <h5 className='mt-5  font-bold text-lg'>
                    Add Specialization
                </h5>
                { specialization?.map((item, index) =>
                <Fragment  key={index}>
                { (item) &&
                <Grid container spacing={2}>
                    {/* ===== Select Specialization ===== */}
                    <Grid item md xs={6}>
                        {/* ==== Select Specialization Dropdown ==== */}
                        <div className="w-full" style={{ width:'100%'}}>
                            <FormControl className={styles.selectField} fullWidth={true} variant="standard">
                            <InputLabel id="specialization-select-outlined-label">Specialization</InputLabel>
                                <Select
                                fullWidth={true}
                                    required
                                    labelId="specialization-select-outlined-label"
                                    id="specialization-select-outlined"
                                    value={specialization[index]?.specialization}
                                    onChange={(e)=>specializationHandler(index, 'specialization', e.target.value)} 
                                    label="Select Specialization"
                                >
                                    { specializationList.map((sp, index) => 
                                        <MenuItem key={`specialization-opt-${index}`} value={sp?.category_id}>{sp?.categoryName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                        {/* ====== Select Specialization Experience =======  */}
                        <Grid item md xs={6}>
                            {/* ==== Select Specialization Experience === */}
                            <div className="w-full" style={{ width:'100%'}}>
                                <FormControl className={`${styles.selectField} `} fullWidth={true} variant="standard">
                                    <InputLabel id="yr-select-outlined-label">Specialization Exp.</InputLabel>
                                    <Select
                                        labelId="yr-select-outlined-label"
                                        id="yr-select-outlined"
                                        value={specialization[index]?.experience}
                                        onChange={(e)=>specializationHandler(index, 'experience', e.target.value)} 
                                        label="Select Experience"
                                    >
                                        { Array(40).fill('Yrs').map((elem,index) => <MenuItem key={index} value={index+1}>
                                        {index === 0 ? `${index+1} ${'yr'}` : `${index+1} ${elem}`}
                                        </MenuItem>) }
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        {/* ==== Select Sub-Specialization Dropdown ==== */}
                        {
                            ((SubSpecializationArr[index]?.length >0) ||
                            (specialization[index]?.subSpecialization)
                            ) && 
                            <Grid item md xs={6}>
                                <div className="" style={{ width:'100%'}}>
                                <FormControl className={styles.selectField} fullWidth={true} variant="standard">
                                    <InputLabel id="demo-mutiple-">Sub Specialization</InputLabel>
                                    <Select
                                    labelId="demo-mutiple-"
                                    className={styles.subSpecMultiple}
                                    id="demo-mutiple-"
                                    multiple
                                    value={specialization[index]?.subSpecialization || []}
                                    onChange={(e)=>specializationHandler(index, 'subSpecialization', e.target.value)} 
                                    // input={<Input/>}
                                    renderValue={(selected) => selected.join(', ')}
                                    >
                                    {SubSpecializationArr[index]?.map((elem) => (
                                        <MenuItem key={elem} value={elem?.categoryName}>
                                            <Checkbox checked={specialization[index]?.subSpecialization?.indexOf(elem.categoryName) > -1} />
                                            <ListItemText primary={elem?.categoryName} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    {/* <small>Select Max 6 Sub Specialization</small> */}
                                </FormControl>
                                </div>
                            </Grid>
                        }
                    
                        {/* ===== Select Sub Specialization Experience ===== */}
                        { (specialization[index]?.subSpecialization || [] )?.length > 0 &&
                            <Grid item md xs={6}>
                            <Typography variant="subtitle2" >Select Exp in SubSpecialiattion</Typography>
                            {
                                (specialization[index]?.subSpecialization || [] )?.map((subSp, i) => 
                                <FormControl key={i} className={`${styles.selectField} `} fullWidth={true} variant="standard">
                                <InputLabel id="yr-select-outlined-label">{subSp}</InputLabel>
                                <Select
                                    labelId="yr-select-outlined-label"
                                    id="yr-select-outlined"
                                    value={specialization[index]?.subSpecializationExp?.[i]}
                                    onChange={(e)=>specializationHandler(index, 'subSpecializationExp', e.target.value, i)} 
                                    label="Select Experience in SubSpecialixattion"
                                >
                                    { Array(40).fill('Yrs').map((elem,index) => <MenuItem key={index} value={index+1}>{index === 0 ? `${index+1} ${'yr'}`:`${index+1} ${elem}`} </MenuItem>) }
                                </Select>
                            </FormControl>
                            )}
                        </Grid>
                        }

                    <Grid item md={1} xs={12}>
                        <div className={`p-3 text-center ${styles.specDeleteIcon}`}>
                            <FontAwesomeIcon icon={faTrashAlt} onClick={()=>removeSpecializationField(index)} className="text-red m-auto"/>
                        </div>
                    </Grid>
                </Grid>
                }
                </Fragment>
                )}
            { <div style={{ textAlign: 'right' }}>
                <Button variant='outlined' className="mt-5" onClick={addSpecializationField}>Add Specialization</Button></div> 
            }
        </>
        : <div className='w-full py-8 text-center'>
            <Typography variant='h5' className='text-gray-700 font-semibold'>Loading...</Typography>
        </div>
        }
    </div>
    </>
  )
}
