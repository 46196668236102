import React, { useEffect, useState } from 'react'
import SidebarNav from  "../sidebar"
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";
import { getNotificatiionTemplateList } from '../../../API/NotificationTemplate';

const Notification = () => {
    const [notificationTemplate, setNotificationTemplate] = useState([])
    const history = useHistory();
    const getAllNotificationTemplates = async () =>{
        const response = await getNotificatiionTemplateList();
        setNotificationTemplate([...notificationTemplate, ...response.data.results])
    }

    useEffect(()=>{
        getAllNotificationTemplates()
    }, [])


  return (
    <div>
        <SidebarNav />
        <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className='page-header'>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Notifications</h1>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <Button variant="primary" onClick={() => history.push("/admin/notification/add")}
                                    >Add New Notification</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-center mb-0 datatable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>User Type</th>
                                                <th>Notification Type</th>
                                                <th>Web Notification</th>
                                                <th>Whatspp Notification</th>
                                                <th>Email Notification</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            { notificationTemplate.map((template, index) => (
                                                        <tr key={template.id} onClick={() => history.push(`/admin/notification/edit/${template.id}`)}
                                                        style={{ cursor: 'pointer' }}>
                                                        <td style = {{borderRight: "solid" }}>{index + 1}</td>
                                                        <td style = {{borderRight: "solid" }}>{template.user_type}</td>
                                                        <td style = {{borderRight: "solid" }}>{template?.notification_type    }</td>
                                                        <td style = {{borderRight: "solid" }}>{""}</td>
                                                        <td style = {{borderRight: "solid" }}>{template?.whatsapp_template.substring(10, length)}</td>
                                                        <td style = {{borderRight: "solid" }}>{template?.email_template.substring(10, length)}</td>
                                                        </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Notification
