import React, { useEffect, useState } from "react";
import SidebarNav from "../../sidebar";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GetCategoryById, UpdateCategory } from "../../../../API/CategoryAPI";
import { GetFaqByCategoryId } from "../../../../API/FaqAPI";

const EditCategories = () => {
  const { categoryid } = useParams();

  const [formData, setFormData] = useState({});
  const [updatedformData, setUpdatedFormData] = useState({});
  const [status] = useState(["Active", "Hold", "Inactive"]);
  const [categoryId, setCategoryId] = useState(null);
  const [fetchedFaqs, setFetchedFaqs] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setUpdatedFormData((prevUpdatedFormData) => ({
      ...prevUpdatedFormData,
      [name]: value,
    }));
  };

  const handleMostPopularCheckbox = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mostPopular: !prevFormData.mostPopular,
    }));
    setUpdatedFormData((prevUpdatedFormData) => ({
      ...prevUpdatedFormData,
      mostPopular: !prevUpdatedFormData.mostPopular,
    }));
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      const resp = await GetCategoryById(categoryid);
      if (resp.status === 200) {
        setCategoryId(categoryid);
        setFormData({ ...resp.data?.Data || {} });
      }
    };
    fetchCategoryData();
  }, [categoryid]);

  const updateCategory = async (e) => {
    e.preventDefault();
    if (Object.keys(updatedformData).length < 1) {
      alert("No Change Detected");
      return;
    }
    const resp = await UpdateCategory(categoryId, updatedformData);
    if (resp.status === 201) {
      Swal.fire("Updated Successfully", "Category Data Updated Successfully", "success");
    }
  };


  useEffect(() => {
    GetFaqByCategoryId(categoryid).then((response) => {
        console.log(response.data, "line63")
        setFetchedFaqs(response.data)
    }).catch(error => {
        console.error("Error fetching posts:", error);
        setFetchedFaqs([])
    })

  }, [])

    // Function to extract <h3> content
  const extractH3Content = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const h3 = doc.querySelector('h3');
      return h3 ? h3.textContent : '';
  };

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title">Edit Category</h3>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <form method="post" onSubmit={updateCategory}>
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        onChange={handleChange}
                        required
                        value={formData?.categoryName || ""}
                        name="categoryName"
                        className="form-control"
                        type="text"
                      />
                    </div>
                    <div className="form-group">
                      <label>Category Description</label>
                      <textarea
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={formData?.desc || ""}
                        name="desc"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={formData?.status || ""}
                        name="status"
                      >
                        {status.map((st) => (
                          <option key={st} value={st}>
                            {st}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Is SuperCategory Tag</label>
                      {formData?.superCategory && (
                        <p>
                          Current: <b>{formData?.superCategory}</b>
                        </p>
                      )}
                      <select
                        defaultValue={formData?.superCategory || ""}
                        className="form-control"
                        onChange={handleChange}
                        name="superCategory"
                      >
                        <option value="">No Super Category</option>
                        <option
                          value="business"
                          selected={formData?.superCategory === "business"}
                        >
                          Business
                        </option>
                        <option
                          value="web-it"
                          selected={formData?.superCategory === "web-it"}
                        >
                          Web & IT
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Page Title Name</label>
                      <input
                        className="form-control"
                        value={formData?.pageTitle || ""}
                        onChange={handleChange}
                        name="pageTitle"
                      />
                    </div>
                    <div className="form-group">
                      <label>Meta Description</label>
                      <textarea
                        className="form-control"
                        value={formData?.metaDescription || ""}
                        onChange={handleChange}
                        name="metaDescription"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        checked={formData.mostPopular || false}
                        onChange={handleMostPopularCheckbox}
                        name="mostPopular"
                      />
                      <label>Add To MostPopular</label>
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-primary" type="submit">Save Changes</button>
                      <Link to="/admin/category" className="btn btn-link">Cancel</Link>
									  </div>
                  </form>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm">FAQ Section</div>
                      <div className="col-sm">
                        <Link to={`/admin/edit-categories/${categoryid}/add-faq`}>
                          +Add New
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

			        <div className="row">
                  <div className="col-md-12">
                      <div className="card">
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table className="table table-hover table-center mb-0 datatable">
                                      <thead>
                                          <tr>
                                              <th>#</th>
                                              <th>Questions</th>
                                              {/* <th>Status</th>
                                              <th>Date</th>
                                              <th>Author</th> */}
                                          </tr>
                                      </thead>
                                      <tbody>

                                      {fetchedFaqs && fetchedFaqs.map((faq, index) => {
                                            const heading = extractH3Content(faq.content); // Extract <h3> content

                                            return (
                                                <tr key={`post-${faq.id}`}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Link to={`/admin/edit-categories/${categoryid}/edit-faq/${faq.id}`}>
                                                            {heading || "No h2 Heading"} {/* Display the extracted h3 heading */}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                      })}
                                      </tbody>
                                  </table>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategories;
