import React, { Component } from "react";
import SidebarNav from "../sidebar";
import Collapse from 'react-bootstrap/Collapse';
// import Button from 'react-bootstrap/Button';
// import {
//   itemRender,
//   onShowSizeChange,
// } from "../../components/paginationfunction";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import AllBooking from "./all";
import { GetAllOrders } from "../../../API/OrderAPI";

class Booking extends Component {

  constructor(props) {
    super(props)
    this.state = {
        open: false 
    }
  }

  handleShow = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handleFilter = async (status) => {
    const resp = await GetAllOrders({ status: status });
    this.setState({ AllBooking: [...resp.data.data] });
  }

  componentDidMount = async () => {
    const resp = await GetAllOrders();
    this.setState({ AllBooking: [...resp.data.data] });
  }

  render() {

    return (
      <>
        <SidebarNav />
       
        <div className="page-wrapper">
			<div className="content container-fluid">

            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Booking List</h3>
                </div>
                <div className="col-auto text-right">
                  <a className="btn btn-white filter-btn" onClick={() => this.handleShow()} href={"#"} id="filter_search">
                    <i className="fas fa-filter"></i>
                  </a>
                </div>
              </div>
		      	</div>

            <Collapse in={this.state.open}>
              <div id="example-collapse-text">
                <div className="card filter-card">
                  <div className="card-body pb-0">
                    <form>
                      <div className="row filter-row">
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label>From Date</label>
                            <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label>To Date</label>
                            <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <button className="btn btn-primary btn-block" type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Collapse>

                <div className="row">
					          <div className="col-md-12">
                        <div className="card">
							              <div className="card-body">
								                <div className="table-responsive">
                                    <Tabs defaultActiveKey="booking">
                                        <Tab eventKey="booking" title="All Booking" onEnter={() => this.handleFilter()}>
                                            <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="pending" title="Pending" onEnter={() => this.handleFilter("Pending")}>
                                            <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="progress" title="In Progress" onEnter={() => this.handleFilter("In Progress")}>
                                         <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="scheduledConsultations" title="Scheduled Consultations" onEnter={() => this.handleFilter("Scheduled Consultations")}>
                                         <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="partiallyCompleted" title="Partially Completed" onEnter={() => this.handleFilter("Partially Completed")}>
                                          <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="completed" title="Completed" onEnter={() => this.handleFilter("Completed")}>
                                          <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="canceled" title="Canceled" onEnter={() => this.handleFilter("Cancelled")}>
                                          <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                        <Tab eventKey="tickets" title="Tickets" onEnter={() => this.handleFilter("Tickets")}>
                                        <AllBooking ordersData={this.state.AllBooking}/>
                                        </Tab>
                                    </Tabs>
                                 </div>
                            </div>
                        </div>
                     </div>  
                </div>                  

           </div>
        </div>  

      </>
    );
  }
}

export default Booking;
