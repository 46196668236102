import React, { Component } from "react";
import SidebarNav from "../sidebar";
import Collapse from 'react-bootstrap/Collapse';
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import { GetAllUsers } from "../../../API/UserAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCross, faMinusCircle, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: null,
          open: false,
          data: [],
        };
      }
      handleShow() {
          this.setState({
              open: !this.state.open
          }) 
      }

  componentDidMount = async () => {
    const resp = await GetAllUsers();
    console.log(resp, "line31")
    this.setState({data: [...resp.data.data]})
  }
  render() {

    const { data } = this.state;
    const columns = [
       
        {
          title: "Name",
          dataIndex: "firstName",
          render: (text, record) => (
           <>
            {text + " " + record?.lastName}
            </>
          ),
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: "Email",
          dataIndex: "email",
          render: (text, record) => (
           <>
             {text}
            </>
          ),
          sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Contact No",
            dataIndex: "phone",
            render: (text, record) => (
             <>
               {text}
              </>
            ),
            sorter: (a, b) => a.contact.length - b.contact.length,
          },
          {
            title: "Reg Date",
            dataIndex: "date",
            render: (text, record) => (
             <>
               {moment(record?.createdAt).format('DD-MMM yyy')}
              </>
            ),
            sorter: (a, b) => moment(a?.createdAt).diff(moment(b?.createdAt)),
          },
          {
            title: "Rating",
            dataIndex: "rating",
            render: (text, record) => (
             <>
               {text}
              </>
            ),
            sorter: (a, b) => a.Signup.length - b.Signup.length,
          },
          {
            title: "Verified",
            dataIndex: "verified",
            render: (text, record) => (
             <>
               {text.toString() === "true" ? <FontAwesomeIcon icon={faCheckCircle}/> : <FontAwesomeIcon icon={faMinusCircle}/>}
              </>
            ),
            sorter: (a, b) => a.login.length - b.login.length,
          },
      ];
  
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			      <div className="content container-fluid">
                  <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title">Users</h3>
                  </div>
                  <div className="col-auto text-right">
                    <a className="btn btn-white filter-btn" onClick={() => this.handleShow()} href={"#"} id="filter_search">
                      <i className="fas fa-filter"></i>
                    </a>
                  </div>
                </div>
              </div>
              <Collapse in={this.state.open}>
              <div id="example-collapse-text">
                <div className="card filter-card">
                  <div className="card-body pb-0">
                    <form>
                      <div className="row filter-row">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label>From Date</label>
                            <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label>To Date</label>
                            <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <button className="btn btn-primary btn-block" type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
          </Collapse>

                <div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
								<Table
                        className="table table-hover table-center mb-0 datatable"
                        style={{ overflowX: "auto", width:"100%"}}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                 
               
                                 </div>
                            </div>
                        </div> 
                    </div>
                </div>                       

          </div>
       </div>
      </>
    );
  }
}

export default Users;
