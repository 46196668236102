import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import SidebarNav from "../sidebar";
import { Grid, Typography, Box } from "@mui/material";
import { GetOrderDetailsById } from "../../../API/OrderAPI";

const dateToString = (date) => {
    return new Date(date).toLocaleString() || "Not Available";
  };

  // TODO: add payment details like service fee and other details in backend too 

function PaymentDetails() {
    const { bookingid } = useParams();
    const [order, setOrder] = useState({});


    useEffect(() => {
        async function fetchData() {
          try {
            const orderRes = await GetOrderDetailsById(bookingid);
            setOrder(orderRes.data.data);
    
          } catch (error) {
            console.error("Error fetching data", error);
          }
        }
    
        fetchData();
      }, [bookingid]);
    

    if (!order) return <div>Loading...</div>;

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Grid Grid container spacing={3} alignItems="center" justifyContent="center" className="mb-4">
              <Box sx={{ width: "100%", mb: 1 }}>
                <Typography
                  variant="h4"
                  component={"h2"}
                  align="center"
                  color="primary"
                >
                  Payment Details
                </Typography>
              </Box>
              <Box sx={{ width: "100%", mb: 1 }}>
                <Typography component={"h3"} align="center">
                  Type: {order.serviceType}
                </Typography>
                <Typography component={"h3"} align="center">
                  Amount: ₹{order.charges}
                </Typography>
                <Typography component={"h3"} align="center">
                  Transaction Id: {order.transaction_id}
                </Typography>
              </Box>
          </Grid>
          <Grid container spacing={3}>
              <Grid item xs={6}>
                  <Typography>Booking Id: {order.short_id}</Typography>
                  <Typography>
                  Paid At: {dateToString(order.created_at)}
                  </Typography>
                  <Typography>Service Provider: {order.serviceProviderId?.userId?.username}</Typography>
                  <Typography>Cancelled By: {order.cancelled_orders?.[0]?.cancelled_by}</Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography style={{ fontWeight: "bold", color: order.status === "Cancelled" ? "red" : "" }}>
                  Order Status: {order.status}
                  </Typography>
                  <Typography>Comment: {order.statusComment}</Typography>
                  <Typography>
                  User: {order.userId?.username}
                  </Typography>
                  <Typography>Cancellation Reason: {order.cancelled_orders?.[0]?.reason}</Typography>

              </Grid>
          </Grid>
        </div>
      </div>
      </>
  )
}

export default PaymentDetails