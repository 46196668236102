import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, 
  // ListItemSecondaryAction, ListItemText, 
  Typography } from '@mui/material'
import { List } from 'antd'
import React, { useState } from 'react'
import { Button, FormControl } from 'react-bootstrap'
  
  export default function ListBlock({ listArray, deleteItem, editItem }) {
  const [editable, seteditable] = useState(-1)
  const [editText, seteditText] = useState('')

  const editNow = (item, i) => {
    seteditable(i)
    seteditText(item)
  }

  const updateText = (index) => {
    editItem(index, editText)
    seteditable(-1)
  }

  return (
    <div className='mb-3' style={{ maxHeight: '15.5rem', overflowY: 'auto'}}>
    { listArray?.length > 0 ?
    <List dense={true}>
      {listArray?.map((item, i) =>
        <div key={i} className='my-1 px-3 py-1 border border-secondary d-flex justify-content-between align-items-center rounded-lg'>
          {
            editable === i
            ? <div className='d-flex'>
              <FormControl type='text' value={editText} onChange={(e)=>seteditText(e.target.value)}/>
              <Button variant='link' onClick={()=>updateText(i)}>Update</Button>
              </div>
              
            : <Typography variant='p'>{item}</Typography>
          }
          <div>
          <IconButton onClick={()=>editNow(item, i)} edge="end" aria-label="Edit">
              <FontAwesomeIcon className='text-primary' icon={faPen} />
            </IconButton>
            <IconButton onClick={()=>deleteItem(i)} edge="end" aria-label="delete">
              <FontAwesomeIcon className='text-danger' icon={faTrash} />
            </IconButton>
          </div>
        </div>
      )}
    </List>
    : <div style={{height:'7rem'}} className='w-100 text-center'>
        <Typography variant='p' className='text-secondary'>No Options Available</Typography>
    </div>
    }
  </div>
  )
}
