import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function addNotificationTemplate(body){
    // console.log(body, "Lline55")
    const response = await axiosInstanceWithSecret.post(`/list-create-notification-template/`, body);

    return response;
 }

 export async function getNotificatiionTemplateList(body){
   // console.log(body, "Lline55")
   const response = await axiosInstanceWithSecret.get(`/list-create-notification-template/`, body);

   return response;
}

export async function UpdateNotificationTemplate(id, body){
   const response = await axiosInstanceWithSecret.put(`update-notification-template/${id}/`, body);
   return response;
}

 export async function RetriveNotifcationTempate(id){
    const response = await axiosInstanceWithSecret.get(`retrive-notification-template/${id}/`, {
    });
    return response;
 }

 export async function DeleteNotifcationTempate(id){
    // const response = await axiosInstanceWithSecret.delete(`/cancellation-reason/${id}`);
    const response = await axiosInstanceWithSecret.delete(`delete-notification-template/${id}/`);
    return response;
 }

 export async function GetNotificationTypes(){
    const response = await axiosInstanceWithSecret.get(`/get-notification-types/`, {
    });
    return response;
 }