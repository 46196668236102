import React from "react";
import AppContainer from "./appcontainer.jsx";
import { BrowserRouter as Router, Route } from "react-router-dom";
import config from "config";
import 'react-toastify/dist/ReactToastify.css';

const AppRouter = (props) => {
  return (
    <Router>
      <Route render={(props) => <AppContainer {...props} />} />
    </Router>
  );
};

export default AppRouter;
