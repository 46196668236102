import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
// import { useMediaQuery } from '@mui/material';
import moment from "moment";

export default function WeeklyTimeComp({ setVal, Value }) {
    const [WeeklyTimeData, setWeeklyTimeData] = useState({});
    const [Days] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])

    const [CommonTime, setCommonTime] = useState(false);

  const transformTimeFormat = (timeData) => {
  const timePattern = /^\d{2}:\d{2}$/; // Matches the "HH:mm" format

  const formatTime = (time) => {
    if (timePattern.test(time)) {
      // Time is already in "HH:mm" format
      return time;
    } else {
      // Convert ISO 8601 to "HH:mm" using Moment.js
      return moment(time).format("HH:mm");
    }
  };

  Object.keys(timeData).forEach(day => {
    timeData[day].startTime = formatTime(timeData[day].startTime);
    timeData[day].endTime = formatTime(timeData[day].endTime);
  });

  return timeData;
};
    // const isSmallScreen = useMediaQuery('(max-width:600px)');

    // const handleWeekChange = (day, e) => {
    //     let availability = {...WeeklyTimeData };
    //     availability = {...availability, [day] : {...availability?.[day], [e.target.name]: e.target.value} };
    //     setWeeklyTimeData({ ...availability} );
    //     setVal({...availability});
    //   }
    const handleWeekChange = (day, value, fieldName) => {
        let availability = { ...WeeklyTimeData };
        if (!availability[day]) {
            availability[day] = {};
        }
        availability[day][fieldName] = value;
        setVal({ ...availability });
    };



    useEffect(() => {
        if(Value){
           const transformedTime =  transformTimeFormat(Value)
            setWeeklyTimeData({ ...transformedTime })
        }
    }, [Value])

    const replicateTime = () => {
        if (!CommonTime) {
            if (
                !WeeklyTimeData.Monday ||
                WeeklyTimeData?.Monday?.startTime === '' ||
                WeeklyTimeData?.Monday?.endTime === ''
            ) {
                toast.error("Please Select Monday's start-end Time first");
                return;
            } else {
                toast.success('Start-End Time Will be common for the Whole Week');
            }
        }
        const StartTime = WeeklyTimeData?.Monday?.startTime;
        const EndTime = WeeklyTimeData?.Monday?.endTime;
        let obj = { ...Value };
        for (let i = 1; i < Days.length; i++) {
            const day = Days[i];
            obj = {
                ...obj,
                [day]: {
                    startTime: !CommonTime ? StartTime : '',
                    endTime: !CommonTime ? EndTime : '',
                },
            };
        }
        setVal({ ...obj });
        setCommonTime((ps) => !ps);
    };
  return (
    <div>
      <div className="service-fields mb-3">
        <br />
        <h5 className="fw-bold">Please specify your preferred days & time for providing advice/ consultation</h5>
        <p className="text-sm text-gray-500">
          (To be Filled, only if you provide consultation/ training/ counselling)
        </p>
        <FormControlLabel
          control={<Checkbox onChange={replicateTime} checked={CommonTime} defaultChecked />}
          label="Keep Same Time For All Days"
        />
        <div className="membership-inf mt-5">
          <Grid container spacing={1}>
            {Days?.map((day) => (
              <>
                <Grid item md={4} sm={4}>
                  <h6 className="block w-[5rem] md:w-full">{day}</h6>
                  <hr />
                </Grid>
                <Grid item md={4} sm={4}>
                  <div className="form-group border-b border-gray-300">
                    <label className="mr-5 text-sm md:text-base block">Start Time</label>
                    {/* <TextField variant="standard" value={Value?.[day]?.startTime} onChange={ (e) => handleWeekChange(day, e)} name="startTime"  type="time" /> */}
                    <input
                      type="time"
                      value={Value && Value[day]?.startTime ? Value[day]?.startTime : null}
                      onChange={(e) => handleWeekChange(day, e.target.value, "startTime")}
                      name="startTime"
                      // className={isSmallScreen ? 'w-32' : 'w-48'}
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={4}>
                  <div className="form-group border-b border-gray-300">
                    <label className="mr-5 text-sm md:text-base block">End Time</label>
                    {/* <TextField id='hgh' variant="standard" value={Value?.[day]?.endTime} onChange={ (e) => handleWeekChange(day, e)} name="endTime" type="time" /> */}
                    <input
                      type="time"
                      value={Value && Value[day]?.endTime ? Value[day]?.endTime : null}
                      onChange={(e) => handleWeekChange(day, e.target.value, "endTime")}
                      name="endTime"
                      // className={isSmallScreen ? 'w-32' : 'w-48'}
                    />
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
