import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

// ========================================================
// ====================== User APIS =======================
// ========================================================
export async function Login(email, password){
    const response = await axiosInstanceWithSecret.post(`/login`, {email: email, password: password})
    return response;
 }


 export async function GetUserById(userid){
   const response = await axiosInstanceWithSecret.get(`/get-user-by-id`,{
      params: {user_id: userid}
   })
   return response;
}


 export async function Register(body){
    //try{
    const response = await axiosInstanceWithSecret.post(`/register`, body);
    return response;
   //  }
   //  catch(err){
   //    //  console.log("ZErr=>", err.response)
   //     let obj = {data:err.data, status: err.response.status};
   //     return obj;
   //  }
 }

export async function GetAllUsers(){
   const response = await axiosInstanceWithSecret.get(`/get-all-users`);
    return response;
}

export async function UpdateUser(body, userid){
   const response = await axiosInstanceWithSecret.put(`/update-user`, body, {
      params:{
         user_id: userid
      }
   })
   return response;
}
