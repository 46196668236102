import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";
// Get all grouped chats for admin
export const getGroupedChatsAdmin = async () => {
    try {
        const response = await axiosInstanceWithSecret.get(`/get-grouped-chats-admin`);
        return response;
    } catch (error) {
        throw error;
    }
};


// Fetch chats between a provider and user
export const fetchChatsByUsers = async (providerId, userId) => {
  try {
    const response = await axiosInstanceWithSecret.get(`/admin/chats/${providerId}/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};