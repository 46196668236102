import { Table, TableBody, TableCell, TableRow, TextField, 
  // TableHead 
} from '@mui/material';
// import { Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import {  SettingNames } from '../../../../GlobalComps/Constant';
import { UpdateSettings } from '../../../../API/Settings';
import { GetAllPlans } from '../../../../API/PlanAPI';
import { 
  // GetAllCategories, 
  GetAllCategoriesOptimized } from '../../../../API/CategoryAPI';
import { Convert2dTo1dArray } from '../../../../GlobalComps/Helper';

export default function PricingSettingsTab({ settingsData }) {
  const [formData, setformData] = useState({});
  const [PlansList, setPlansList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [categoryId, setcategoryId] = useState('');

  const handleChange = (e, plnId) => {
    setformData({...formData, 
      [categoryId]: {...formData?.[categoryId], [plnId]: Number(e.target.value)}
  });
  }


  const fetchPlanData = async () => {
    const resp = await GetAllPlans();
    if(resp){
      setPlansList(resp.data.data);
    }
  }

  const fetchCategoryList = async () => {
    const resp = await GetAllCategoriesOptimized();
    if(resp.status === 200){
        setCategoryList(Convert2dTo1dArray(Object.values(resp.data)));
    }
  }


  const updateSettings = async () => {
    const body = {
      settingName: SettingNames.PRICING_SETTING,
      setting1:{...formData}    
    }
    const resp = await UpdateSettings(body, SettingNames.PRICING_SETTING);
    if(resp.status === 200) {
      alert('Settings updated successfully');
    }
  }

  useEffect(() => {
    setformData({ ...settingsData?.setting1 });
  }, [settingsData])
  

  useEffect(() => {
    fetchPlanData();
    fetchCategoryList();
  }, []);
  
  return (
    <div className="card">
        <div className="card-header">
            <h2>Pricing Settings</h2>
        </div>
        <div className="card-body">
            <form>
                <div className="form-group">
                        <label>Select Category</label>
                        <select defaultValue="" 
                        value={categoryId} 
                        className="form-control" 
                        name="category" 
                        placeholder="Select category" 
                        onChange={(e)=>setcategoryId(e.target.value)}>
                            <option value="" disabled>Select Category</option>
                            {
                                CategoryList?.map((cat) =>
                                <option value={cat?.category_id}>{cat?.categoryName}</option>
                            )}
                        </select>
                    </div>
                {/* <TableContainer component={Paper}> */}
                { categoryId !=='' &&
                  <Table>
                  <TableBody>
                  { PlansList?.map((plan) =>
                    <TableRow>
                      <TableCell>{plan?.plan_name}</TableCell>
                      <TableCell>
                        <TextField 
                          id="outlined-basic"
                          value={formData?.[categoryId]?.[plan?.plan_id] || plan?.charge} 
                          label="Plan Price"
                          type="number"
                          onChange={(e) => handleChange(e, plan?.plan_id)} 
                          variant="outlined" />
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
                }
                {/* </TableContainer> */}
            </form>
                <button onClick={updateSettings} className="mt-5 btn btn-primary">Update Settings</button>
        </div>
    </div>
  )
}
