import React, { Component } from "react";
import SidebarNav from "../sidebar";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import WebsiteSettings from "./elems/WebsiteSettings";
import CategorySettingsTab from "./elems/CategorySettingsTab";
import { GetSettings } from "../../../API/Settings";
import { SettingNames } from "../../../GlobalComps/Constant";
import PricingSettingsTab from "./elems/PricingSettingsTab";
import DynamicContent from "./elems/DynamicContent";
import CreateServicesSettings from "./elems/CreateServicesSettings";
import CancellationSettings from "./elems/CancellationSettings";

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state={
			Settings:{}
		};
	}
 
	fetchSettings = async () =>{
		const resp = await GetSettings();
		if(resp){
			this.setState({Settings:{...resp.data.data}})
		}
	}
	
	componentDidMount = () =>{
		this.fetchSettings();
	}

  render() {
 
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper settings">
			<div className="content container-fluid">
			
				
				<div className="page-header">
					<div className="row">
						<div className="col-12">
							<h3 className="page-title">General Settings</h3>
						</div>
					</div>
				</div>

				<Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
					<Tab eventKey="general" title="General Settings">
						<WebsiteSettings settingsData={this.state.Settings?.[SettingNames.GENERAL_SETTING]}/>
					</Tab>
					<Tab eventKey="category" title="Category Settings">
						<CategorySettingsTab settingsData={this.state.Settings?.[SettingNames.SERVICE_PROVIDER_FORM]}/>
					</Tab>
					<Tab eventKey="payment" title="Payment Gateway">
						<PricingSettingsTab settingsData={this.state.Settings?.[SettingNames.PRICING_SETTING]}/>
					</Tab>
					<Tab eventKey="sms" title="Set Instructions/Content">
						<DynamicContent settingsData={this.state.Settings?.[SettingNames.DYNAMIC_CONTENT_SETTING]}/>
					</Tab>
					<Tab eventKey="createServices" title="Create Service Settings">
						<CreateServicesSettings orgsettingsData={this.state?.Settings?.[SettingNames.CREATE_SERVICES_SETTING]}/>
					</Tab>
					<Tab eventKey="cancellationSettings" title="Cancellation Settings">
						<CancellationSettings orgsettingsData={this.state?.Settings?.[SettingNames.CREATE_SERVICES_SETTING]}/>
					</Tab>
				</Tabs>
          </div>
        </div>        
      </>
    );
  }
}

export default Settings;
