import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import SidebarNav from  "../../sidebar"
// import UpdateNotificationTemplate from "../../../../API/NotificationTemplate"
import { GetNotificationTypes, RetriveNotifcationTempate } from '../../../../API/NotificationTemplate';
import { DeleteNotifcationTempate } from '../../../../API/NotificationTemplate';
import { UpdateNotificationTemplate } from '../../../../API/NotificationTemplate';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
const EditNotification = () => {
    const [notification, setNotification] = useState({})
    const [notificationTypes, setNotifcationTypes] = useState()

    // const notificationTypes =  ["Query", "Order", "Normal", "Chat", "Query Reply", "Cancellation", "Refund", "Payment", "Withdrawal", "Consultation Confirmation", "Consultation Rescheduled", "Order Booked", "Order Accepted"]
    const userTypes = ["User", "ServiceProvider"]
    const history = useHistory();
    const { templateid } = useParams();

    const getNotifcation = async () => {
        const response = await RetriveNotifcationTempate(templateid)
        setNotification(response.data)
        // console.log(response.data, "09088")
    }

    useEffect(()=>{
        getNotifcation()
    }, [templateid])
    const fetchtNotificationTypes = async () => {
        try {
            const response = await GetNotificationTypes();
            setNotifcationTypes(response.data)
        } catch (error) {
            console.error("Error fetching notification types:", error);
        }
    }
    useEffect(() => {
       fetchtNotificationTypes()
    }, [])

    const updateTemplate = async () => {
       let response =  await UpdateNotificationTemplate(templateid, notification)
       if (response.status=== 200){
        toast.success("Template updated successfully");
        history.push(`/admin/notification`)
       }else{
        console.error("Error deleting Notifcation Template:", error);
       }
    }
    const handleNotificationUpdate = (e) => {
        e.preventDefault();
        updateTemplate()
    }

    const handleChangeState = (event) => {
        setNotification({
            ...notification,
            [event.target.name]: event.target.value
        });

    }

    const handleContentDelete = () => {
        Swal.fire({
            title: "Are you sure you want to delete the Template",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, proceed!"
          }).then(async (result) => {
            if (result.isConfirmed) {
                DeleteNotifcationTempate(templateid).then((response) => {
                if (response.status === 204) {
                  toast.success("Template deleted successfully");
                  history.push(`/admin/notification`)
                }
                console.log(response)
              }).catch(error => {
                console.error("Error deleting Notifcation Template:", error);
              });
            }
          })

    }

  return (
    <div>
    <SidebarNav />
    <div className="page-wrapper">
              <div className="content container-fluid">
                  <form className='page-header' onSubmit={handleNotificationUpdate}>
                      <div className="row my-2">

                          <label >Choose a user type:</label>
                          <select value={notification?.user_type} name="user_type" onChange={handleChangeState}>
                              <option  >--Please choose an option--</option>
                              {userTypes.map((type, index) => (
                                  <option key={index} value={type}>{type}</option>
                              ))}
                              </select>
                      </div>
                      <div className="row my-2">
                          <label >Choose a notification type:</label>
                          <select value={notification?.notification_type}  name="notification_type" onChange={handleChangeState}>
                              <option   defaultValue >--Please choose an option--</option>
                              {notificationTypes && notificationTypes.map((type, index) => (
                                   <option key={index} value={type}>{type}</option>
                              ))}
                              </select>
                      </div>
                      <div className="row my-2">
                          <label  htmlFor="webNotification" className="mr-2">Web Notification</label>
                          <input type="text" name="web_notification" id="webNotification" value={notification?.web_notification} placeholder="Web notification" onChange={handleChangeState}/>
                      </div>
                      <div className="row my-2">
                          <label htmlFor="whatsappNotification" className="mr-2">Whatsapp Notification</label>
                          <textarea type="text" name="whatsapp_template" id='whatsappNotification' value={notification?.whatsapp_template}  rows="4" placeholder="Whatsapp notification" onChange={handleChangeState}></textarea>
                      </div>
                      <div className="row my-2">
                          <label htmlFor="emailNotification" className="mr-2">Email Notification</label>
                          <textarea type="text" name="email_template" id='emailNotification' value={notification?.email_template}  rows="4" placeholder="Email notification" onChange={handleChangeState}></textarea>
                      </div>

                      <div className="col my-2 align-between">
                            <Button variant="primary" type="submit" style={{marginRight: "10px"}}>Update</Button>
                            <Button variant="danger" onClick={handleContentDelete}>Delete</Button>
                        </div>
                  </form>

              </div>
          </div>
  </div>
  )
}

export default EditNotification