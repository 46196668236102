import React, { Fragment, useEffect, useMemo, useState } from 'react'
import DefaultImg from './../admin/assets/images/default-image-upload.jpg'
import DefaultPDFImg from './../admin/assets/images/default-pdf-image.png'
// import { Player } from "video-react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { getFileType } from './Helper';
import { Env } from '../Env';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faFile, faVideo } from '@fortawesome/free-solid-svg-icons';
import PDFViewer from './PDF/PDFViewer';

export default function PreviewFile({ fileData }) {
    const [FileUrl, setFileUrl] = useState(DefaultImg)
    const [fileType, setfileType] = useState('')
    const [zoomIn, setzoomIn] = useState(false)
    const [multipleFiles, setmultipleFiles] = useState(false)


    useEffect(() => {
    if(fileData){
      if(typeof(fileData) !== 'string'){
        if(fileData?.type?.includes('image') || fileData?.type?.includes('video')){
            const createdUrl = window.URL.createObjectURL(fileData)
            setfileType(fileData?.type)
            setFileUrl(createdUrl)
        }
        else if(fileData?.type?.includes('pdf')) {
            setfileType('pdf')
            setFileUrl(DefaultPDFImg)
        }
        else {
          setfileType('other')
          setFileUrl(DefaultPDFImg)
       }
      }
      // If File is a url
      else{
        const filesArr = fileData?.split(',')
        if(filesArr?.length > 1){
          setmultipleFiles(true)
          setfileType(filesArr?.map(el => getFileType(el)))
          setFileUrl(filesArr?.map(el => Env.S3_HOSTNAME + el))
        }
        else{
          setfileType(getFileType(fileData))
          setFileUrl(Env.S3_HOSTNAME+fileData)
        }
      }
    }else{
      setFileUrl(DefaultImg)
      setfileType('other')
    }
  }, [fileData])

// console.log("url===>",FileUrl)

  // const filesArray = useMemo(()=>fileData?.split(','), [fileData])
  const filesArray = useMemo(() => {
    if (typeof fileData === 'string') {
      return fileData.split(',');
    } else {
      return [];
    }
  }, [fileData]);
  
 


  
  return (
    <Fragment>
    <div>
    { !multipleFiles ?
      <>
        { fileType?.includes('video')
            // eslint-disable-next-line jsx-a11y/media-has-caption
            && <div onClick={()=>setzoomIn(true)}>
              <FontAwesomeIcon icon={faVideo} className='text-3xl'/>
            </div>
        }
        {  fileType?.includes('image') &&
           <img onClick={()=>setzoomIn(true)} alt='file' src={FileUrl} width={75} height={50}/>
        }
        {  fileType?.includes('pdf') &&
           <div onClick={()=>setzoomIn(true)}>
            {/* <FontAwesomeIcon icon={faFilePdf} className='text-3xl'/> */}
            <img src={DefaultPDFImg} alt='PDF File' width={50} height={50} />
           </div>
        }
        {  fileType?.includes('audio') &&
           <audio rel="noopener noreferrer" title='view-file'>
            Audio
            {/* <FontAwesomeIcon icon={faFilePdf} className='text-3xl'/> */}
            <source src={FileUrl}/>
           </audio>
        }

        {  fileType?.includes('word') &&
           <div onClick={()=>setzoomIn(true)}>
            {/* <FontAwesomeIcon icon={faFilePdf} className='text-3xl'/> */}
            <FontAwesomeIcon icon={faFile} className='text-3xl'/>
            {/* <iframe title='DOC Portfolio' className='lg:w-[665px] lg:h-[400px]' src={`https://view.officeapps.live.com/op/embed.aspx?src=${FileUrl}`}  frameBorder='0'></iframe> */}
           </div>
        }
        {/* {  fileType?.includes('other') &&
           <a target='_blank' rel="noopener noreferrer" href={FileUrl} title='view-file'>
            {/* <FontAwesomeIcon icon={faFilePdf} className='text-3xl'/>
            View File
           </a>
        } */}
        </>
        : <div className='p-3' onClick={() => setzoomIn(true)} title={`${typeof fileData === 'string' ? fileData.split(',')?.length : '0'} Files available`}>
        <FontAwesomeIcon icon={faFilePdf} className='fs-1' />
        <sup className='fs-3 ml-2'>{typeof fileData === 'string' ? fileData.split(',')?.length : '0'}</sup>
      </div>
      
      
    }
    </div>

    <Dialog open={zoomIn} onClose={()=>setzoomIn(false)}>
      <DialogContent className='w-screen'>
      {/* <Grid container spacing={2}> */}
      { filesArray?.map((file, index) => 
      <>
       { getFileType(file)?.includes('pdf')
              && <PDFViewer fileLink={Env.S3_HOSTNAME+file} />
          }
          { getFileType(file)?.includes('video')
              // eslint-disable-next-line jsx-a11y/media-has-caption
              && <video width="100%" height="400" controls>
          <source src={Env.S3_HOSTNAME+file} type="video/mp4" />
        </video>
          }
          {  getFileType(file)?.includes('image') &&
            <img alt='file' src={Env.S3_HOSTNAME+file} height={500} width={500}/>
          }

          {  getFileType(file)?.includes('word') &&
          //  <div>
           <iframe title='DOC Portfolio' style={{ width: '75vh', height: '60vh' }} src={`https://view.officeapps.live.com/op/embed.aspx?src=${Env.S3_HOSTNAME+file}`}></iframe>
          //  </div>
          }

{         getFileType(file)?.includes('ppt') &&
          //  <div>
           <iframe title='PPT Portfolio' style={{ width: '75vh', height: '60vh' }} src={`https://view.officeapps.live.com/op/embed.aspx?src=${Env.S3_HOSTNAME+file}`}></iframe>
          //  </div>
          }
          {/* {  !(['video', 'image']?.includes(getFileType(file))) &&
            <a target='_blank' className='border rounded' rel="noopener noreferrer" href={FileUrl?.[index] || ''} title='view-file'>
              <FontAwesomeIcon icon={faFile} className='fs-1'/><br/>
              View File
            </a>
          } */}
          </>
        )}
        {/* </Grid> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setzoomIn(false)}>Close</Button>
      </DialogActions>
    </Dialog>
    </Fragment>
  )
}
