import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Input, Select } from '@mui/material';
import * as React from 'react';

export default function DropdownMultiSelect({ width, selectControlProps, required, value, onchange, options, label }) {
//   const theme = useTheme();

  const handleChange = (event) => {
    const { target: { value },} = event;
    onchange(
      typeof value === 'string' ? value?.split(',') : value,
    );
  };


  return (
      <FormControl fullWidth={true} variant='standard'>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          required={required}
          value={value}
          onChange={handleChange}
          {...selectControlProps}
          input={<Input style={{width:width}} variant='standard' label={label} />}
          renderValue={(selected) => selected.join(', ')}
        >
          {options?.map((name) => (
            <MenuItem
              key={name}
              value={name}
            //   style={getStyles(name, value, theme)}
            >
                <Checkbox value={name} checked={value?.includes(name)} />
                <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}

DropdownMultiSelect.defaultProps = {
  width: '8rem'
}