import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UpdateSettings } from '../../../../API/Settings';
import { SettingNames } from '../../../../GlobalComps/Constant';
import AdminContext from '../../../AdminContext/AdminContext';

export default function DynamicContent() {
  const Aadmin = useContext(AdminContext);
  const [CompleteProfileCategory, setCompleteProfileCategory] = useState('');
  const [CompleteProfileCreateService, setCompleteProfileCreateService] = useState('');
  const [AddServiceSection, setAddServiceSection] = useState('')
  const [CompleteProfileDocuments, setCompleteProfileDocuments] = useState('')
  const [ChoosePlans, setChoosePlans] = useState('')

  const [formData, setformData] = useState({});

  useEffect(() => {
    setformData({...formData, 
      Category: CompleteProfileCategory, 
      CreateService: CompleteProfileCreateService,
      AddService: AddServiceSection,
      Documents: CompleteProfileDocuments,
      ChoosePlans: ChoosePlans
    });
  }, [AddServiceSection, CompleteProfileCategory, CompleteProfileCreateService])
  
  const updateSettings = async () => {
    const body = {
      settingName: SettingNames.DYNAMIC_CONTENT,
      setting1:{...formData}    
    }
    const resp = await UpdateSettings(body, SettingNames.DYNAMIC_CONTENT);
    if(resp.status === 200) {
      alert('Settings updated successfully');
    }
  }

  useEffect(() => {
    const settsData = Aadmin?.settings?.[SettingNames.DYNAMIC_CONTENT]?.setting1;
      setCompleteProfileCategory(settsData?.Category || '');
      setCompleteProfileCreateService(settsData?.CreateService || '');
      setAddServiceSection(settsData?.AddService || '');
      setCompleteProfileDocuments(settsData?.Documents || '');
      setChoosePlans(settsData?.ChoosePlans || '')  
  }, [Aadmin])
  

  return (
    <div className="card mb-5">
        <div className="card-header">
            <h5 className="card-title">Complete Profile Page (Web-Content)</h5>
        </div>
        <div className="card-body">
            <p><b>Select Categories (Step-1)</b></p>
            <ReactQuill theme="snow" value={CompleteProfileCategory} onChange={setCompleteProfileCategory} />
            <br/>
            <p><b>Create Services Page (Step-2)</b></p>
            <ReactQuill theme="snow" value={CompleteProfileCreateService} onChange={setCompleteProfileCreateService} />
            <br/>
            <p><b>Add Services/Products Instructions</b></p>
            <ReactQuill theme="snow" value={AddServiceSection} onChange={setAddServiceSection} />
            <p><b>Add Documents Form Instructions</b></p>
            <ReactQuill theme="snow" value={CompleteProfileDocuments} onChange={setCompleteProfileDocuments} />
            <p><b>Add Choose Plan Instruction</b></p>
            <ReactQuill theme="snow" value={ChoosePlans} onChange={setChoosePlans} />
        </div>
        <button className="btn btn-primary mx-5" onClick={updateSettings}>Update Settings</button>
    </div>
  )
}
