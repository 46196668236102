import React from 'react';
import ServiceProviderCan from './Comps/ServiceProviderCan';
import UserCan from './Comps/UserCan';

const CancellationSettings = () => {


    return (
        <>
            <ServiceProviderCan/>
            <UserCan/>
        </>
    );
};

export default CancellationSettings;
