import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetAllCategories(){
    const response = await axiosInstanceWithSecret.get(`/category-hierarchy-admin`);
    return response.data;
 }

 export async function GetAllParentCategories(only_parent_categories = false){
   const response = await axiosInstanceWithSecret.get(`/get-all-category?only_parent_categories=${only_parent_categories}`);
   return response;
}

 export async function GetAllCategoriesOptimized(){
   const response = await axiosInstanceWithSecret.get(`/category-hierarchy`,{
      params: { supercat: 'yes', active_only: false }
   });
   return response;
}

 export async function AddNewCategory(body){
    const response = await axiosInstanceWithSecret.post(`/create-category`, body);
    return response;
 }

 export async function GetCategoryById(catId){
    const response = await axiosInstanceWithSecret.get(`/get-category-by-id`, {
      params:{
         category_id : catId
      }
    });
    return response;
 }

 export async function GetCategoryByName(catname){
   const response = await axiosInstanceWithSecret.get(`/get-category-by-name`, {
     params:{
        category_name : catname
     }
   });
   return response;
}

export async function GetMostPopularCategories(){
   const response = await axiosInstanceWithSecret.get(`/get-most-popular-category`);
   return response;
}


 export async function UpdateCategory(catId, body){
    const response = await axiosInstanceWithSecret.patch(`/update-category?category_id=${catId}`, body);
    return response;
 }

 export async function DeleteCategory(catId){
    const response = await axiosInstanceWithSecret.delete(`/remove-category?category_id=${catId}`);
    return response;
 }