import axios from "axios";
import { toast } from "react-toastify";

export function ObjectToArray(obj) {
    const keys = Object.keys(obj);
    let arr=[];
    for (let i = 0; i < keys.length; i++) {
        const element = obj[keys[i]];
        arr.push(element);
    }
    return arr;
}

export function JsonToFormdata(JSONobj) {
  let payloadFormdata = new FormData();
  const array = Object.keys(JSONobj);
  for (let i = 0; i < array.length; i++) {
    const key = array[i];
    const val = JSONobj?.[key];
    if (Array.isArray(val)) {
      for (let j = 0; j < val.length; j++) {
        const arrayKey = `${key}`;
        payloadFormdata.append(arrayKey, val[j]);
      }
    } else {
      payloadFormdata.append(key, val);
    }
  }
  return payloadFormdata;
}

export function convertToSlug(Text) {
    return Text.toLowerCase()
               .replace(/ /g, '-')
               .replace(/[^\w-]+/g, '');
  }

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const validatePhone = (no) => {
    return String(no).match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
}

  export function preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
  
    if (!charStr.match(/^[0-9]+$/) || e.target.value.length>9)
      e.preventDefault();
  }

export function DeSlug(name) {
  return name.replaceAll('__', '/ ').replaceAll('-',' ').replaceAll('~', '-');
}

export async function getcurrentIP(){
  const res = await axios.get('https://geolocation-db.com/json/')
  // console.log(res?.data);
  return res?.data?.IPv4 || '';
}


export function RequiredFieldsAlert(requiredFields, data){
  for (let i = 0; i < requiredFields.length; i++) {
    const field = requiredFields[i];
    if(["", null].includes(data?.[field] || "")){
      toast.error(`${field} is required`);
      return false;
    }          
  }
  return true;
}

export function checkNull(data){
  const TypeOfVar = typeof data;
  if(TypeOfVar === 'undefined'){
    return false;
  }
  else if(TypeOfVar === 'object'){
    return Object.keys(data).length > 0;
  }
  else if(TypeOfVar === 'array'){
    return data?.length > 0;
  }
  else{
    return ["", null].includes(data || "")
  }

}

export const removeItemFromArray = (arr, item, type='index') => {
  let index;
  let array = [...arr]
  if(type === 'index'){
    index = item
  }
  else{
    index = array.indexOf(item);
  }
  if (index > -1) { // only splice array when item is found
    array.splice(index, 1); // 2nd parameter means remove one item only
  }
  return array;
}

export function CompareAlphabetSort(a, b) {
  if(a?.toLowerCase() > b?.toLowerCase()){
    return 1;
  }
  else if(a?.toLowerCase() < b?.toLowerCase()){
    return -1;
  }
  else{
    return 0;
  }
}

export const Convert2dTo1dArray = (test2d) => {
  const merged = test2d.reduce(function(prev, next) {
    return prev.concat(next);
  });
  return merged
}

export const checkForImage = (imgLink) => {
  const exten = imgLink?.split('.')?.[1]?.toLowerCase()
  if(['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'].includes(exten)){
    return true
  }
  return false

}

export const getFileType = (imgLink) => {
  const exten = imgLink?.split('.')?.pop()?.toLowerCase();
  if(['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif', 'jfif'].includes(exten)){
    return 'image'
  }
  else if(['webm', 'mpg', 'mp2', 'peg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov','qt', 'flv', 'swf', 'avchd'].includes(exten)){
    return 'video'
  }
  else if(['mp3', 'wav', 'aiff', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'm4a', 'mid']?.includes(exten)){
    return 'audio'
  }
  else if(['pdf']?.includes(exten)){
    return 'pdf'
  }
  else if(['pptx'].includes(exten)){
    return 'ppt';
  }
  else if(['doc', 'docx'].includes(exten)){ // Include doc and docx files
    return 'word';
  }
  else{
    return 'file'
  }
}

export function toggleValueInArray(arr, value) {
  const index = arr.indexOf(value);
  if (index === -1) {
    // Value not found in array, add it
    return [...arr, value];
  } else {
    // Value found in array, remove it
    return arr.filter(item => item !== value);
  }
}