import React, { Fragment, useContext, useEffect, useState } from 'react'
import { GetAllCategoriesOptimized } from '../../../../API/CategoryAPI';
import { Convert2dTo1dArray } from '../../../../GlobalComps/Helper';
import { Divider, FormControlLabel, Grid, MenuItem, Select, Switch, Typography, Button, Input, Checkbox, ListItemText } from '@mui/material';
import ListBlock from './Comps/ListBlock';
import AddOptionForm from './Comps/AddOptionForm';
import { toast } from 'react-toastify';
import { SettingNames } from '../../../../GlobalComps/Constant';
import { UpdateSettings } from '../../../../API/Settings';
import AdminContext from '../../../AdminContext/AdminContext';
import DropdownMultiSelect from '../../../../GlobalComps/DropdownMultiSelect';

export default function CreateServicesSettings({ orgsettingsData }) {
    // Context Data
    const ccontext = useContext(AdminContext)

    // States
    const [selectedCategory, setselectedCategory] = useState('');
    const [CategoryList, setCategoryList] = useState([]);
    const [servicesTypeArr] = useState(['Services', 'Retainership'])
    const [settingsData, setsettingsData] = useState({})

    // States zfor Specialities
    const [subCategoriesArray, setsubCategoriesArray] = useState([])   // SubCategories Array
    const [specialitiesSelectedCategories, setspecialitiesSelectedCategories] = useState([])
    const [selectedSubCategorySpeciality, setselectedSubCategorySpeciality] = useState(['all']) // For Specialities (Multi-Select)
    const [settingsData2, setsettingsData2] = useState({}) // For specialites Data

    const [selectedSubCategory, setselectedSubCategory] = useState('')

    const handleChange = (e) => {
      setselectedCategory(e.target.value);
    }

    // For Speciality Setting
    const handleSubcategoryChange = (e) => {
        if(selectedSubCategorySpeciality?.[0] === 'all') {
            setselectedSubCategorySpeciality([...e.target.value]?.filter(el => el!=='all'))
        }
        else{
            setselectedSubCategorySpeciality(e.target.value)
        }
    }

    // For Speciality Setting 
    const handleCategoryChange = (value) => {
        if(selectedSubCategorySpeciality?.[0] === 'all') {
            setspecialitiesSelectedCategories([...value]?.filter(el => el!=='all'))
        }
        else{
            setspecialitiesSelectedCategories(value)
        }
    }

    useEffect(() => {
        setsettingsData2(ps => ({
            ...ps,
            [selectedCategory]: {
                ...ps?.[selectedCategory],
                subCategories: [...selectedSubCategorySpeciality]
            }
          }))
    }, [selectedSubCategorySpeciality])
  
    const fetchCategoryList = async () => {
      const resp = await GetAllCategoriesOptimized();
      if(resp.status === 200){
          setCategoryList(Convert2dTo1dArray(Object.values(resp.data)));
      }
    }

    const fetchSubCategories = () => {
        const allCategories = ccontext?.AllCategories
        const categoriesData = [...allCategories?.data, ...allCategories?.WebIt, ...allCategories?.Business]
        const categoryObj = categoriesData?.find(el => el.category_id ===selectedCategory)
        setsubCategoriesArray([...categoryObj?.SubCategories || []])
    }

    const updateSettings = async () => {
        const settingsData1 = {
            [selectedCategory]: {
                ...orgsettingsData?.setting1?.[selectedCategory],
                [selectedSubCategory]: {...settingsData}
            }
        }
        const body = {
            settingName: SettingNames.CREATE_SERVICES_SETTING,
            setting1 : {...orgsettingsData?.setting1 || {}, ...settingsData1},
            setting2: {...orgsettingsData?.setting2 || {}, ...settingsData2}
        }
        const resp = await UpdateSettings(body, SettingNames.CREATE_SERVICES_SETTING)
        if(resp.status === 200){
            toast.success('Settings Saved Successfully')
        }
    }

    useEffect(() => {
      fetchCategoryList()
      if(selectedCategory && selectedSubCategory){
        console.log(selectedCategory)
        console.log(selectedSubCategory)
            let setting1 = {
                    Retainership: {
                        limits: {
                            enabled: false,
                            options: []
                        },
                        inclusions: {
                            enabled: false,
                            options: []
                        },
                        offerings: {
                            enabled: true,
                            options: []
                        }
                    },
                    Services: {
                        limits: {
                            enabled: false,
                            options: []
                        },
                        inclusions: {
                            enabled: false,
                            options: []
                        }, 
                        offerings: {
                            enabled: true,
                            options: []
                        }
                    }
                }

            let setting2 = {
                [selectedCategory]: {
                    subCategories: [],
                    enabled: false
                }
            }

            console.log(orgsettingsData)

            // Settings for limits & inclusions
            // The setting is specialization specific
            if(orgsettingsData?.setting1?.[selectedCategory]?.[selectedSubCategory]){
                setting1 = { ...orgsettingsData?.setting1?.[selectedCategory]?.[selectedSubCategory] || {}}    
                console.log("Setting1..", setting1)            
                } // endif

            if(orgsettingsData?.setting2?.[selectedCategory]){
                // This setting is for specialities
                setting2 = {
                    [selectedCategory]: {
                        ...orgsettingsData?.setting2?.[selectedCategory]
                    }
                }
            } // end-if

            setsettingsData({...setting1})
            setsettingsData2({...setting2})
            setselectedSubCategorySpeciality([...orgsettingsData?.setting2?.[selectedCategory]?.subCategories || []])
        }
    }, [selectedCategory, selectedSubCategory])

    useEffect(() => {
        if(ccontext.AllCategories){
            fetchSubCategories()
        }
    }, [ccontext.AllCategories, selectedCategory])
    


    // Handlers to update settings
    const updateEnableOption = (serviceType, field, value) => {
        setsettingsData( ps => ({
            ...ps,
            [serviceType]: {
                ...ps?.[serviceType],
                [field]: {
                    ...ps?.[serviceType]?.[field],
                    enabled: value
                }
            }
        }))
    }

    // Handler to add options
    const addOptions = (serviceType, field, value) => {
        const tempArr = [...settingsData?.[selectedCategory]?.[serviceType]?.[field]?.options || []]?.map(el => el?.toLowerCase())
        console.log(settingsData)
        if(tempArr?.includes(value?.toLowerCase())){
            toast.error('Option Already Added')
            return
        }
        setsettingsData( ps => ({
            ...ps,
            
            [serviceType]: {
                ...ps?.[serviceType],
                [field]: {
                    ...ps?.[serviceType]?.[field],
                    options: [
                        ...ps?.[serviceType]?.[field]?.options || [],
                        value
                    ]
                }
            }
        }))
    }


    useEffect(()=> {
        console.log(settingsData)
    }, [settingsData])

    // Handler to Delete options
    // Only for Settings1 (Inclusions, Limits)
    const deleteOptionFromList = (serviceType, field, indexToRemove) => {
        const tempArr = [...settingsData?.[serviceType]?.[field]?.options || []]
        tempArr.splice(indexToRemove, 1)
        setsettingsData( ps => ({
            ...ps,
            [serviceType]: {
                ...ps?.[serviceType],
                [field]: {
                    ...ps?.[serviceType]?.[field],
                    options: [ ...tempArr ]
                }
            }
        }))
    }

    // Handler to Update options
    const updateOptionInList = (serviceType, field, indexToEdit, value) => {
        let tempArr = [...settingsData?.[serviceType]?.[field]?.options || []]
        if(indexToEdit >= tempArr.length){
            return
        }
        tempArr[indexToEdit] = value
        setsettingsData( ps => ({
            ...ps,
            [serviceType]: {
                ...ps?.[serviceType],
                [field]: {
                    ...ps?.[serviceType]?.[field],
                    options: [ ...tempArr ]
                }
            }
        }))
    }

    // Update option for enable/disable for speciality
    const updateSpecEnableOption = (e) => {
        setsettingsData2(ps => ({
            ...ps,
            [selectedCategory]: {
                ...ps?.[selectedCategory],
                enabled: !ps?.[selectedCategory]?.enabled
            }
        }))
    }

    const selectedSettingData = settingsData
    
  return (
    <div>
        <Typography variant='h4' className='fw-bold'>Create Services Settings</Typography>

        {/* Dropdown to select Category */}
        <div className="form-group my-5">
            <label className='mb-1'>Select Category</label><br/>
            <Select fullWidth variant='outlined' defaultValue="" value={selectedCategory || ""} name="category" placeholder="Select category" onChange={handleChange}>
                <MenuItem value="" disabled>None</MenuItem>
                {
                    CategoryList?.map((cat) =>
                    <MenuItem value={cat?.category_id}>{cat?.categoryName}</MenuItem>
                )}
            </Select>
        </div>

        {/* ===== Dropdown to select Sub-Categories ==== */}
        { subCategoriesArray?.length > 0 &&
        <div className="form-group my-5">
            <label className='mb-1'>Select Specialization</label><br/>
            <Select fullWidth 
                variant='outlined' defaultValue="" 
                value={selectedSubCategory || ""} 
                name="category" 
                placeholder="Select Sub-category" 
                onChange={(e)=> setselectedSubCategory(e.target.value)}>
                <MenuItem value="" disabled>None</MenuItem>
                {
                    subCategoriesArray?.map((subcat) =>
                    <MenuItem value={subcat?.category_id}>{subcat?.categoryName}</MenuItem>
                )}
            </Select>
        </div>
        }


        {/* Create Services options */}
        { (selectedCategory && selectedCategory !=='' && selectedSubCategory !== '' && selectedSubCategory)
        ? <Fragment>
        {
            servicesTypeArr?.map((service) =>
                <div className='card'>
                    <div className='card-header'>
                        <Typography variant='p' className='fs-4 fw-bold'>{service}</Typography>
                    </div>
                    <div className='card-body'>
                        <div className='d-flex'>
                        <Typography variant='p' className='text-primary'>Enable Fields: </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedSettingData?.[service]?.limits?.enabled || false}
                                    onChange={() => updateEnableOption(service, 'limits', !settingsData?.[service]?.limits?.enabled)} 
                                    color="primary"
                                    name={`Limits`}
                                />
                            }
                            label="Limits"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedSettingData?.[service]?.inclusions?.enabled || false}
                                    onChange={() => updateEnableOption(service, 'inclusions', !settingsData?.[service]?.inclusions?.enabled)} 
                                    color="secondary"
                                    name={`Inclusions`}
                                />
                            }
                            label="Inclusions"
                        />
                        </div>
                        <Divider/>

                        {/* List of Options */}
                        <Grid container spacing={3}>
                            <Grid item md={6} sm={12}>
                                <Typography variant="h6" color="primary">Limits</Typography>
                                <ListBlock
                                    editItem={(index, val) => updateOptionInList(service, 'limits', index, val)} 
                                    deleteItem={(index)=> deleteOptionFromList(service, 'limits', index)} 
                                    listArray={selectedSettingData?.[service]?.limits?.options || []}/>
                                {/* Form to add options */}
                                <AddOptionForm addNewOptionHandler={(value) => addOptions(service, 'limits', value)} />
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Typography variant="h6" color="primary">Inclusions</Typography>
                                <ListBlock
                                    editItem={(index, val) => updateOptionInList(service, 'inclusions', index, val)} 
                                    deleteItem={(index)=> deleteOptionFromList(service, 'inclusions', index)} 
                                    listArray={selectedSettingData?.[service]?.inclusions?.options || []}/>
                                <AddOptionForm addNewOptionHandler={(value) => addOptions(service, 'inclusions', value)} />
                            </Grid>


                            <Grid item md={6} sm={12}>
                                <Typography variant="h6" color="primary">Service Offerings</Typography>
                                <ListBlock
                                    editItem={(index, val) => updateOptionInList(service, 'offerings', index, val)} 
                                    deleteItem={(index)=> deleteOptionFromList(service, 'offerings', index)} 
                                    listArray={selectedSettingData?.[service]?.offerings?.options || []}/>
                                <AddOptionForm addNewOptionHandler={(value) => addOptions(service, 'offerings', value)} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }

        {/* Specialities Column */}
        <div className='card'>
            <div className='card-header'>
                <Typography variant='p' className='fw-bold fs-4'>Specialities Settings</Typography>
            </div>
            <div className='card-body'>
                {/* Drop down to select Categories */}
                <DropdownMultiSelect 
                    value={specialitiesSelectedCategories} 
                    options={CategoryList?.map(el => el?.categoryName)} 
                    onchange={handleCategoryChange}/>
                 
                 {/* Dropdown to select Category */}
                <div className="form-group mt-1">
                    <label className='mb-1'>Select Sub-Category</label><br/>
                    <Select multiple 
                        fullWidth variant='outlined' 
                        value={selectedSubCategorySpeciality || []} 
                        name="subCategory" 
                        placeholder="Select Sub-category"
                        input={<Input />}
                        renderValue={(selected) => selected?.map(el => subCategoriesArray?.find(elem => elem?.category_id === el)?.categoryName).join(', ')} 
                        onChange={handleSubcategoryChange}>
                        <MenuItem onClick={()=>setselectedSubCategorySpeciality(['all'])} value="all">
                                <Checkbox checked={selectedSubCategorySpeciality?.[0] === 'all'} />
                                <ListItemText primary={'All'} />
                        </MenuItem>
                        {
                            subCategoriesArray?.map((cat, i) =>
                            <MenuItem key={i} value={cat?.category_id}>
                                <Checkbox checked={selectedSubCategorySpeciality?.[0] === 'all' || selectedSubCategorySpeciality.indexOf(cat?.category_id) > -1} />
                                <ListItemText primary={cat?.categoryName} />
                            </MenuItem>
                        )}
                    </Select>

                    <p className='mt-2 text-default'>
                        Specialities will be active for above selected sub-categories. <br/>
                        It will be active for Consultation, Retainership and Service
                    </p>
                    <br/>
                    <FormControlLabel
                            control={
                                <Switch
                                    checked={settingsData2?.[selectedCategory]?.enabled || false}
                                    onChange={updateSpecEnableOption} 
                                    color="secondary"
                                    name={`Enable Specialities`}
                                />
                            }
                            label="Enable Specialities"
                        />
                </div>
            </div>
        </div>
        <div className='w-100 text-center mb-5'>
            <Button variant='contained' color='success' onClick={updateSettings}>Save Changes</Button>
        </div>
        </Fragment>
        : <div className='text-center w-100'>
            <Typography className='text-secondary mt-5' variant='h6'>Select Category To Continue</Typography>
        </div>
        }
    </div>
  )
}
