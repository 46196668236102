import React, { useEffect } from "react";
// import config from 'config';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// import Categories from "./client/components/category";
//provider
//customer
import 'react-toastify/dist/ReactToastify.css';
//pages
// import MainPage from "./client/components/ServiceProviderModals/MainPage"
import AppUniversal from "./admin/app-universal";
// import TypesOfServices from "./client/components/TypesOfServices/index.js";
// import PortfolioProfiles from "../src/client/components/PortfolioProfiles/PortfolioProfiles" ;
import { ToastContainer } from "react-toastify";
import AdminState from "./admin/AdminContext/AdminState.jsx";
// import PageWrapper from "./GlobalComps/PageWrapper.jsx";
// import ChatApp from "./client/components/chat/index.jsx";

const AppContainer = function (props) {
  const pathname = props.location.pathname;

  useEffect( ()=>{
    // const url = props.location.pathname.split("/")[1];
    window.scrollTo(0, 0)
    // console.log("scrolllll");
 }, [pathname] );

  if (props) {
    const url = props.location.pathname.split("/")[1];
    
    return (
      <Router>
          <div>
            <AdminState>
              <Switch>
                <Route path="/admin" component={AppUniversal} />
              </Switch>
            </AdminState>
          </div>
        <ToastContainer position="top-right" draggable theme="colored" autoClose={3000}/>
      </Router>
    );
  }
  return null;
};

export default AppContainer;
