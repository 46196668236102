import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetPostById(id) {
    const response = await axiosInstanceWithSecret.get(`/blog/article-detail/${id}`);
    return response;
}

export async function GetAllPosts() {
    const response = await axiosInstanceWithSecret.get(`/blog/articles/`);
    return response;
}

export async function AddNewPost(body) {
    const response = await axiosInstanceWithSecret.post(`/blog/articles/`, body);
    return response;
}

export async function UpdatePost(id, body) {
    const response = await axiosInstanceWithSecret.put(`/blog/article-detail/${id}`, body);
    return response;
}

export async function DeletePost(id) {
    const response = await axiosInstanceWithSecret.delete(`/blog/article-detail/${id}`);
    return response;
}

export async function AddNewImage(file, altText) {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("alt", altText);


    const response = await axiosInstanceWithSecret.post(`/blog/article-image-upload/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response;
}