import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const Editor = ({ placeholder, content, setContent }) => {
    const editor = useRef(null);

    const config = useMemo(() => ({
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: placeholder || 'Start typing...'
    }), [placeholder]);

    return (
        <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { 
                setContent(newContent)
             }}
        />
    );
};

export default Editor;