import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function UpdateSettings(body, settingName){
    const response = await axiosInstanceWithSecret.put(`/upsert-setting`, body, {
        params:{
            'setting_name': settingName
        }
    });
    return response;
 }

 export async function GetSettings(){
    const response = await axiosInstanceWithSecret.get(`/get-general-setting`, {});
    return response;
 }