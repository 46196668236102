import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import React from 'react'
import PreviewFile from '../../../../GlobalComps/PreviewFile'

export default function DocumentPreviewWrapper({ fileData, children, handleRemove }) {
  return (
    <div className='w-full'>
        <Grid container>
            <Grid item md={10}>
                { children }
            </Grid>
            <Grid item md={2}>
                {
                    Array.isArray(fileData) ? (
                          fileData?.map(file => 
                              <React.Fragment key={file.name}>
                                  <PreviewFile fileData={file} />
                              </React.Fragment>
                          )
                      )
                          : ((typeof fileData === 'string') && !fileData?.includes('object File')) ? <PreviewFile fileData={fileData} /> : null
                }
                <span role={'presentation'}  style={{cursor:'pointer',marginTop:'3.7rem'}} onClick={handleRemove} >
                    <FontAwesomeIcon icon={faTrashAlt} className='text-danger'/>
                </span>
            </Grid>
        </Grid>
    </div>
  )
}
