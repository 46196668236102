import React, { useEffect, useState } from 'react'
import SidebarNav from '../../sidebar';
import Editor from '../../Blog/Posts/Editor';
import { AddNewFaq } from '../../../../API/FaqAPI';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
const AddFaq = () => {
const [content, setContent] = useState('');
const { categoryid } = useParams();
const history = useHistory();

const handleSave = () => {

   let body = {
    category: categoryid,
    content: content
   }
  AddNewFaq(body).then((response) => {
    if (response.status === 201) {
      toast.success("Faq saved successfully");
      history.push(`/admin/edit-categories/${categoryid}`)
    }

  }).catch(error => {
    console.error("Error saving article:", error);
  });
}



  return (
    <div>
    <SidebarNav />
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="page-header">
                <div className="row">
                    <div className="col">
                        <h3 className="page-title">Add FAQ</h3>
                    </div>
                    <div className="row my-2">
                        <p htmlFor="title" className="mr-2 font-weight-bold" style={{marginLeft:'2rem'}}> Please provide the FAQ and Answers, Question must be in  H3 tag</p>
                        <Editor setContent={setContent} content={content} />
                    </div>

                    <div className="col">
                        <button className ="btn btn-primary" onClick={()=>handleSave()}>Save</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddFaq
