import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Collapse, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { deliveryTimeArr } from "../../../../GlobalComps/Constant";
import DropdownSelect from "../../../../GlobalComps/DropdownSelect";
import { removeItemFromArray } from "../../../../GlobalComps/Helper";
import styles from "./../CompleteProfile.module.css";
import React from "react";
import DropdownGroupMultiselect from "../../../../GlobalComps/DropdownGroupMultiselect";
// import DropdownMultiSelect from "../../../../GlobalComps/DropdownMultiSelect";
import DropdownGroupMultiselectNew from "../../../../GlobalComps/DropdownGroupMultiselectNew";
import { Table } from "react-bootstrap";

function AddRetainership (props) {
    const [State, setState] = useState({
		Categories: [],
		process: false,
		serviceTypes: ['Retainership','Service', 'AddOnService', 'Retainership'],
		UserData:{},
		ServiceImgDisplay:'',
		FormData:[],
		RetainershipOfferings:[
			...props.offeringSetting
		],
     });

	 console.log("props",props)


  const formChange = (name, val, index) => {
	let FormDataArr = State.FormData;
	FormDataArr[index] = {...FormDataArr[index], [name] : val}
	// setState({ FormData: [...FormDataArr || []] });
	props.updateState([...FormDataArr || []])
  }


  const formChangeNewListingSameOffering = (name, val, index, subindex) => {
	console.log("name - ", name, "value - ", val, "index - ", index )
	let FormDataArr = State.FormData;

	console.log(FormDataArr, "FormDataArr")

	console.log(FormDataArr[index], "FormDataArr[index]")
	console.log(FormDataArr[index].limitsPriceVariation, "FormDataArr[index].limitsPriceVariation")
	
	FormDataArr[index].limitsPriceVariation[subindex] = {...FormDataArr[index].limitsPriceVariation[subindex], [name]:val}

	setState({ FormData: [...FormDataArr || []] });
	console.log(State.FormData, "FormDataArrFormdata")

	}

  const addNewRetainershipRecord = () => {
	if(State.FormData.length > 10){
		Swal.fire({
			title:'Max 10 Retainership are Allowed',
			icon:'error'
		});
		return;
	}
	setState(prevState => ({
		...prevState,
		FormData: [...prevState.FormData,{ offerings: '', indiaPrice: '', specialty: [], limits: [], inclusions: [] }]
	}));
	props.updateState([...State.FormData, { offerings: '', indiaPrice: '', specialty: [], limits: [], inclusions: [] }])

  }


  const addNewListingSameOffering = (index) => {
	// if (State.FormData.length === 1){
	// 	setSameOfferingListing(true)
	// }
	
	const currentOffering = State.FormData[index-1]
	// console.log(currentOffering, "currentOffering")

	currentOffering.limitsPriceVariation = [...currentOffering.limitsPriceVariation || [], {indiaPrice: '', limits: [], deliveryTime: "", description: ""}]


	// const newCurrentOffering = {...currentOffering, limitsPriceVariation: [...currentOffering.limitsPriceVariation || [], {indiaPrice: '', limits: [], deliveryTime: "", description: ""}]}
	// console.log(newCurrentOffering, "currentOffering")
	
	setState(prevState => ({
		
		...prevState,
		FormData: [...prevState.FormData]
	}));

	
	// props.updateState([...State.FormData, { offerings: '' }])

}

const removeNewListingSameOffering =(index)=>{
	const currentOffering = State.FormData.slice(index)[0]
	currentOffering.limitsPriceVariation.pop()
	setState(prevState => ({
		
		...prevState,
		FormData: [...prevState.FormData]
	}));

}

  const removeServiceRecord = (index) => {
	let formData = State.FormData;
	let newFormData = removeItemFromArray(formData, index, 'index');
	setState(prevState => ({
		...prevState,
		FormData: [...newFormData]
	}));
	props.updateState([...newFormData])
  }


  useEffect(() => {
    setState( ps => ({ ...ps, RetainershipOfferings: [...props.offeringSetting] } ));
  }, [props.offeringSetting])

  useEffect(() => {
    try{
		const staticInitData = [
			{offerings:''},
			{offerings:''},
			{offerings:''},
			{offerings:''},
			{offerings:''}];
		const dataArr = [...props?.data || staticInitData];
		setState({ 
			FormData: [...dataArr ], 
			RetainershipOfferings: [...props.offeringSetting],
			RetainershipSpecialitiesList: [...props?.specialitiesSettings || []],
			SpecializationBasedOfferingsList: {...props?.servicesSetting?.offerings?.options || {}}  
			// RetainershipInclusionsList: [...props?.servicesSetting?.inclusions?.options || []],
			// RetainershipLimitsList: [...props?.servicesSetting?.limits?.options || []]  
		});
		}
		catch(err){
			console.log(err);
		}
  }, [props])

	const isSpecialtyEnabled = props?.specialtySetting?.enabled || false;
	const isInclusionsEnabled = props?.servicesSetting?.inclusions?.enabled || false;
	const isLimitsEnabled = props?.servicesSetting?.limits?.enabled || false;

  
    return (
         <>
		<div className="content">
				<div className="row justify-content-center">
					<div className="col-span-12">
						<div className="section-header text-center">
							<h2 className="uppercase text-xl font-bold my-4 md:my-8">Add Retainership/Package Options</h2>
						</div>
						{
							State.process && <div className="alert alert-info">Proccessing Please Wait..</div>
						}
							<div className="service-fields mb-3">
								{/* Table */}
								<Table responsive >
									<thead className="bg-gray-300">
										<tr>
											<th className="font-semibold text-base">Offerings</th>
											{ isSpecialtyEnabled && <th className="font-semibold text-base">Specialities</th> }
                                            { isInclusionsEnabled && <th className="font-semibold text-base">Inclusions</th> }
                                            { isLimitsEnabled && <th className="font-semibold text-base">Limits</th> }
											<th className="font-semibold text-base">Details</th>
											<th className="font-semibold text-base">Delivery Time</th>
											<th className="font-semibold text-base">Charges (INR)</th>
										</tr>
									</thead>
									<tbody>
									{
										State?.FormData?.map((a, index) => 

										<>
											<tr>
												{(index !== 0) && (
													<td colSpan="3" >
														<Button className="text-xs" onClick={()=>addNewListingSameOffering(index)}><FontAwesomeIcon icon={faPlusCircle} className="me-2"/> Add Limit - Price Variation for the current Offering</Button>
													</td>

												)}
											</tr>
											<tr key={index}>
												{console.log("state",State)}
												<td className={styles.tableCell}>
												{  ((index!==State?.FormData?.length-1) || props.mode === 'edit') &&
													<DropdownSelect 
													options={props?.servicesSetting?.offerings?.options}
														label=" Offerings"
														selectControlProps={{value:a?.offerings || ''}}
														// required={index===0?true:false}
														name="offerings"  
														value={a?.offerings || ''} 
														onchange={ (e) => {formChange('offerings', e.target.value, index);} }
													/>
												}
												{
														((index===State.FormData?.length-1) && props.mode !== 'edit') && 
														<TextField  data-index={index} 
															type="text"
															fullWidth={true}
															size="small"
															variant="outlined"
															defaultValue={a?.offerings}
															placeholder='Create Your Own option'
															onChange={ (e) => {formChange('offerings', e.target.value, index) } }
														/>
													}
												</td>
												{/* ======= Specialities Field ======= */}
												 
													{   isSpecialtyEnabled &&
													<td className={` ${styles.tableCell}`}>
													{ ((index!==State?.FormData?.length) || props.mode === 'edit')
														&& ( 
														<DropdownGroupMultiselect
															options={props?.specialitiesObject}
															selectControlProps={{value:a?.specialty || [] }}
															
															// required={index===0?true:false}
															name="specialty"
															width={'8rem'}
															label={'Specialities'}
															value={a?.specialty || []} 
															onchange={ (val) => {formChange('specialty', val, index);} }
														/>
														)
													}
													</td>
												}

												{/* ======= Inclusions Field ====== */}
												{ isInclusionsEnabled &&
													<td className={styles.tableCell}>
													{ ((index!==State?.FormData?.length) || props.mode === 'edit')
														&& ( 
														<DropdownGroupMultiselectNew 
															// options={State.RetainershipInclusionsList}
															options={props?.servicesSetting?.inclusions?.options}
															// required={index===0?true:false}
															name="inclusions"
															width={'10rem'} 
															label={'Inclusions'}
															value={a?.inclusions || []} 
															onchange={ (val) => {formChange('inclusions', val, index);} }
														/>
														)
													}
													</td>
												}

												{/* ======= Limits Field ====== */}
												{ isLimitsEnabled &&
													<td className={styles.tableCell}>
													{ ((index!==State?.FormData?.length) || props.mode === 'edit')
														&& ( 
														<DropdownGroupMultiselectNew 
															// options={State.RetainershipLimitsList} 
															options={props?.servicesSetting?.limits?.options}
															// required={index===0?true:false}
															width={'10rem'}
															name="limits" 
															label={'Limits'}
															value={a?.limits || []} 
															onchange={ (val) => {formChange('limits', val, index);} }
														/>
														)
													}
													</td>
												}

												<td className={styles.tableCell}>
													<TextField data-index={index} 
														name="description" 
														value={a?.description || ''}
														onChange={ (e) => {formChange('description', e.target.value, index) } }
														// required
														multiline
														size="small"
														rows={2}
														placeholder="Enter Details" 
														className="form-control" 
														type="textarea" />
												</td>
												<td className={styles.tableCell}>
												<DropdownSelect 
													options={deliveryTimeArr}
													selectControlProps={{value:a?.deliveryTime || ''}}
													label=" Time"
													name="deliveryTime" 
													value={a?.deliveryTime || ''}
													onchange={ (e) => {formChange('deliveryTime', e.target.value, index) } }
												/>
												</td>
												<td className={styles.tableCell}>
													<TextField data-index={index} 
														value={a?.indiaPrice || ''}
														onChange={ (e) => {formChange('indiaPrice', e.target.value, index) } }
														size="small"
														label="Charges"
														className="form-control"
														placeholder="INR (₹)" 
														type="number" />
												</td>
												<td>
                                                    <span role={'presentation'} data-index={index} style={{cursor:'pointer'}} onClick={()=>removeServiceRecord(index)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} className='text-danger'/>
                                                    </span>
												</td>
											</tr>

											{a.limitsPriceVariation?.map((obj, subindex)=>

											<tr key={subindex} >
												<td  colSpan={5}>
													<Collapse in={true} timeout="auto" unmountOnExit>
														<Box sx={{ margin: 1 }}>

															<Table size="small" aria-label="pricing-options">
														
																<tbody>
																	<tr >
																		<td className={styles.tableCell} >
																			
																					<>
																						{/* <LightTooltip title={a?.limits ? "" : <span dangerouslySetInnerHTML={{ __html: Limits }} />} open={hoveredIndex4 === index} arrow TransitionComponent={Zoom} placement="right-end">
																						<div onMouseEnter={() => handleMouseEnter4(index)} onMouseLeave={handleMouseLeave4}> */}
																						{/* {console.log("initial option", props?.servicesSetting?.limits?.options)} */}
																						<DropdownGroupMultiselectNew
																							// options={removeDuplicate(State.ServiceLimitsList)}
																							options={props?.servicesSetting?.limits?.options}
																							selectControlProps={{ value: obj?.limits || '' }}
																							// required={index===0?true:false}
																							name="limits"
																							width={'10rem'}
																							label={'Select Limits'}
																							value={obj?.limits || ''}
																							onchange={(val) => { formChangeNewListingSameOffering('limits', val, index, subindex); }}
																							size="small"
																							
																							
																						/>
																						{/* // 	</div>
																					// </LightTooltip> */}
																					</>

																			
																		</td>

																		<td className={styles.tableCell}>
																			{/* <LightTooltip title={a?.offerings ? "" : <span dangerouslySetInnerHTML={{ __html: ConsultationCharges }} />} arrow placement='top-start' > */}
																			<TextField data-index={index}
																				label={<span className="text-xs">Charges</span>}
																				required={!!obj?.offerings}
																				value={obj?.indiaPrice || ''}
																				onChange={(e) => { formChangeNewListingSameOffering('indiaPrice', Number(e.target.value), index, subindex) }}
																				onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
																				className="form-control "
																				placeholder="INR (₹)"
																				type="number" 
																				size="small"
																				style={{ minWidth: "80px" }}
																			
																				/>
																		</td>

																		<td className={styles.tableCell}>
																			{/* <LightTooltip title={a?.description ? "" : <span dangerouslySetInnerHTML={{ __html: ServiceDescription }} />} arrow TransitionComponent={Zoom} placement="left-start" > */}
																			<TextField data-index={index}
																				style={{ minWidth: "300px" }}
																				name="description"
																				multiline
																				rows={2}
																				inputProps={{
																					style: {
																						height: "23px"
																					},
																				}}
																				// required={!!a?.offerings}
																				onChange={(e) => { formChangeNewListingSameOffering('description', e.target.value, index, subindex) }}
																				size="small"
																				defaultValue={obj?.description || ''}
																				label={<span className="text-xs">Additional Details</span>}
																				// placeholder= {<span className="text-xs">Additional Details</span>}
																				className="form-control"
																				type="text" />
																			{/* </LightTooltip> */}
																			{/* <div>
																			{errorMessage && errorMessage.errorIndex === index && <span style={{ color: 'red' }}>{errorMessage.error}</span>}
																			</div> */}
																		</td>

																		<td className={styles.tableCell}>
																			{/* <LightTooltip title={a?.deliveryTime ? "" : <span dangerouslySetInnerHTML={{ __html: consultationDelevery }} />} open={hoveredIndex1 === index} arrow TransitionComponent={Zoom} placement="top-end">
																			<div onMouseEnter={() => handleMouseEnter1(index)} onMouseLeave={handleMouseLeave1}> */}
																			<DropdownSelect
																				options={deliveryTimeArr}
																				selectControlProps={{ value: obj?.deliveryTime || '' }}
																				label={<span className="text-xs">Select</span>} 
																				name="deliveryTime"
																				// required={true}
																				required={!!obj?.deliveryTime}
																				value={obj?.deliveryTime || ''}
																				onchange={(e) => { formChangeNewListingSameOffering('deliveryTime', e.target.value, index, subindex) }}
																			/>
																			{/* </div>
																			</LightTooltip> */}
																		</td>

																		<td className={styles.tableCell}>
																			<FontAwesomeIcon onClick={()=>removeNewListingSameOffering(index)} icon={faTrashAlt} className='text-danger'/>
																			
																		</td>
																	
																	</tr>
																</tbody>

															</Table>

														</Box>


													</Collapse>
												</td>

											</tr>

											)}

										</>
										)
									}
									<tr>

										<td colSpan={3} className="p-3">
											<Button className="text-xs" onClick={()=>addNewListingSameOffering(State?.FormData?.length)}> <FontAwesomeIcon icon={faPlusCircle} className="me-2" />Add Limit - Price Variation for the current Offering</Button>
											
										</td>
										
										<td colSpan={5} style={{textAlign:'right'}}>
											<Button type="button" variant="outlined" onClick={addNewRetainershipRecord}>+ Add Another Option</Button>
										</td>
									</tr>
									</tbody>
								</Table>

								{/* Table Ends */}
							</div>
							
							{/* <div className="submit-section">
								<button className="btn btn-primary submit-btn" type="submit">Submit</button>
							</div> */}
						{/* </form> */}
					</div>
				</div>
		</div>       
        
          </>
    );
  }
export default AddRetainership;