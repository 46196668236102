import * as React from 'react';
import { Box, Button, Typography, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { Done, Clear } from '@mui/icons-material';
import PreviewFile from '../../../GlobalComps/PreviewFile';
import UserAgreementModal from './UserAgreementModal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height:600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const DetailsModal = ({open, setOpen, modalDisplayData, setModalDisplayData}) => {
  console.log(modalDisplayData, "modalDisplayData")
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setModalDisplayData("")
    }

function stringCheckIfFile (str){
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|pdf|docx|pptx)$/i;
    if(str.match(imageExtensions) == null){
        return false;
    }
    else {
        return true;
    }
}
  return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
            <Box sx={style} >
                <Typography id="modal-modal-title" variant="h6" component="h2" className='pb-3 font-weight-bold'>
               {modalDisplayData.title}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* {modalDisplayData?.confidence} <br/> */}

                    <TableContainer component={Paper}>
                    <div style={{ height: "500px", overflow: "auto" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                            {
                                Object.keys(modalDisplayData).map(key=>
                                    <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                       {key}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                    {Array.isArray(modalDisplayData[key]) ? (
                                                // If it's an array, render each element
                                                key === "accepted_agreements" ? <UserAgreementModal data={modalDisplayData[key]} />
                                                    :
                                        modalDisplayData[key].map((item, index) => (
                                            <div key={index}>{JSON.stringify(item)}</div>
                                        ))

                                        // ""
                                        ) : typeof modalDisplayData[key] === 'object' ? (
                                        // If it's an object, render its properties
                                            JSON.stringify(modalDisplayData[key])
                                        // ""
                                        ) : typeof modalDisplayData[key] === 'boolean' ? (
                                            <p>{modalDisplayData[key] ? <Done/> : <Clear/>}</p>

                                        ) : typeof  modalDisplayData[key] === "string" ? (
                                            stringCheckIfFile( modalDisplayData[key]) ? (
                                                <PreviewFile fileData={modalDisplayData[key]} />
                                            )
                                            : 
                                            modalDisplayData[key]
                                          
                                        )
                                        // :  modalDisplayData[key].endsWith("jpg") ||  modalDisplayData[key].endsWith("jepg") ? (
                                        //     <PreviewFile fileData={modalDisplayData[key]} />
                                        // )
                                        : 
                                        (
                                            modalDisplayData[key]
                                        )
                                        }
                                    </TableCell>
                                    {/* <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                    <TableCell align="right">{row.protein}</TableCell> */}
                                    </TableRow>

                                )


                                

                            }

                                  

                            
                                
                          
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </Typography>
            </Box>
            </Modal>
        </div>
  )
}

export default DetailsModal