import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DeleteConcellation, UpdateCancellation, addCancellationReason, getAllCancellationReasons } from '../../../../../API/CancellationAPI';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const UserCan = () => {
    const [userReasons, setUserReasons] = useState([])

    useEffect(()=>{
        const  fetchAll = async()=>{
            try {
                const response = await getAllCancellationReasons("USER");
                setUserReasons(response.data.results);
            } catch (error) {
                console.error('There was an error fetching the cancellation reasons!', error);
            }
        }
       
        fetchAll()

    }, [])


  const handleChange = (e, index) => {

    const { name, value } = e.target;
    const updatedReasons = [...userReasons];
    updatedReasons[index][name] = value;
    if (updatedReasons[index]?.reasons_id){
        updatedReasons[index].id = updatedReasons[index]?.reasons_id
    }
   
    updatedReasons[index].reasons_id = ""
    setUserReasons(updatedReasons);
};

const addMore = () => {
    setUserReasons([...userReasons, { status: "", reason: "", repercussion: "" }]);
};


useEffect(()=>{
    console.log(userReasons, "line4958")
}, [userReasons])


const handleDelete = (index) => {

    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to proceed with this action?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'No, cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            // Perform the action after confirmation
            performAction(index);
        } 

    });

    const performAction = async (index) =>{
        // console.log("delete function called")
        // console.log(reasons[index].reasons_id, "line5454")
        setUserReasons(prevReasons => prevReasons.filter((_, i) => i !== index));
        let id = userReasons[index].reasons_id
        const resp = await DeleteConcellation(id)
        // console.log(resp.status, "line585")
        if (resp.status===204){
           
            Swal.fire({
                title: 'Deleted!',
                text: 'Your item has been deleted.',
                icon: 'success'
            });
        }
    
    }

};

const handleSave = async (reason, index) => {
  console.log(reason, "line 935u35")

    try{
      let currentReason = { ...reason, userType: "USER" };
            let resp;
            // Check if the ID exists in providerReasons
            // const reasonExists = userReasons.some(item => item.id === currentReason.id);


            if (reason.id) {
                // If the ID exists, update the cancellation reason
                resp = await UpdateCancellation(currentReason.id, currentReason);

            } else {
                // If the ID does not exist, add a new cancellation reason
                resp = await addCancellationReason(currentReason);
            }


      if (resp.status === 201 || resp.status === 200) {
          const updatedUserReasons = [...userReasons]; // Create a shallow copy of the array
          updatedUserReasons[index] = { ...updatedUserReasons[index], reasons_id: resp.data.reasons_id }; // Update the specific object
          setUserReasons(updatedUserReasons); 

          Swal.fire({
              title: 'Saved Successfully!',
              text: 'Your item has been saved successfully.',
              icon: 'success'
          });
      } 
     }catch (error) {
      if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          if (error.response.status === 400) {
              const key = Object.keys(error.response.data)[0]; 
              const value = error.response.data[key][0]; 
              toast.error(`Error: ${error.response.status} - You are missing a field? ${key} ${value}`);
          } 
      } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
          toast.error(`Error: ${error.message}`);
      }
    }
 


};

  return (
    
    <div className='card'>
    <div className='card-header'>
        <Typography variant='p' className='fs-4 fw-bold'>User</Typography>
    </div>
    <div className='card-body'>
            {userReasons.map((reason, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <div>
                        <p className='text-xl'>Cancellation Type</p>
                        <select name='status' value={reason.status} className='' onChange={(e) => handleChange(e, index)}>
                            <option value="" disabled>Select</option>
                            <option value="PRE">Pre-Acceptance</option>
                            <option value="POST">Post-Acceptance</option>
                        </select>
                    </div>
                    <div style={{ width: "18rem" }}>
                        <p>Cancellation Reasons</p>
                        <input name='reason' value={reason.reason} className="" style={{ width: "100%" }} onChange={(e) => handleChange(e, index)} />
                    </div>
                    <div style={{ width: "18rem" }}>
                        <p>Cancellation Repercussions</p>
                        <textarea name="repercussion" value={reason.repercussion} style={{ width: "100%" }} onChange={(e) => handleChange(e, index)} />
                    </div>
                    {reason.reasons_id ?  <button style={{backgroundColor : "green", color: "white"}}>Saved</button> 
                    :
                    <button onClick={()=>handleSave(reason, index)}>Save</button>
                    }
                   
                    <button onClick={()=>handleDelete(index)}  >Delete</button>
                </div>
            ))}
        <button onClick={addMore}>Add more +</button>
    </div>
    </div>  
  )
}

export default UserCan