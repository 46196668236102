import React, { useEffect, useState } from 'react'
import SidebarNav from "../../sidebar"
import Button from 'react-bootstrap/Button';
import { GetNotificationTypes, addNotificationTemplate } from '../../../../API/NotificationTemplate';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
const AddNotification = () => {

    const [notification, setNotification] = useState({
        user_type: "",
        notification_type: "",
        web_notification: "",
        whatsapp_template: "",
        email_template: ""
    });
    const [notificationTypes, setNotifcationTypes] = useState()
    const history = useHistory();

    const fetchtNotificationTypes = async () => {
        try {
            const response = await GetNotificationTypes();
            setNotifcationTypes(response.data)
        } catch (error) {
            console.error("Error fetching notification types:", error);
        }
    }
    useEffect(() => {
       fetchtNotificationTypes()
    }, [])

    const userTypes = ["User", "ServiceProvider"]
    const handleNotificationSave = async (e) => {
        e.preventDefault();
        try {
            const response = await addNotificationTemplate(notification);
            toast.success("Template added successfully");
            history.push(`/admin/notification`)
        } catch (error) {
            console.error("Error saving notification", error);
        }
    };
    const updateNotificationState = (event) => {
        setNotification({
            ...notification,
            [event.target.name]: event.target.value
        });
    }



  return (
    <div>
      <SidebarNav />
      <div className="page-wrapper">
                <div className="content container-fluid">
                    <form className='page-header' onSubmit={handleNotificationSave}>
                        <div className="row my-2">

                            <label >Choose a user type:</label>
                            <select name="user_type" onChange={updateNotificationState}>
                                <option value={notification?.user_type} defaultValue>--Please choose an option--</option>
                                {userTypes.map((type, index) => (
                                    <option key={index} value={type}>{type}</option>
                                ))}
                                </select>
                        </div>
                        <div className="row my-2">
                            <label >Choose a notification type:</label>
                            <select name="notification_type" onChange={updateNotificationState}>
                                <option  value={notification?.notification_type} defaultValue >--Please choose an option--</option>
                                {notificationTypes && notificationTypes.map((type, index) => (
                                     <option key={index} value={type}>{type}</option>
                                ))}
                                </select>
                        </div>
                        <div className="row my-2">
                            <label  htmlFor="webNotification" className="mr-2">Web Notification</label>
                            <input type="text" name="web_notification" id="webNotification" value={notification?.web_notification} placeholder="Web notification" onChange={updateNotificationState}/>
                        </div>
                        <div className="row my-2">
                            <label htmlFor="whatsappNotification" className="mr-2">Whatsapp Notification</label>
                            <textarea type="text" name="whatsapp_template" id='whatsappNotification' value={notification?.whatsapp_template}  rows="4" placeholder="Whatsapp notification" onChange={updateNotificationState}></textarea>

                            {/* <input type="text" name="whatsappNotification" value={notification?.author} placeholder="Author name" onChange={updateNotificationState}/> */}
                        </div>
                        <div className="row my-2">
                            <label htmlFor="emailNotification" className="mr-2">Email Notification</label>
                            <textarea type="text" name="email_template" id='emailNotification' value={notification?.email_template}  rows="4" placeholder="Email notification" onChange={updateNotificationState}></textarea>

                            {/* <input type="emailNotification" name="author" value={notification?.author} placeholder="Author name" onChange={updateNotificationState}/> */}
                        </div>

                        <div className="col my-2 align-between">
                            <Button variant="primary" type="submit" className="mr-2">Save</Button>
                        </div>
                    </form>

                </div>
            </div>
    </div>
  )
}

export default AddNotification
