import React, { useEffect, useState } from 'react'
import SidebarNav from "../../sidebar";
import { Link } from "react-router-dom";
import { GetAllPosts } from '../../../../API/BlogAPI';
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";


const PostLists = () => {

    const [fetchedPosts, setFetchedPosts] = useState([])
    const history = useHistory();

    useEffect(() => {
        GetAllPosts().then((response) => {
            setFetchedPosts(response.data)
        }).catch(error => {
            console.error("Error fetching posts:", error);
            setFetchedPosts([])
        })

    }, [])

    return (
        <>
            <SidebarNav />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className='page-header'>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Posts</h1>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <Button variant="primary" onClick={() => history.push("/admin/posts/post")}>Add New Post</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-center mb-0 datatable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Author</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {fetchedPosts &&
                                                fetchedPosts.map((post, index) => (
                                                    <tr key={`post-${post.id}`}>
                                                        <td>{index + 1}</td>
                                                        <td><Link to={`/admin/posts/post?post=${post.id}`}>{post.title}</Link></td>
                                                        <td>{post.status ? "Published" : "Draft"}</td>
                                                        <td>{post.created_at}</td>
                                                        <td>{post.author}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default PostLists