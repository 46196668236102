import React, { Component } from "react";
import SidebarNav from "../../sidebar";
import {Link} from "react-router-dom";
import { withRouter } from 'react-router'
import { AddNewCategory } from "../../../../API/CategoryAPI";

class AddCategories extends Component {

  constructor(props) {
	super(props)

	this.state={
		formData:{status:"Active", mostPopular: false}
	};
  }

  gotoAddPage() {
	const {history} = this.props;
	history.push('/admin/category')
  }

  handleMostPopularCheckbox = () => {
	this.setState(prevState => ({
		formData: {...prevState.formData, mostPopular: !prevState.mostPopular}
	}));
  }

  handleChange = (e) => {
	  this.setState({formData: {...this.state.formData, [e.target.name]:e.target.value}});
  }

  submitCategory = async (e) => {
	  e.preventDefault();
	const payload = {...this.state.formData};
	const resp = await AddNewCategory(payload);
	if(resp.status === 201) {
		alert("Neew Category Added");
		this.gotoAddPage();
	}
	else{
		alert("Some Error occurred")
	}
  }

  render() {
     
  return (
      <>
      <SidebarNav />
      <div className="page-wrapper">
			<div className="content container-fluid">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
					
						
						<div className="page-header">
							<div className="row">
								<div className="col">
									<h3 className="page-title">Add Category</h3>
								</div>
							</div>
						</div>
						
						
						<div className="card">
							<div className="card-body">
							
							
								<form onSubmit={this.submitCategory}>
									<div className="form-group">
										<label>Category Name</label>
										<input onChange={this.handleChange} required name="categoryName" className="form-control" type="text" />
									</div>
									<div className="form-group">
										<label>Category Description</label>
										<textarea className="form-control" onChange={this.handleChange} required name="desc"></textarea>
									</div>
									<div className="form-group">
										<label>Status</label>
										<select className="form-control" onChange={this.handleChange} required name="status" type="file">
											<option value="Active">Active</option>
											<option value="Hold">Hold</option>
											<option value="Inactive">Inactive</option>
										</select>
									</div>
									<div className="form-group">
										<label>Is SuperCategory Tag</label>
										<select className="form-control" onChange={this.handleChange} name="superCategory" type="file">
											<option value="" selected>No Super Category</option>
											<option value="business">Business</option>
											<option value="web-it">Web & IT</option>
										</select>
									</div>
									<div className="form-group">
										<label>Page Title Name</label>
										<input className="form-control" onChange={this.handleChange} name="pageTitle"/>
									</div>
									<div className="form-group">
										<label>Meta Description</label>
										<textarea className="form-control" onChange={this.handleChange} name="metaDescription"></textarea>
									</div>
									<div className="form-group">
										<input type="checkbox" checked={this.state.formData.mostPopular} onChange={this.handleMostPopularCheckbox} name="mostPopular"/>
										<label>Add To MostPopular</label>
									</div>
									<div className="mt-4">
										<button className="btn btn-primary" type="submit">Add Category</button>
										<Link to="/admin/category" className="btn btn-link">Cancel</Link>
									</div>
								</form>
							
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    );
  }
}

export default withRouter(AddCategories);
