import React, { Component } from "react";
import SidebarNav from "../sidebar";
import { Table } from "antd";

import {
  itemRender,
  onShowSizeChange,
} from "../paginationfunction";
import { GetAllContacts } from "../../../API/ContactsAPI";
import moment from "moment";

class ContactsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          data: [],
        };
      }
  
  fetchAllContacts = async () => {
    const resp = await GetAllContacts();
    if(resp.status === 200){
      this.setState({
        data: [...resp.data]
      });
    }
  }

  componentDidMount = () => {
    this.fetchAllContacts();
  }
    
  render() {

    const { data } = this.state;
    const columns = [
        {
          title: "Full Name",
          dataIndex: "name",
          render: (text, record) => (
          <>{text}</>
          ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            render: (text, record) => (
            <>{moment(text, 'YYYY-M-DD').format('DD-MMM, YYYY')}</>
            ),
          },
        {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
             <a href={`mailto:${text}`}>{text}</a>
            ),
            sorter: (a, b) => a.email.length - b.email.length,
          },

          {
            title: "Mobile Number",
            dataIndex: "contactNo",
            render: (text, record) => (
            <a href={`tel:${text}`}>{text}</a>
            )
          },
          {
            title: "Context",
            dataIndex: "context",
            render: (text, record) => (
            <>{text}</>
            )
          },
          {
            title: "Subject",
            dataIndex: "subject",
            render: (text, record) => (
            <>{text}</>
            )
          },
          {
            title: "Message",
            dataIndex: "message",
            render: (text, record) => (
            <>{text}</>
            )
          }
      ];
  
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			<div className="content container-fluid">

        <div className="page-header">
					<div className="row">
						<div className="col">
							<h3 className="page-title">Contacts Lists</h3>
						</div>
					</div>
				</div>


        <div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
								
                  <Table
                        className="table table-hover table-center mb-0 datatable"
                        style={{ overflowX: "auto", width:"100%"}}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                 
                 </div>
              </div> 
           </div>
        </div> 
     </div>               


        </div>
        </div>
      </>
    );
  }
}

export default ContactsList;
