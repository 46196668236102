import { Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ServiceTypesObj, SettingNames } from '../../../../GlobalComps/Constant';
import AddConsultation from '../StepperForms/AddConsultation';
import AddAddOnService from '../StepperForms/AddOnService';
import AddRetainership from '../StepperForms/AddRetainership';
import AddServices from '../StepperForms/AddService';
import AdminContext from '../../../AdminContext/AdminContext';
const _ = require('lodash')

export default function CreateServices({ createServicesSettings, settingsFromAdmin, categoryId, ServicesFormData, updateData, mode, specialization }) {
  const [settingsData, setsettingsData] = useState({});
  const [OfferingSettings, setOfferingSettings] = useState({});

    // New State for Specs
  const [specialitiesObject, setspecialitiesObject] = useState({})
  const [CommonSettingsObj, setCommonSettingsObj] = useState({})


    const cclient = useContext(AdminContext)


  useEffect(() => {
    const contextObj  = settingsFromAdmin;
    setsettingsData(contextObj?.settings?.[SettingNames.SERVICE_PROVIDER_FORM]?.setting1?.[categoryId]);
    setOfferingSettings(contextObj?.settings?.[SettingNames.SERVICE_PROVIDER_FORM]?.setting2?.[categoryId]);
  }, [categoryId, settingsFromAdmin])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // This will generate options for specialties Menu (Group wise)
  useEffect(() => {
    const specsArr = _.cloneDeep(specialization)
    // Finding the specialization for the category
    const selectedCategoryObj = getCategoryObjData(categoryId)

    // Subcategories activated for specialties to show in options
    const activatedSubcategories = [...createServicesSettings?.setting2?.[categoryId]?.subCategories || []]
    
    // Crearting specialties object for the Consultation, Services and Retainership
    let specCategoryObject = {}
    
    // Traversing through speciality array
    for (let i = 0; i < specsArr.length; i++) {
      const element = specsArr[i]; // catObj [ Finding SubSpecialization]
      const specCatId = element?.specialization || ''

      // Checking if sub-category is activated
      if(!activatedSubcategories?.includes(specCatId)){
        continue
      }

      // If spec is activated
      if(specCatId){
        const foundedsubCatObj = selectedCategoryObj?.SubCategories?.find(el => el?.category_id === specCatId) // Finding Subcategory
        const subCatName = foundedsubCatObj?.categoryName
        specCategoryObject = {
          ...specCategoryObject,
          [subCatName] : [...element?.subSpecialization || []]
        }
      }
    }
    setspecialitiesObject(specCategoryObject)
  }, [specialization])


    // =============================================================================
  // ========== Function to generate settings for services & retainership ========
  // =============================================================================
  const getCategoryObjData = (catId) => {
    const categoryDataArr = [...cclient?.AllCategories?.data || [], ...cclient?.AllCategories?.WebIt || [], ...cclient?.AllCategories?.Business || []]
    const selectedCategoryObj = categoryDataArr?.find(c => c?.category_id === catId)
    return selectedCategoryObj
  }

  const getSubCategoryName = useCallback((subCatId) => {
    const foundedsubCatObj = getCategoryObjData(categoryId)?.SubCategories?.find(el => el?.category_id === subCatId) // Finding Subcategory
      const subCatName = foundedsubCatObj?.categoryName
      return subCatName
  }, [categoryId])

  const generateServicesSettings = () => {
    const originalSettings = {...createServicesSettings?.setting1?.[categoryId] || {} }
    const specializationIds = specialization?.map(el => el?.specialization)
    // const settingsKeys = Object.keys(originalSettings)

    let commonSettings = {}

    for (let i = 0; i < specializationIds.length; i++) {
      // const subCatId = settingsKeys[i];
      const subCatId = specializationIds[i]
      const settingElem = originalSettings?.[subCatId]
  
      // Checking if subcategoriesId 
      // if(?.includes(subCatId)){
  
        // fetching Subcategory Name
        const subCatName = getSubCategoryName(subCatId)
        
        commonSettings = {
          ...commonSettings,
          Services: {
            inclusions: {
              options: {
                ...commonSettings?.Services?.inclusions?.options,
                [subCatName]: [...settingElem?.Services?.inclusions?.options || []],
              },
              enabled: commonSettings?.Services?.inclusions?.enabled || (settingElem?.Services?.inclusions?.enabled || false)
            },
            limits: {
              options: {
                ...commonSettings?.Services?.limits?.options,
                [subCatName]: [...settingElem?.Services?.limits?.options || []],
              },
              enabled: commonSettings?.Services?.limits?.enabled || (settingElem?.Services?.limits?.enabled || false)
            },
            offerings: {
              options: {
  
                ...commonSettings?.Services?.offerings?.options,
                [subCatName]: [...settingElem?.Services?.offerings?.options || []],
  
              }
            }

          },
          Retainership: {
            inclusions: {
              options: {
                ...commonSettings?.Retainership?.inclusions?.options,
                [subCatName]:[...settingElem?.Retainership?.inclusions?.options || [] ]
              },
              enabled: commonSettings?.Retainership?.inclusions?.enabled || (settingElem?.Retainership?.inclusions?.enabled || false)
            },
            limits: {
              options: {
                ...commonSettings?.Retainership?.limits?.options,
                [subCatName]: [...settingElem?.Retainership?.limits?.options || [] ]
              },
              enabled: commonSettings?.Retainership?.limits?.enabled || (settingElem?.Retainership?.limits?.enabled || false)
            }, 
            offerings: {
              options: {
  
                ...commonSettings?.Retainership?.offerings?.options,
              [subCatName]: [...settingElem?.Retainership?.offerings?.options || []],
  
              }
            }

          }
        }
      // } // End-if
    } // end-for
    // Updating State
    setCommonSettingsObj({...commonSettings})
  }
// ================================================================================

useEffect(() => {
  generateServicesSettings()
}, [createServicesSettings?.setting1?.[categoryId]])

     
  
  return (
    <div>
        <Typography variant="h5" className="fw-bold mb-2">Create Services</Typography>
        <p>
          Select Consultation/Service options from dropdown or write your own
        </p>
        { settingsData?.[ServiceTypesObj.CONSULTATION] !== false && 
              <AddConsultation mode={mode} 
                  offeringSetting={OfferingSettings?.['Consultation'] || []} 
                  data={ServicesFormData.Consultation}
                  specialitiesObject={specialitiesObject || {}}
                  specialtySetting={createServicesSettings?.setting2?.[categoryId]}  // Setting to control specialties 
                  updateState={(val) => updateData('Consultation',val)}
              />
          }
        { settingsData?.[ServiceTypesObj.SERVICE] !== false && 
              <AddServices mode={mode} 
                  offeringSetting={OfferingSettings?.['Services'] || []} 
                  data={ServicesFormData.Service}
                  specialitiesObject={specialitiesObject || {}}
                  specialtySetting={createServicesSettings?.setting2?.[categoryId]}  // Setting to control specialties
                  servicesSetting={CommonSettingsObj?.Services}  // Settings to control Limits, Inclusions
                  updateState={(val) => updateData('Service',val)}
              />
          }
        { settingsData?.[ServiceTypesObj.RETAINERSHIP] !== false && 
              <AddRetainership mode={mode} 
                  offeringSetting={OfferingSettings?.['Retainership'] || []} 
                  data={ServicesFormData.Retainership}
                  specialitiesObject={specialitiesObject || {}}
                  specialtySetting={createServicesSettings?.setting2?.[categoryId]}  // Setting to control specialties
                  servicesSetting={CommonSettingsObj?.Retainership}   // Settings to control Limits, Inclusions
                  updateState={(val) => updateData('Retainership',val)}
              />
          }
        { settingsData?.[ServiceTypesObj.ADDONSERVICE] !== false && 
              <AddAddOnService mode={mode} 
                  offeringSetting={OfferingSettings?.['Add On Service'] || []} 
                  data={ServicesFormData.AddOnService}
                  specialtySetting={createServicesSettings?.setting2?.[categoryId]}  // Setting to control specialties
                  updateState={(val) => updateData('AddOnService',val)}
              />
          }
    </div>
  )
}
