import React, { useState, useEffect, useCallback } from "react";
import SidebarNav from "../sidebar";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Table,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
} from "@mui/material";
import { getMainConsultationByOrderId, GetOrderDetailsById, getVideoCallDetailsByOrderId } from "../../../API/OrderAPI";
import { GetActivityByOrder } from "../../../API/ActivityAPI";


const dateToString = (date) => {
  return new Date(date).toLocaleString() || "Not Available";
};


const CallLogTable = ({ callData }) => {
  const [interactionData, setInteractionData] = useState([]);


  const processCallLog = useCallback((log) => {
    
    const userSessions = {};  // To track each user's join and leave timestamps
    const interactions = [];

    log.forEach((event) => {
      const { event: eventType, timestamp, user_id } = event;

      if (eventType === 'join') {
        if (!userSessions[user_id]) {
          userSessions[user_id] = [];
        }
        userSessions[user_id].push({ join: new Date(timestamp) });
      } else if (eventType === 'leave' && userSessions[user_id]?.length > 0) {
        // Find the last join event and record the leave time
        const lastSession = userSessions[user_id][userSessions[user_id].length - 1];
        if (lastSession && !lastSession.leave) {
          lastSession.leave = new Date(timestamp);
        }
      }
    });

    const allUserIds = Object.keys(userSessions);

    // Now calculate time both users were in the call together
    const user1Sessions = userSessions[allUserIds[0]] || [];
    const user2Sessions = userSessions[allUserIds[1]] || [];

    user1Sessions.forEach(user1Session => {
      user2Sessions.forEach(user2Session => {
        const overlap = getOverlap(user1Session, user2Session);
        if (overlap) {
          interactions.push(overlap);
        }
      });
    });

    return interactions;
  }, []);


  useEffect(() => {
    if(callData?.call_log){
    const processedData = processCallLog(callData?.call_log);
    setInteractionData(processedData);
    
    
  }
}, [callData, processCallLog]);

  const getOverlap = (session1, session2) => {
    const start = Math.max(session1.join.getTime(), session2.join.getTime());
    const end = Math.min(session1.leave?.getTime() || Infinity, session2.leave?.getTime() || Infinity);

    if (start < end) {
      const duration = Math.floor((end - start) / 1000); // duration in seconds
      return {
        start: new Date(start),
        end: new Date(end),
        duration,
      };
    }
    return null;
  };

  const formatTime = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div>
      <h2>Call Interaction Log</h2>
      <Table style={{ margin: 'auto', textAlign: 'center'}}>
        <thead>
          <tr>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {interactionData.length > 0 ? (
            interactionData.map((interaction, index) => (
              <tr key={index}>
                <td>{dateToString(interaction.start)}</td>
                <td>{dateToString(interaction.end)}</td>
                <td>{formatTime(interaction.duration)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No interactions found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};


const ConsultationComponent = ({ mainConsultation, videoCallDetails }) => {
  console.log('videoCallDetails', videoCallDetails);
  
  return (
    <Grid item xs={12}>
      <Box sx={{ width: "100%", my: 2, pt: 2, borderTop: "1px solid #e0e0e0" }}>
        <Typography variant="h5" component={"h3"} align="center">
          Consultation Details
        </Typography>
      </Box>

      <Grid item xs={12}>
        <Typography>
          Accepted Slot:{" "}
          {dateToString(
            mainConsultation?.consultationDetails?.selectedSlot?.startTime
          )}{" "}
          to{" "}
          {dateToString(
            mainConsultation?.consultationDetails?.selectedSlot?.endTime
          )}
        </Typography>

        {/* 3 rows and 3 column table, columns name are slot, starttime, endtime */}
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography align="center" variant="h6">Scheduled Slots</Typography>
          <Table style={{ margin: 'auto', textAlign: 'center'}}>
            <thead>
              <tr>
                <th>Slot</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(mainConsultation?.timeSlots || "[]").map(
                (slot, index) => (
                  <tr key={index + 'sdfsdf'}>
                    <td>{slot.slot}</td>
                    <td>{dateToString(slot.startTime)}</td>
                    <td>{dateToString(slot.endTime)}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Box>

        <Typography>
          Marked delivery from SP and User end:{" "}
        </Typography>
        <Typography>
          If any files shared:{" "}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ width: "100%", my: 2, pt: 2, borderTop: "1px solid #e0e0e0" }}>
          <Typography variant="h5" component={"h3"} align="center">
            Video Call Details
          </Typography>
        </Box>
        <CallLogTable callData={videoCallDetails} />
      </Grid>
    </Grid>
  );
};


const ServiceComponent = ({ service }) => {
  console.log(service, "service");
  
  return (
    <Grid item xs={12}>
      <Box sx={{ width: "100%", my: 2, pt: 2, borderTop: "1px solid #e0e0e0" }}>
        <Typography variant="h5" component={"h3"} align="center">
          Service Details
        </Typography>
      </Box>
      {/* Order booking time by user Order acceptance by SP Final
      draft shared time and date Draft/ order accepted Ticket raised If ticket
      raised issue resolved date and time */}
      <Typography>
        Order booking time by user: {dateToString(service?.bookingTime)}
      </Typography>
      <Typography>
        Order acceptance by SP: {dateToString(service?.acceptedAt)}
      </Typography>
      <Typography>
        Final draft shared time and date:{" "}
        {dateToString(service?.finalDraftSharedAt)}
      </Typography>
      <Typography>
        Draft/ order accepted: {dateToString(service?.draftAcceptedAt)}
      </Typography>
      <Typography>
        Ticket raised: {dateToString(service?.ticketRaisedAt)}
      </Typography>
      <Typography>
        If ticket raised issue resolved: {dateToString(service?.resolvedAt)}
      </Typography>
    </Grid>
  );
};
  

const ActivityCard = ({ agreementId, activityType, acceptedAt, details, user }) => {
  const parsedDetails = details ? details : {};

  return (
    <Box
      sx={{
        borderTop: "1px solid #e0e0e0",
        paddingTop: "16px",
        marginTop: "16px",
      }}
      key={agreementId}
    >
      <Typography variant="subtitle1">
        Activity Type : <strong>{activityType.split("_").join(" ")}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Activity Timestamp: <strong>{dateToString(acceptedAt)}</strong>
      </Typography>
      <Typography variant="subtitle1">
        User: <strong>{user}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Details: <strong>{parsedDetails}</strong>
      </Typography>
    </Box>
  );
};

function BookingDetails() {
  const { bookingid } = useParams();

  const [user, setUser] = useState({});
  const [order, setOrder] = useState({});
  const [activityList, setActivityList] = useState([]);
  const [mainConsultation, setMainConsultation] = useState({});
  const [scheduledConsultationObject, setScheduledConsultationObject] = useState({});
  const [videoCallDetails, setVideoCallDetails] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const orderRes = await GetOrderDetailsById(bookingid);
        setUser(orderRes.data.data.userId);
        setOrder(orderRes.data.data);

        const activityRes = await GetActivityByOrder(bookingid);
        setActivityList(activityRes.data.results);
        
        const consultationRes = await getMainConsultationByOrderId(bookingid);
        setMainConsultation(consultationRes.data);

        const videoCallDetailsRes = await getVideoCallDetailsByOrderId(bookingid);
        setVideoCallDetails(videoCallDetailsRes.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    fetchData();
  }, [bookingid]);

  useEffect(() => {
    if (!activityList.length) return;

    const filteredActivityList = activityList
      .map((activity) => JSON.parse(activity.details))
      .filter((details) => details.status === "ScheduledConsultation")
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    setScheduledConsultationObject(filteredActivityList[0] || {});
  }, [activityList]);

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Booking details */}
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            className="mb-4"
          >
            <Box sx={{ width: "100%", mb: 1 }}>
              <Typography
                variant="h4"
                component={"h2"}
                align="center"
                color="primary"
              >
                Booking Details
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mb: 1 }}>
              <Typography component={"h3"} align="center">
                Type: {order.serviceType}
              </Typography>
              <Typography component={"h3"} align="center">
                Amount: ₹{order.charges}
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography>Booking Id: {bookingid}</Typography>
              <Typography>
                Booked At: {dateToString(order.created_at)}
              </Typography>
              <Typography>
                Accepted At: {dateToString(scheduledConsultationObject?.updated_at)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Booking Status: {order.status}
              </Typography>
              <Typography>Comment: {order.statusComment}</Typography>
              <Typography>
                Accepted Slot:{" "}
                {dateToString(
                  mainConsultation?.consultationDetails?.selectedSlot?.startTime
                )}{" "}
                to{" "}
                {dateToString(
                  mainConsultation?.consultationDetails?.selectedSlot?.endTime
                )}
              </Typography>
            </Grid>
          </Grid>
          {/* End booking details */}

          <Grid container spacing={3}>
            {/* User Details */}
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                User Details:
              </Typography>
              <Typography>User Name: {user.username}</Typography>
              <Typography>Email: {user.email}</Typography>
              <Typography>Phone: {user.phone}</Typography>
              <Typography variant="subtitle1">
                Mark Delivery User:{" "}
                <strong>
                  {String(order?.user_mark_as_done)}
                </strong>
              </Typography>
            </Grid>
            {/* End User Details */}

            {/* ServiceProvider Details */}
            {order.serviceProviderId && (
              <Grid item xs={6}>
                <Typography style={{ fontWeight: "bold" }}>
                  Service Provider Details:
                </Typography>
                <Typography>
                  User Name:{" "}
                  <strong>{order.serviceProviderId.userId.username}</strong>
                </Typography>
                <Typography>
                  Email: <strong>{order.serviceProviderId.userId.email}</strong>
                </Typography>
                <Typography>
                  Phone: <strong>{order.serviceProviderId.userId.phone}</strong>
                </Typography>
                <Typography variant="subtitle1">
                  Mark Delivery from SP:{" "}
                  <strong>
                    {String(order.service_provider_mark_as_done)}
                  </strong>
                </Typography>
              </Grid>
            )}
            {/* End ServiceProvider Details */}

            {/* Consultation Details */}
            {order.serviceType === "Consultation" && (
              <ConsultationComponent mainConsultation={mainConsultation} videoCallDetails={videoCallDetails} />
            )}
            {/* End Consultation Details */}

            {/* Service Details */}
            {order.serviceType === "Service" && (
              <ServiceComponent service={order.service} />
            )}
            {/* End Service Details */}

            {/* Activity Details */}
            <Grid item xs={12}>
              {activityList.map((agreement, index) => (
                <ActivityCard
                  agreementId={agreement.id}
                  activityType={agreement.activity_type}
                  acceptedAt={agreement.activity_timestamp}
                  details={agreement.details}
                  user={agreement.user}
                />
              ))}
            </Grid>
            {/* End Activity Details */}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default BookingDetails;
