import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";
export async function CreateNewOrder(body){
    const response = await axiosInstanceWithSecret.post(`/create-new-order`, body);
    return response;
 }

 export async function GetAllOrders(queryParams) {
    const response = await axiosInstanceWithSecret.get(`/get-all-orders`, {
      params: queryParams,
    });
    return response;
 }

 export async function GetOrdersByProviderId(providerId){
    const response = await axiosInstanceWithSecret.get(`/get-orders-by-serviceprovider`,{
        params:{
            provider_id:providerId
        }
    });
    return response;
 }

 export async function UpdateOrderDetails(orderId, orderData){
    const response = await axiosInstanceWithSecret.put(`/update-order`, orderData,{
        params:{
            order_id:orderId
        }
    });
    return response;
 }

 export async function GetOrderDetailsById(orderId){
    const response = await axiosInstanceWithSecret.get(`/get-order-by-id`, {
      params: {
        order_id: orderId,
      },
    });
    return response;
 }

 export async function getMainConsultationByOrderId(order_id) {
    const response = await axiosInstanceWithSecret.get(`/get-main-consultation-by-order-id`, {
    params: {
        order_id: order_id,
    },
    })
    return response;    
 }

 export async function getVideoCallDetailsByOrderId(orderId) {
    const response = await axiosInstanceWithSecret.get(`/get-video-call-by-order-id`, {
    params: {
        order_id: orderId,
    },
    })
    return response;    
 }


 export async function OrderRelatedFile(order_id, body=null) {
    try {
      let response;
      const url = `/get-order-related-files?order_id=${order_id}`;
  
      if (body) {
        // Use POST request if body is provided
        response = await axiosInstanceWithSecret.post(url, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        // Use GET request if no body is provided
        response = await axiosInstanceWithSecret.get(url);
      }
  
      return response;
    } catch (err) {
      return err.response;
    }
  }