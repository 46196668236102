import React, { Fragment, useEffect, useState } from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/header/index";
import Dashboard from "./components/dashboard";
import Categories from "./components/category";
import AddCategories from "./components/category/add";
import EditCategories from "./components/category/edit";
import SubCategories from "./components/subcategory";
import AddSubCategories from "./components/subcategory/add";
import EditSubCategories from "./components/editcategory";
import Services from "./components/servicedetail";
import ServicesDetail from "./components/servicedetail/view";
import Payment from "./components/payment";
import PaymentDetails from "./components/payment/details";

import Rating from "./components/rating";
// import RatingType from "./components/ratingtype";
import EditSubscriptions from "./components/editsubscription";
import Subscriptions from "./components/subscription";
import AddSubscriptions from "./components/addsubscription";
import User from "./components/user";
import Settings from "./components/settings";
import Profile from "./components/profile";
import Booking from "./components/booking";
// import Wallet from "./components/wallet";
// import WalletHistory from "./components/wallet/history";
// import EditRatingtype from "./components/ratingtype/editratingtype";
// import AddRatingtype from "./components/ratingtype/addratingtype";
import SubSubCategories from "./components/subsubcategory";
// import EditProfileForm from "./components/serviceprovider/EditProfileForm";
import AdminLoginPage from "./components/AdminLogin/AdminLoginPage";
import { getcurrentIP } from "../GlobalComps/Helper";
import AdminUsers from "./components/AdminUsers";
import ContactsList from "./components/Contacts";
import EditProfileForm from "./components/serviceprovider/EditProfileForm";

import Documents from "./components/Documents"
import PostLists from "./components/Blog/Posts/PostLists";
import SinglePost from "./components/Blog/Posts/SinglePost";
import TermsAndConditionsLists from "./components/TermsAndConditions/TermsAndConditionsLists";
import SingleTermsAndCondition from "./components/TermsAndConditions/SingleTermsAndCondition";
import BookingDetails from "./components/booking/details";
import AddFaq from "./components/category/edit/AddFaq";
import EditFaq from "./components/category/edit/EditFaq";
import Notification from "./components/notification";
import AddNotification from "./components/notification/add";
import EditNotification from "./components/notification/edit";
import QueryMsg from "./components/query-msg";
import TrackBookingJourney from "./components/track-booking-journey";
const AppUniversal = function (props) {
  const [isAdminLogin, setisAdminLogin] = useState(false);

  //To Stay Logged in
  //  const [isAdminLogin, setisAdminLogin] =  useState(localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : null)


  const [access, setaccess] = useState(false);
  const [allowedIPAddresses] = useState(['49.36.189.21', '103.211.52.237', '180.151.243.24', '125.63.112.129']);
  // const history = useHistory();

  const checkIP = async () => {
    setaccess(true)
    return;
    const resp = await getcurrentIP();
    if(!allowedIPAddresses.includes(resp.toString())){
      window.location.href = "/";
    }
    else{
      setaccess(true)
    }
  }

  useEffect(() => {
    checkIP();
  }, [])

  return (
    <Fragment>
    {
      access ?
      <Fragment>
      {
        isAdminLogin
        ?<Router basename={''}>
          <div className="main-wrapper">
            <Route render={(props) => <Header {...props} />} />
            <Switch>
              <Route path="/admin" exact component={Dashboard} />
              <Route path="/admin/category" exact component={Categories} />
              <Route path="/admin/add-categories" exact component={AddCategories} />
              <Route path="/admin/edit-categories/:categoryid" exact component={EditCategories} />
              <Route path="/admin/edit-categories/:categoryid/add-faq" exact component={AddFaq} />
              <Route path="/admin/edit-categories/:categoryid/edit-faq/:faqid" exact component={EditFaq} />

              <Route path="/admin/sub-categories/:categoryid" exact component={SubCategories} />
              <Route path="/admin/subsub-categories/:categoryid" exact component={SubSubCategories} />
              <Route path="/admin/add-subcategories/:categoryid" exact component={AddSubCategories} />
              <Route path="/admin/edit-subcategories/:subcategoryid" exact component={EditSubCategories} />
              {/* <Route path="/admin/services" exact component={Services} /> */}
              <Route path="/admin/provider-details/:providerId" exact component={ServicesDetail} />
              <Route path="/admin/edit-provider-details/:providerId" exact >
                <EditProfileForm/>
              </Route>
              <Route path="/admin/user" exact component={User} />
              <Route path="/admin/edit-subscriptions/:planid" exact component={EditSubscriptions} />
              <Route path="/admin/subscriptions" exact component={Subscriptions} />
              <Route path="/admin/settings" exact component={Settings} />
              <Route path="/admin/service-provider" exact component={Services} />
              <Route path="/admin/documents" exact component={Documents} />
              {/* <Route path="/admin/wallet" exact component={Wallet} /> */}
              {/* <Route path="/admin/wallet-history" exact component={WalletHistory} /> */}
              <Route path="/admin/add-subscriptions" exact component={AddSubscriptions}/>
              <Route path="/admin/rating" exact component={Rating}/>
              {/* <Route path="/admin/ratingtype" exact component={RatingType}/> */}
              {/* <Route path="/admin/edit-ratingtype" exact component={EditRatingtype}/> */}
              {/* <Route path="/admin/add-ratingtype" exact component={AddRatingtype}/> */}
              <Route path="/admin/payments" exact component={Payment}/>
              <Route path="/admin/payments/:bookingid" component={PaymentDetails}/>
              <Route path="/admin/profile" exact component={Profile}/>
              <Route path="/admin/bookings" exact component={Booking} />
              <Route path="/admin/bookings/:bookingid" exact component={BookingDetails} />
              <Route path="/admin/list-admin-users" exact component={AdminUsers}/>
              <Route path="/admin/list-contacts" exact component={ContactsList}/>
              <Route path="/admin/posts" exact component={PostLists} />
              <Route path="/admin/posts/post" component={SinglePost} />
              <Route path="/admin/clickwrap-agreement" exact component={TermsAndConditionsLists} />
              <Route path="/admin/clickwrap-agreement/add" component={SingleTermsAndCondition} />

              <Route path="/admin/notification" exact component={Notification} />
              <Route path="/admin/notification/add" component={AddNotification} />
              <Route path="/admin/notification/edit/:templateid" exact component={EditNotification} />
              <Route path="/admin/query-msg" exact component={QueryMsg} />
              <Route path="/admin/track-booking-journey" exact component={TrackBookingJourney} />

            </Switch>
          </div>
        </Router>
        :<AdminLoginPage setisAdminLogin={setisAdminLogin}/>
      }
    </Fragment>
    :<div className="w-full text-center p-5">
      <h1>Verifying Auth Access....</h1>
      <h5>Please Wait...</h5>
    </div>
    }
    </Fragment>
  );
};

export default AppUniversal;
