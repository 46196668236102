import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetAllDocumentsServiceProvider(params){

    try{
        // console.log(`/get-all-serviceprovider-documents`)
        const response = await axiosInstanceWithSecret.get(`/get-all-serviceprovider-documents${params}`);
        // const response = await axiosInstanceWithSecret.get(`/get-all-serviceprovider-documents`);
        return response;
    }
    catch(err){
        return err.response;
    }
   
 }


