import React, { useState, useEffect, useCallback } from "react";
import SidebarNav from "../sidebar";
import Editor from "../Blog/Posts/Editor";
import Button from "react-bootstrap/Button";
import {
  AddNewTermsAndConditions,
  GetTermsAndConditionsById,
  UpdateTermsAndConditions,
} from "../../../API/TermsAndConditionsAPI";

import { useHistory } from "react-router-dom";

function SingleTermsAndCondition() {
  const [content, setContent] = useState("");
  const [post, setPost] = useState({});

  const history = useHistory();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let terms_id = params.get("terms_id") || null;

  const updateTermsAndConditionState = (event) => {
    const { name, value } = event.target;
    setPost((prevPost) => ({ ...prevPost, [name]: value }));
  };

  useEffect(() => {
    if (terms_id) {
      GetTermsAndConditionsById(terms_id)
        .then((response) => {
          setPost(response.data);
          setContent(response.data?.text || "");
        })
        .catch((error) => {
          console.error("Error in getting Terms And Condition:", error);
        });
    }
  }, [terms_id]);

  const handleContentSave = useCallback(
    (event) => {
      event.preventDefault();
      if (window.confirm("Do you want to save changes?")) {
        if (terms_id) {
          UpdateTermsAndConditions(terms_id, { ...post, text: content })
            .then((response) => {
              history.push("/admin/clickwrap-agreement");
            })
            .catch((error) => {
              console.error("Error updating Terms and Condition:", error);
            });
        } else {
          AddNewTermsAndConditions({ ...post, text: content })
            .then((response) => {
              history.push("/admin/clickwrap-agreement");
            })
            .catch((error) => {
              console.error("Error creating new Terms and Condition:", error);
            });
        }
      }
    },
    [content, history, post, terms_id]
  );

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <form className="page-header" onSubmit={handleContentSave}>
            <div className="row my-2">
              <label htmlFor="title" className="mr-2">
                Version
              </label>
              <input
                type="text"
                name="version"
                id="version"
                value={post?.version || ""}
                placeholder="Enter version"
                onChange={updateTermsAndConditionState}
                required
              />
            </div>
            <div className="col my-2 mx-auto">
              <label htmlFor="status" className="">
                Status
              </label>
              <select
                name="status"
                id="status"
                value={!!post?.status ? "1" : "0"}
                onChange={updateTermsAndConditionState}
              >
                <option value="0">Draft</option>
                <option value="1">Publish</option>
              </select>
            </div>
            <div className="row my-2">
              <Editor setContent={setContent} content={content} />
            </div>
            <div className="col my-2 align-between">
              <Button variant="primary" type="submit" className="mr-2">
                Save
              </Button>
              {/* {terms_id && (
                <Button variant="danger" onClick={handleContentDelete}>
                  Delete
                </Button>
              )} */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SingleTermsAndCondition;
