import React, { Component } from "react";
import SidebarNav from "../../sidebar";
import { Table } from "antd";
import 'antd/dist/reset.css';

import {  itemRender, onShowSizeChange } from "../../../components/paginationfunction";

import moment from "moment";
import { Link } from "react-router-dom";


class AllBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      show: null,
      StatusColors:{
          Pending:'badge badge-warning',
          InProgress:'badge badge-warning',
          Scheduled:'badge badge-info',
          PartialyCompleted:'badge badge-secondary',
          Completed:'badge badge-success',
          Cancelled:'badge badge-danger',
          Tickets:'badge badge-secondary',
      }
    };
  }

  handleShow = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { ordersData } = this.props;
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        render: (text, record) => <>{record?.short_id}</>,
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (text, record) => (
          <>{moment(record?.updated_at).format("D-MMM yyyy")}</>
        ),
      },
      {
        title: "User Name",
        dataIndex: "user",
        render: (text, record) => (
          <div className="avatar avatar-sm mr-2">
            {record?.userUsername}
          </div>
        ),
        sorter: (a, b) => a.name.length - b.name.length,
      },

      {
        title: "Provider",
        dataIndex: "providername",
        render: (text, record) => (
          <div className="avatar avatar-sm mr-2">
            {record?.serviceProviderUsername}
          </div>
        ),
        sorter: (a, b) => a.providername.length - b.providername.length,
      },

      {
        title: "Service Type",
        dataIndex: "serviceType",
        render: (text, record) => <> {record?.serviceType} </>,
        sorter: (a, b) => a.date.length - b.date.length,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (text, record) => <> {record.charges} </>,
        sorter: (a, b) => a.charges - b.charges,
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => (
          <>
            {" "}
            <div className={this.state.StatusColors?.[record.status]}>
              {record.status}
            </div>{" "}
          </>
        ),
        sorter: (a, b) => a.charges.length - b.charges.length,
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (text, record) => (
          <Link
            className="btn-link link-primary"
            to={`/admin/bookings/${record.order_id}`}
          >
            {" "}
            View Details
          </Link>
        ),
        sorter: (a, b) => a.updated.length - b.updated.length,
      },
    ];

    return (
      <>
        <SidebarNav />
         <Table
            className="table mb-0"
            style={{ overflowX: "auto", width:"100%"}}
            columns={columns}
            bordered
            dataSource={ordersData}
            rowKey={(record) => record.id}
            showSizeChanger={true}
            pagination={{
              total: ordersData?.length,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              itemRender: itemRender,
            }}
          />
      </>
    );
  }
}

export default AllBooking;
