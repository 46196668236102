import React, { useState } from 'react'
import logo from "../../../assets/images/logo.png";
import favicon from "../../../assets/images/favicon.png";

export default function WebsiteSettings() {
    const [formData, setformData] = useState({});

  const handleChange = (e) => {
    setformData({...formData, [e.target.name]: e.target.value});
  }

  return (
    <div>
        <div className="card mb-0">
                <div className="card-header">
                    <h4 className="card-title">General Settings</h4>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label>Website Name</label>
                        <input type="text" className="form-control" placeholder="Dreamguy's Technologies" onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Contact Details</label>
                        <input type="text" className="form-control"  onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input type="text" className="form-control" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Currency</label>
                        <p><strong>USD $ </strong></p>
                    </div>
                    <div className="form-group">
                        <label>Website Logo</label>
                        <div className="uploader">
                            <input type="file" className="form-control" />
                        </div>
                        <p className="form-text text-muted small mb-0">Recommended image size is <b>150px x 150px</b>
                        </p>
                        <img src={logo} className="site-logo" alt="" />
                    </div>
                    <div className="form-group">
                        <label>Favicon</label>
                        <div className="uploader">
                            <input type="file" className="form-control" />
                        </div>
                        <p className="form-text text-muted small mb-0">Recommended image size is <b>16px x 16px</b> or <b>32px x 32px</b></p>
                        <p className="form-text text-muted small mb-1">Accepted formats: only png and ico</p>
                        <img src={favicon} className="fav-icon" alt="" />
                    </div>
                </div>
            </div>
    </div>
  )
}
