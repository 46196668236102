import { Table, TableBody, TableCell, TableRow, Switch, TextField, TableHead } from '@mui/material';
// import { Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { GetAllCategoriesOptimized } from '../../../../API/CategoryAPI';
import { ServicesTypes, SettingNames } from '../../../../GlobalComps/Constant';
import { UpdateSettings } from '../../../../API/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Convert2dTo1dArray } from '../../../../GlobalComps/Helper';

export default function CategorySettingsTab({ settingsData }) {
  const [formData, setformData] = useState({serviceForm:{}});
  const [CategoryList, setCategoryList] = useState([]);

  const handleChange = (e) => {
    setformData({...formData, [e.target.name]: e.target.value});
  }

  const fetchCategoryList = async () => {
    const resp = await GetAllCategoriesOptimized();
    if(resp.status === 200){
        setCategoryList(Convert2dTo1dArray(Object.values(resp.data)));
    }
  }

  const checkSwitch = (servicetype) => {
    const cat = formData?.category;
    let checked = true;
    const cst = formData.serviceForm?.[cat]?.[servicetype];
    if(typeof cst !== 'undefined') {
      checked = cst;
    }
    // console.log("Switch Func",checked, servicetype, "==>",cst);
    return checked;

  }

  const markServiceForm = (servicetype) => {
    const cat = formData?.category;
    if(!cat){
      alert('Please select a category');
      return;
    }
    else{
      const checked = checkSwitch(servicetype);
      setformData(prevState => ({
        ...formData, 
        serviceForm: {...prevState.serviceForm, 
          [cat]:{...prevState.serviceForm?.[cat], [servicetype]: !checked}
        }
      }) );
    }
  }

  const updateSettings = async () => {
    const body = {
      settingName: SettingNames.SERVICE_PROVIDER_FORM,
      setting1:{...formData?.serviceForm}    
    }
    const resp = await UpdateSettings(body, SettingNames.SERVICE_PROVIDER_FORM);
    if(resp.status === 200) {
      updateOfferingsSettings();
    }
  }

  const updateOfferingsSettings = async () => {
    const body = {
      settingName: SettingNames.SERVICE_PROVIDER_FORM,
      setting2:{...formData?.offeringSettingData}    
    }
    const resp = await UpdateSettings(body, SettingNames.SERVICE_PROVIDER_FORM);
    if(resp.status === 200) {
      alert('Settings updated successfully');
    }
  }

  const offeringFieldHandler = (event, servTab, index) => {
    let arr = formData?.offeringSettingData?.[formData?.category]?.[servTab];
    arr[index] = event.target.value;
    setformData(prevState => ({
      ...prevState,
      offeringSettingData: {
        ...prevState.offeringSettingData,
        [formData?.category]:{
          ...prevState.offeringSettingData?.[formData?.category],
          [servTab]:[...arr]
        }
      }
    }));
  }

  const addOfferingField = (servTab) => {  
    let arr = formData?.offeringSettingData?.[formData?.category]?.[servTab] || [];
    arr.push("");
    setformData(prevState => ({
      ...prevState,
      offeringSettingData: {
        ...prevState.offeringSettingData,
        [formData?.category]:{
          ...prevState.offeringSettingData?.[formData?.category],
          [servTab]:[...arr]
        }
      }
    }));
  }

  const removeOfferingField = (index, servTab) => {  
    let arr = formData?.offeringSettingData?.[formData?.category]?.[servTab] || [];
    arr.splice(index, 1);
    setformData(prevState => ({
      ...prevState,
      offeringSettingData: {
        ...prevState.offeringSettingData,
        [formData?.category]:{
          ...prevState.offeringSettingData?.[formData?.category],
          [servTab]:[...arr]
        }
      }
    }));
  }


  useEffect(() => {
    setformData({ serviceForm: {...settingsData?.setting1}, offeringSettingData: {...settingsData?.setting2} });
  }, [settingsData])
  

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const servicesTabs = ['Services','Consultation','Retainership','Add On Service' ];
  
  return (
    <div className="card">
        <div className="card-header">
            <h2>Category Settings</h2>
        </div>
        <div className="card-body">
            <form>
                <div className="form-group">
                    <label>Select Category</label>
                    <select defaultValue="" value={formData?.category || ""} className="form-control" name="category" placeholder="Select category" onChange={handleChange}>
                        <option value="" disabled>Select Category</option>
                        {
                            CategoryList?.map((cat) =>
                            <option value={cat?.category_id}>{cat?.categoryName}</option>
                        )}
                    </select>
                </div>
              {formData.category && <>
                <Table>
                  <TableBody>
                  { ServicesTypes?.map((services) =>
                    <TableRow>
                      <TableCell>{services}</TableCell>
                      <TableCell>
                      <Switch
                        checked={checkSwitch(services)}
                        onChange={() => markServiceForm(services)} 
                        color="primary"
                        name={`Check to Activate ${services}`}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
                {/* <button onClick={updateSettings} type="button" className="mt-5 btn btn-primary">Update Settings</button> */}
                {/* </TableContainer> */}

                {/* Offerings */}
                <h5 className='mt-5 text-secondary'>Offerings</h5>

                <Table>
                  <TableHead>
                    <TableRow>
                      { servicesTabs.map( ell =>
                        <TableCell>{ell}</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  <TableRow>
                  { servicesTabs.map( service_tab =>
                    <TableCell>
                      {
                        formData?.offeringSettingData?.[formData?.category]?.[service_tab]?.map((el, index) =>
                        <div className="d-flex" style={{placeItems:'center'}}>
                          <TextField
                            variant='outlined'
                            placeholder="Enter Offerring"
                            className='my-3'
                          id="outlined-basic" name="" value={el} onChange={(e)=> offeringFieldHandler(e,service_tab, index)}/>
                          <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={()=>removeOfferingField(index, service_tab)} icon={faTrashAlt} className="mx-3 text-danger"/>
                        </div>
                        )
                      }
                      <div style={{cursor: 'pointer'}} onClick={()=>addOfferingField(service_tab)}>
                        <FontAwesomeIcon icon={faPlusCircle} className="text-primary"/>
                        Add Offering
                      </div>
                    </TableCell>
                  )}
                  </TableRow>
                  </TableBody>
                </Table>
                <button onClick={updateSettings} type="button" className="mt-5 btn btn-primary">Update Settings</button>
              </>
              }
            </form>
        </div>
    </div>
  )
}
