import React from 'react';

const PDFViewer = ({ fileLink }) => {
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <iframe
        title={"PDF File"}
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          fileLink
        )}&embedded=true`}
        // style={{ width: "50vw", height: "60vh" }}
        className='w-100 h-100'
        frameBorder="0"
      >
        Sorry, your browser doesn't support embedded PDFs.
      </iframe>
    </div>
  );
};

export default PDFViewer;