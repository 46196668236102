import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetBookingJourney(session_id) {
    let response;
    if (session_id) {
        response = await axiosInstanceWithSecret.get(`track-booking-journey/?session_id=${session_id}`);
    }
    else {
        response = await axiosInstanceWithSecret.get(`track-booking-journey/`);
    }
    return response;
}