import * as React from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
import { toggleValueInArray } from './Helper';
import { Checkbox, FormControl, Input, InputLabel, ListItemText, 
  // ListSubheader,  OutlinedInput, 
  MenuItem, Select } from '@mui/material';

export default function DropdownGroupMultiselect({ width, selectControlProps, required, value, onchange, options, label }) {

  const handleChange = (selectedCheck) => {
    onchange(
        toggleValueInArray(value, selectedCheck)
    );
  };

  return (
      <FormControl fullWidth={true} variant='standard'>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          required={required}
          // className={`w-full md:w-[${width}]`}
          value={value}
          {...selectControlProps}
          input={<Input style={{width: width}} variant='standard' label={label}/>}
          renderValue={(selected) => selected.join(', ')}
        >
          {Object.keys(options || {})?.map((group) => (
          [
            <MenuItem onClick={()=>handleChange(group)} key={group} className='bg-primary font-bold bg-blue-200 flex items-center'>
              <Checkbox value={group} checked={value?.includes(group)} />
                <ListItemText primary={group} />
            </MenuItem>,
            options?.[group]?.map((option) => (
              <MenuItem onClick={()=>handleChange(option)} key={option} value={option}>
                <Checkbox value={option} checked={value?.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))
          ]
        ))}
        </Select>
      </FormControl>
  );
}

DropdownGroupMultiselect.defaultProps = {
  width: '8rem'
}