import React, { Fragment, useContext, useEffect, useState } from 'react'
import { CreateNewDocument, GetProviderFormData, GetServiceProviderById, SaveProviderFormData, UpdateDocumentById, UpdateServiceProvider } from '../../../API/ServiceProviderAPI';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import styles from './CompleteProfile.module.css';
import { Backdrop, Button, Card, CardContent, CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import CategoryForm from './profileForms/CategoryForm';
import CreateServices from './profileForms/CreateServices';
import ServicesForm from './profileForms/ServicesForm';
import ProfileDocuments from './profileForms/ProfileDocuments';
import PricePlanForm from './profileForms/PricePlanForm';
import { SaveSPFormModel, ServicesTypes, SettingNames } from '../../../GlobalComps/Constant';
import { checkNull, JsonToFormdata, RequiredFieldsAlert } from '../../../GlobalComps/Helper';
import Swal from 'sweetalert2';
import { AddNewService, UpdateService, UpsertBulkServices } from '../../../API/Service';
import AdminContext from '../../AdminContext/AdminContext';
import { Toast } from 'bootstrap';

  function getSteps(mode) {
    if(mode === 'edit'){
    return ['Categories', 'Create Services','Service Details', 'Documents & Terms'];
    }
    return ['Categories', 'Create Services','Service Details', 'Documents & Terms', 'Choose plan Free / Paid'];
  }



export default function CompleteProfile({adminView=false, PropProviderId=undefined, mode='add', settingsFromAdmin={}}) {
  // State to exempt From initial render

  // Step States
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(mode);
  const [verificationFormData, setverificationFormData] = useState({});
  const [ServicesFormData, setServicesFormData] = useState({});
  // Documents Data State
  const [documentsFormData, setdocumentsFormData] = useState({});
  const [documentsEditData, setdocumentsEditData] = useState({});
  const [documentsAcceptTnc, setdocumentsAcceptTnc] = useState(true)
  const [createServicesSettings, setcreateServicesSettings] = useState({})


  const [saveFormObj, setsaveFormObj] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const {ProviderId} = useParams();

  const history = useHistory()

  const cclient = useContext(AdminContext);


  useEffect(() => {
    //setsettingsData(cclient?.settings?.[SettingNames.SERVICE_PROVIDER_FORM]?.setting1?.[verificationFormData?.CategoryData?.category]);
    setcreateServicesSettings(cclient?.settings?.[SettingNames.CREATE_SERVICES_SETTING])
  }, [cclient, verificationFormData])

  const setCategoryFormData = (name, val) => {
    setverificationFormData({
      ...verificationFormData,
      CategoryData:{...verificationFormData.CategoryData, [name]: val}});
    }

  // function getStepContent(stepIndex) {
  //   switch (stepIndex) {

  //     default:
  //       return 'Unknown stepIndex';
  //   }
  // }

  const updateServiceFormData = (name, obj) => {
    // console.log('updateServiceFormData ==>', name, obj);
    setServicesFormData({
      ...ServicesFormData,
      [name]:[...obj || []]
    });
  }


  const saveFormToDb = async (step) => {
    const spId = PropProviderId || localStorage.getItem('LoggedinProviderId') || '';
    let saveFormData;
    if((spId && spId!=='') || PropProviderId){
      saveFormData = {...saveFormObj, serviceProviderId: spId || PropProviderId};
    }
    else{
      Swal.fire('Error Saving Form To Server', 'Please Try Refreshing the form', 'error');
      return;
    }
    switch (step) {
      case 0:
        saveFormData = {...saveFormData, [SaveSPFormModel[step]]: verificationFormData?.CategoryData}
        break;
      case 1:
        saveFormData = {...saveFormData, [SaveSPFormModel[step]]: ServicesFormData}
        break;
      case 2:
        saveFormData = {...saveFormData, [SaveSPFormModel[step]]: verificationFormData?.ServiceData}
        break;
      case 3:
        saveFormData = {...saveFormData, [SaveSPFormModel[step]]: documentsFormData}
        break;
      case 4:
        saveFormData = {...saveFormData, [SaveSPFormModel[step]]: verificationFormData?.PlanData}
        break;

      default:
        break;
    }
    setsaveFormObj(saveFormData);
    if(!spId){
      toast.error('Cannot Save Form currently')
      return;
    }
    const resp = await SaveProviderFormData(saveFormData, spId);
    if(resp.status === 201){
      toast.success('Form saved successfully');
    }
    else{
      toast.error('Saving Form Failed');
    }
  }

  const handleNext = (e) => {
    e.preventDefault()
    // if(formValidation()){
      saveFormToDb(activeStep);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
    // else{
    //   // Swal.fire({ title: 'validation Failed', text: 'Please check your Fields', icon: 'error'})
    // }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Updating or Inserting Service PRovider Data
  const handleSubmitForm = async () => {

    // Checking Plan Data Validation
    if(mode!=='edit' && (!verificationFormData.PlanData?.plan || verificationFormData?.PlanData?.plan === '')){
      toast.error('Please Select Your Plan');
      return;
    }
    // Loading State
    setisLoading(true);

    // Checking Specialization
    const specsArray = verificationFormData?.CategoryData?.specialization;
    for (let i = 0; i < specsArray.length; i++) {
      const element = specsArray[i];
      if(element.specialization && element?.specialization !== ''){
        continue;
      }
      else{
        Swal.fire('Specialization Not Added', 'Please Refresh & Try again', 'warning')
        setisLoading(false);
        setActiveStep(0);
        return;
      }
    }

    // Submitting Profile Documents
    let respDoc;
    try{
      // Update API Call for Document (If Mode = edit)
      if(mode === 'edit'){
        let profileDocumentObj = JsonToFormdata(documentsEditData);
        if (Object.values(documentsEditData).length > 0)  // Updating if Form Editted
          respDoc = await UpdateDocumentById(profileDocumentObj, documentsFormData.ProfileDocument_id);
      }
      else{
        let profileDocumentObj = JsonToFormdata(documentsFormData);
        if (profileDocumentObj)
          respDoc = await CreateNewDocument(profileDocumentObj);
        else
          toast.error('Error Creating Document')
        // console.log("RespDoc ==>", respDoc);
      }
    }
    catch(err){
      if(err.response.status !== 201) {
          let resp2 = err.response.body;
          if(err.response.status === 400){
            const errorArr = [];
            const obj = Object.keys(resp2?.data || {});
            for (let i = 0; i < obj.length; i++) {
              const element = resp2.data?.[obj[i]][0];
              errorArr.push(obj[i]+'::'+element);
            }
            Swal.fire({
              title:errorArr.join(','),
              text: 'Validation Error',
              icon: 'error'
            });
          setActiveStep(3);
          setisLoading(false);
          return;
        } // end of for
      }
      setisLoading(false);
    }

    // ==== Creating Service Provider Payload ====
    const payload = {
      coreExpertise: verificationFormData?.ServiceData?.skills,
      companyProfile: verificationFormData?.companyProfile || '',
      category: verificationFormData?.CategoryData?.category,
      globalExpertise: verificationFormData?.ServiceData?.globalSkills,
      catchyLine: verificationFormData?.ServiceData?.catchyLine,
      tagline: verificationFormData?.ServiceData?.tagline,
      preferred_days_time: verificationFormData?.ServiceData?.preferred_days_time,
      portfolioLink: verificationFormData?.portfolioLink,
      serviceCountries: verificationFormData?.ServiceData?.countries?.join(',') || '',
      languageProficiency: verificationFormData?.ServiceData?.languageProficiency || [],
      specialization: verificationFormData?.CategoryData?.specialization,
      // retainership: verificationFormData?.retainership,
      occupation: verificationFormData?.ServiceData?.occupation,
      qualification: verificationFormData?.ServiceData?.qualification,
      certificationId: documentsFormData?.certificationId,
      yearOfPassing: documentsFormData?.yearOfPassing,
      plan: verificationFormData?.PlanData?.plan,
      documents: respDoc?.data?.ProfileDocument_id || documentsFormData?.ProfileDocument_id || '',
      expYears: verificationFormData?.CategoryData?.totalExperience?.years,
      expMonths: verificationFormData?.CategoryData?.totalExperience?.months
    }

    const sid = PropProviderId || localStorage?.getItem('LoggedinProviderId');
    const resp2 = await UpdateServiceProvider(payload, sid);
    if(resp2.status === 200){
      let respUser = {status: 200}
      if(respUser.status === 200){

        // Creating Services/Consultations

        const resp = await UpsertBulkServices(ServicesFormData, sid, verificationFormData?.CategoryData?.category)
        if(resp.status === 200){
          console.log('Service Provider Category created....', resp.data)
        }
        else{
          console.error(resp.data)
        }
        setisLoading(false);
        toast.success(mode==='edit'?'Profile Updated Successfully':'Successfully Registered As Provider');
        if(mode !== 'edit'){
          history.push('/provider/thank-you');
          return;
        }
        else if(adminView){
          history.push('/admin/service-provider');
          return
        }
        history.push('/provider/provider-dashboard');
      }
    }
    else if(resp2.status === 400){
      const error = [];
      const obj = Object.keys(resp2.data);
      for (let i = 0; i < obj.length; i++) {
        const element = obj[i][0];
        error.push(element);
      }
      Swal.fire({
        title:'Validation Error',
        text: error.join(','),
        icon: 'error'
      })
      setisLoading(false);
    }
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  // ======================================
  // ======== Get Saved Form Data =========
  // ======================================
  const getSavedFormDB = async () => {
    const spId = (ProviderId) || (PropProviderId);
    if(spId){
      const resp = await GetProviderFormData(spId);
      if(resp.status === 200){
        const savedFormData = resp.data.data;
        setverificationFormData({
          CategoryData: savedFormData?.[SaveSPFormModel[0]] || {},
          ServiceData: {...savedFormData?.[SaveSPFormModel[2]] || {}},
          PlanData: savedFormData?.[SaveSPFormModel[4]] || {},
        });
        setServicesFormData({...savedFormData?.[SaveSPFormModel[1]]});
        let savedDocData = {...savedFormData?.[SaveSPFormModel[3]]};
        const nonFileFields = {
          companyProfile: savedDocData?.companyProfile || '',
          portfolio: savedDocData?.portfolio || '',
          selfie: savedDocData?.selfie || '',
          profilePhoto: savedDocData?.profilePhoto || '',
          panNumber: savedDocData?.panNumber || '',
          identityProof: savedDocData?.identityProof || '',
          certificate: savedDocData?.certificate || '',
          websiteLink: savedDocData?.websiteLink || '',
          gstNo: savedDocData?.gstNo || '',
          gstCertificate: savedDocData?.gstCertificate || '',
          documentComment: savedDocData?.documentComment || '',
          certificationId: savedDocData?.certificationId || '',
          yearOfPassing: savedDocData?.yearOfPassing || ''
        }
        setdocumentsFormData({...nonFileFields});
      }
    }
  }

  // =======================================
  // ====== Get Data For Edit Form =========
  // =======================================
  const getFormDataForEdit = async () => {
    const sid = (ProviderId) || (PropProviderId);
    if(sid){
      const resp = await GetServiceProviderById(sid);
      const Data = resp.data.data;
      console.log('Data ' , Data, Data?.userId?.status);
      // Getting formdata fro saved state if status is hold for user
      if(Data?.userId?.status === 'Hold'){
        toast.info('Saved data found. Feteching....')
        getSavedFormDB()
        return
      }
      let formDataObj={};

      // ===== Set Document State =======
      setdocumentsFormData({ ...Data?.documents,
        certificationId: Data.certificationId,
        yearOfPassing: Data.yearOfPassing
      });

      // Data for Category Page
      let categoryData = {
        category: Data.category.category_id,
        specialization: [...Data?.specialization || []],
        totalExperience:{
          months: Data?.expMonths,
          years: Data?.expYears
        }
      }
      // Data For Service Data
      let ServiceDetailsObj ={
        catchyLine: Data?.catchyLine,
        countries: Data?.serviceCountries?.replace(' and ',',')?.split(','),
        portfolioLink: Data?.portfolioLink,
        tagline: Data?.tagline,
        skills: Data?.coreExpertise,
        companyProfile: Data?.companyProfile,
        globalSkills: Data?.globalExpertise,
        languageProficiency: Data?.languageProficiency,
        occupation: Data?.occupation,
        qualification: Data?.qualification,
        yearOfPassing: Data?.yearOfPassing,
        certificationId: Data?.certificationId,
        preferred_days_time: Data?.preferred_days_time
      };

      formDataObj = {...formDataObj, CategoryData: {...categoryData}, ServiceData: {...ServiceDetailsObj} };
      setverificationFormData(formDataObj);


      // Data for Services
      let ServiceObj ={};
      ServicesTypes.forEach(element => {
        let temp = Data.Services?.[element];
        let arr =[];
        for (let i = 0; i < temp.length; i++) {
          const elem = temp[i];
          arr.push({
            offerings: elem?.offerings,
            specialty: elem?.specialty || [],
            inclusions: elem?.inclusions || [],
            limits: elem?.limits || [],
            limitsPriceVariation: elem?.limitsPriceVariation || [],
            description: elem?.description,
            indiaPrice: elem.indiaPrice,
            outsidePrice: elem?.outsidePrice || 0,
            deliveryTime: elem?.deliveryTime,
            serviceId: elem?.service_id,
            askBeforeOrder: elem?.askBeforeOrder || false
          })
        }
        ServiceObj = {...ServiceObj, [element]: [...arr]};
      });
      setServicesFormData({...ServiceObj})

    }
  }

    useEffect(() => {
      if(mode === 'edit'){
        getFormDataForEdit();
      }
    }, []);

  return (
    <Fragment>
      <div className="p-4 md:px-20 md:py-8">
        <Backdrop className={styles.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="container-sm">
          <div className="row">
            {/* <Sidebar /> */}
            <div className="col-span-12">
              <h2 className="solvo-title">Complete Your Profile</h2>
              {/* Stepper Form */}
              <div className={styles.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel className="text-xs md:text-base">{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <form  onSubmit={handleNext} method="post">
                  <div>
                    {activeStep === steps.length ? (
                      <div>
                        <Typography className={styles.instructions}>All steps completed</Typography>
                        <Button type="button" onClick={handleReset}>
                          Reset
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Card style={{ border: "1px solid gray" }}>
                          <CardContent className={styles.formCard}>
                            {/* Stepper Contents */}
                            {activeStep === 0 && (
                              <CategoryForm
                                mode={mode}
                                FormData={verificationFormData?.CategoryData || {}}
                                ExpData={{
                                  ...verificationFormData?.CategoryData?.totalExperience,
                                }}
                                setFormData={setCategoryFormData}
                              />
                            )}

                            {activeStep === 1 && (
                              <CreateServices
                                settingsFromAdmin={settingsFromAdmin}
                                categoryId={verificationFormData?.CategoryData?.category}
                                createServicesSettings={createServicesSettings}
                                specialization={verificationFormData?.CategoryData?.specialization}
                                ServicesFormData={ServicesFormData}
                                mode={mode}
                                updateData={updateServiceFormData}
                              />
                            )}
                            {activeStep === 2 && (
                              <ServicesForm
                                PreviewData={{ ServicesFormData, verificationFormData }}
                                mode={mode}
                                FormData={verificationFormData?.ServiceData}
                                setFormData={setverificationFormData}
                                DocumentsFormData={documentsFormData}
                                setdocumentsFormData={(name, val) => {
                                  setdocumentsFormData((ps) => ({ ...ps, [name]: val }));
                                  if (mode === "edit") setdocumentsEditData((ps) => ({ ...ps, [name]: val }));
                                }}
                              />
                            )}
                            {activeStep === 3 && (
                              <ProfileDocuments
                                mode={mode}
                                FormData={documentsFormData}
                                adminView={adminView}
                                setdocumentsAcceptTnc={setdocumentsAcceptTnc}
                                setFormData={(name, val) => {
                                  setdocumentsFormData((ps) => ({ ...ps, [name]: val }));
                                  if (mode === "edit") setdocumentsEditData((ps) => ({ ...ps, [name]: val }));
                                }}
                              />
                            )}
                            {activeStep === 4 && (
                              <PricePlanForm
                                mode={mode}
                                FormData={verificationFormData}
                                setFormData={setverificationFormData}
                              />
                            )}
                            {/* Stepper Contents ENDS*/}
                          </CardContent>
                        </Card>
                        <br />

                        {/* Action Buttons */}
                        <div className="text-center">
                          <Button disabled={activeStep === 0} onClick={handleBack} className={styles.backButton}>
                            Back
                          </Button>
                          {activeStep < steps.length - 1 ? (
                            <>
                              <Button variant="contained" type="submit" className="bg-blue-500">
                                Save & Continue
                              </Button>
                            </>
                          ) : (
                            <Button variant="contained" type="button" onClick={handleSubmitForm}>
                              {mode === "edit" ? "Update Details" : "Subscribe"}
                            </Button>
                          )}
                        </div>
                        {/* Action Buttons ENDS */}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
