import React, { Component } from "react";
import SidebarNav from "../../sidebar";
import {Link} from "react-router-dom";
import { withRouter } from 'react-router'
import { AddNewCategory, GetCategoryById } from "../../../../API/CategoryAPI";

class AddSubCategories extends Component {

  constructor(props) {
	super(props);

	this.state = {
		CategoryId:'',
		CatData: {},
		formData: {status:"Active"},
	}
  }

  gotoAddPage() {
	const {history} = this.props;
	history.push('/admin/category')
  }

  componentDidMount = async () => {
	const catId = this.props.match.params.categoryid;
	const resp = await GetCategoryById(catId);
	this.setState({ CatData:{...resp.data?.Data}, CategoryId: catId});
  }

  submitCategory = async (e) => {
	e.preventDefault();
	const payload = {...this.state.formData, parentCategoryId: this.state.CategoryId};

	const resp = await AddNewCategory(payload);
	if(resp.status === 201) {
		alert("New Sub-Category Added");
		this.gotoAddPage();
	}
	else{
		alert("Some Error occurred")
	}
	}

  handleChange = (e) => {
	this.setState({
		formData: {...this.state.formData, [e.target.name]:e.target.value }
	});
}

  render() {
     
  return (
      <>
      <SidebarNav />
      <div className="page-wrapper">
			<div className="content container-fluid">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
					
						
						<div className="page-header">
							<div className="row">
								<div className="col">
									<h3 className="page-title">Add Sub Category</h3>
								</div>
							</div>
						</div>
						
						
						<div className="card">
							<div className="card-body">
								<form onSubmit={this.submitCategory}>
                                    <div className="form-group">
										<label>Category Name</label>
										<input value={this.state?.CatData?.categoryName} disabled={true} type="text" className="form-control"/>
									</div>
									<div className="form-group">
										<label>Sub-Category Name</label>
										<input className="form-control" onChange={this.handleChange} value={this.state.formData?.categoryName} required name="categoryName" type="text" />
									</div>
									<div className="form-group">
										<label>Sub-Category Description</label>
										<textarea className="form-control" onChange={this.handleChange} required name="desc" value={this.state.formData?.desc}></textarea>
									</div>
									<div className="form-group">
										<label>Status</label>
										<select className="form-control" onChange={this.handleChange} required value={this.state.formData?.status} name="status" >
											<option value="Active" selected>Active</option>
											<option value="Hold">Hold</option>
											<option value="Inactive">Inactive</option>
										</select>
									</div>
									<div className="form-group">
										<label>Sub-Category Title (For Pages Title)</label>
										<input className="form-control" onChange={this.handleChange} value={this.state.formData?.pageTitle} name="pageTitle" type="text" />
									</div>
									<div className="mt-4">
										<button className="btn btn-primary" type="submit">Add Subcategory</button>
										<Link to="/admin/category" className="btn btn-link">Cancel</Link>
									</div>
								</form>
							
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    );
  }
}

export default withRouter(AddSubCategories);
