import React, { useState, useEffect, useCallback } from "react";
import SidebarNav from "../../sidebar";
import Editor from "./Editor";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { GetPostById, UpdatePost, AddNewImage, AddNewPost, DeletePost } from '../../../../API/BlogAPI';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";


function CopyToClipboard({text}) {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success("Image Url Copied to clipboard")

}

export default function SinglePost() {
    const [content, setContent] = useState('');
    const [post, setPost] = useState({});
    const [images, setImages] = useState([]);

    const history = useHistory();
    
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let post_id = params.get('post') || null;
    
    const updatePostState = (event) => {
        const { name, value } = event.target;
        setPost(prevPost => ({ ...prevPost, [name]: value }));
    }


    useEffect(() => {
        if (post_id) {
            GetPostById(post_id)
                .then((response) => {
                    setPost(response.data)
                    setContent(response.data?.content || '')
                })
                .catch(error => {
                    console.error("Error in getting post:", error);
                });
        }
    }, [post_id])
    


    
    const handleContentSave = useCallback((event) => {
        event.preventDefault();
        if (window.confirm("Do you want to save changes?")) {
            if (post_id) {
                UpdatePost(post_id, { ...post, content })
                    .then((response) => {
                        history.push("/admin/posts");
                    })
                    .catch(error => {
                        console.error("Error updating post:", error);
                    });
            } else {
                AddNewPost({ ...post, content })
                    .then((response) => {
                        history.push("/admin/posts");
                    })
                    .catch(error => {
                        console.error("Error creating new post:", error);
                    });
            }
        }
    }, [content, history, post, post_id]);
    
    const handleContentDelete = (event) => {
        event.preventDefault()
        if (window.confirm("Do you want to delete?")){
            setPost((prev) => (
                { ...prev, 'content': content }
            ))
            DeletePost(post_id).then((response) => {
                history.push("/admin/posts")
            }).catch(error => {
                console.error("Error saving article:", error);
            });
        }
        setContent('');
    }

    const handleFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            const confirmUpload = window.confirm("Are you sure you want to upload this image?");
            if (confirmUpload) {
                const altText = prompt("Please enter the alt text for the image:", "");
                if (altText) {
                    AddNewImage(file, altText)
                        .then(response => {
                            setImages((prevImages) => [...prevImages, { image_url:response?.data?.image_url, alt: altText }]);
                        })
                        .catch(error => {
                            console.error("Error uploading image:", error);
                        });
                }
            }
            else {
                event.target.value = '';
            }
        }
    }
    
    

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    let today = year + "-" + month + "-" + day;
    
    return (
        <>
            <SidebarNav />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <form className='page-header' onSubmit={handleContentSave}>
                        <div className="row my-2">
                            {/* <div className="col">
                                <h1 className="page-title">Posts</h1>
                            </div> */}
                            <label htmlFor="title" className="mr-2">Title</label>
                            <input type="text" name="title" id="title" value={post?.title || ""} placeholder="Enter title" onChange={updatePostState} required />
                        </div>
                        <div className="row my-2">
                            <label htmlFor="description" className="mr-2">Meta Description</label>
                            <input type="text" name="description" id="description" value={post?.description || ""} placeholder="enter meta descriptions" onChange={updatePostState} maxlength="160" />
                        </div>
                        <div className="row my-2">
                            <label htmlFor="author" className="mr-2">Author</label>
                            <input type="text" name="author" value={post?.author} placeholder="Author name" onChange={updatePostState}/>
                        </div>
                        <div className="row my-2">
                            <input type="date" value={post?.updated_at ? new Date(post?.updated_at).toISOString().slice(0, 10) : today} 
                                name="updated_at" id="updated_at" placeholder="Date" onChange={updatePostState} />
                        </div>
                        <div className="row my-2">
                            <label htmlFor="tags" className="mr-2">Tags(Comma seprated)</label>
                            <input type="text" name="tags" id="tags" placeholder="Tags" onChange={updatePostState} value={post?.tags}/>
                        </div>
                        <div className="col my-2 mx-auto">
                            <label htmlFor="status" className="">Status</label>
                            <select name="status" id="status" value={!!post?.status ? '1' : '0'} onChange={updatePostState}>
                                <option value="0">Draft</option>
                                <option value="1">Publish</option>
                            </select>
                        </div>
                        <div className="row my-2">
                            <Editor setContent={setContent} content={content} />
                        </div>
                        <div className="row my-2">
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>file input</Form.Label>
                                <Form.Control className="ml-3" type="file" onChange={handleFile} />
                            </Form.Group>
                        </div>
                        {/* Uploaded images */}
                        <div className="row my-2">
                            {images?.map((image, index) => (
                                // image url with copy button
                                <div className="row" key={index}>
                                    <div className="row">
                                        <div className="col align-center">
                                            <img src={image?.image_url} alt={image?.alt} height={200} width={200} />
                                            <Button variant="primary" onClick={()=> CopyToClipboard({ text: image?.image_url }) }>Copy URL</Button>
                                        </div>
                                        <div className="col">   
                                            <p>Alt Text: {image?.alt}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col my-2 align-between">
                            <Button variant="primary" type="submit" className="mr-2">Save</Button>
                            { post_id && <Button variant="danger" onClick={handleContentDelete}>Delete</Button>}
                        </div>
                    </form>
                    
                </div>
            </div>
        </>
    )
}