import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { RegisterAdminUser, UpdateAdminUser } from '../../../API/AdminUserAPI';
import { AccessSettings } from '../../../GlobalComps/Constant';

export default function AddAdminUser({ refreshRecords, editData, open, handleClose }) {
  const [formData, setformData] = useState({});
  const [editFormData, seteditFormData] = useState({});
  const [editMode, seteditMode] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleFormChange(name, value);
  }

  const handleFormChange = (name, val) => {
    if(editMode){
        seteditFormData(ps=>({...ps, [name]:val}));
    }
    setformData(ps=>({...ps, [name]:val}));
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let resp;
    if(!editMode){
        resp = await RegisterAdminUser({...formData});
    }
    else{
        resp = await UpdateAdminUser({...editFormData});
    }
    if(resp.status === 201){
        refreshRecords();
        toast.success(editMode? 'User Updated Successfully' :'New User Added Successfully');
        handleClose();
    }
    else{
        if(typeof(resp.data)==='string')
            toast.error(resp.data);
        else{
            toast.error(Object.values(resp.data)?.join(', '));
        }
    }
  }


  const handleAccessChange = (role) => {
    let accessArr = [...formData?.adminAccessRoleSettings || []]
    // All Access Manage
    if(role === '*'){
        if(accessArr.length === 1 && accessArr?.includes('*')){
            accessArr = [];
        }
        else{
            accessArr = ['*'];
        }
        handleFormChange('adminAccessRoleSettings', accessArr);
        return;
    }
    if(accessArr.includes(role)){
        const index = accessArr.indexOf(role);
        if (index > -1) { 
            accessArr.splice(index, 1); 
        }
        return;
    }

    accessArr.push(role);
    handleFormChange('adminAccessRoleSettings', accessArr);
    
  }

  useEffect(() => {  
    if(Object.values(editData).length > 0){
        setformData({...editData});
        seteditFormData({adminUsername: editData?.adminUsername});
        seteditMode(true);
    }
  }, [editData])

  useEffect(() => {
    if(!open){
        seteditMode(false);
        setformData({});
    }
  }, [open])
  
  

  const settingsArray = formData?.adminAccessRoleSettings || [];

  return (
    <Dialog open={open} maxWidth='md' fullWidth={true} onClose={handleClose}>
        <DialogTitle>{ editMode ? "Update Admin User" : "Add Admin User" }</DialogTitle>
        <DialogContent dividers>
            <Form onSubmit={handleSubmit} method="POST" autoComplete={false}>
                <div className="form-group">
                    <label>UserName</label>
                    <input disabled={editMode} onChange={handleChange} required value={formData?.adminUsername} name="adminUsername" className="form-control" type="text" />
                </div>
                <div className="form-group">
                    <label>Create Password</label>
                    <input onChange={handleChange} required value={formData?.adminPassword} name="adminPassword" className="form-control" type="password" />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input onChange={handleChange} required value={formData?.adminEmail} name="adminEmail" className="form-control" type="email" />
                </div>
                <div className="form-group">
                    <label>Status</label>
                    <select onChange={handleChange} required value={formData?.adminStatus} name="adminStatus" className="form-control">
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Block">Block</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Access Roles</label>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check 
                                    type="checkbox" 
                                    onClick={()=>handleAccessChange('*')} 
                                    name="adminAccess" 
                                    value="*" 
                                    label="All Access" />
                            </Form.Group>
                        </Grid>
                    {
                        Object.values(AccessSettings)?.map((aRole, i) =>
                        <>{ i!==0 &&
                            <Grid item xs={6}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check 
                                        disabled={settingsArray?.includes('*')} 
                                        checked={settingsArray?.includes('*') || settingsArray?.includes(aRole)} 
                                        onClick={()=>handleAccessChange(aRole)} 
                                        type="checkbox" 
                                        name="adminAccess" 
                                        value={aRole} 
                                        label={aRole} />
                                </Form.Group>
                            </Grid>
                        }</>
                        )
                    }
                    </Grid>
                </div>
                <div className="form-group">
                    <label>Admin Role</label>
                    <select onChange={handleChange} value={formData?.adminRole} required name="adminRole" className="form-control">
                        <option value="Admin">Admin</option>
                        <option value="Support">Support</option>
                        <option value="Technical">Technical</option>
                    </select>
                </div>

                {!editMode
                 ?<Button type="submit" className="mb-4" variant="contained" color="primary">Add Admin User</Button>
                 :<Button type="submit" className="mb-4" variant="contained" color="primary">Update Admin User</Button>
                }
            </Form>
        </DialogContent>
        <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="danger">Close</Button>
        </DialogActions>
    </Dialog>
  )
}
