import React, { useEffect, useState } from 'react'
import SidebarNav from '../../sidebar';
import Editor from '../../Blog/Posts/Editor';
import { DeleteFaq, GetFaqById, UpdateFaq } from '../../../../API/FaqAPI';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

const EditFaq = () => {
const [content, setContent] = useState('');
const { faqid } = useParams();
const { categoryid } = useParams()
const history = useHistory();

const handleUpdate = () => {
   let body = {
    category: categoryid,
    content: content
   }
   UpdateFaq(faqid, body).then((response) => {
    if (response.status === 200) {
      toast.success("Faq updated successfully");
    }


    console.log(response)
  }).catch(error => {
    console.error("Error saving article:", error);
  });
}

const handleDelete = () => {

    Swal.fire({
        title: "Are you sure you want to delete the FAQ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, proceed!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          DeleteFaq(faqid).then((response) => {
            if (response.status === 204) {
              toast.success("Faq delete successfully");
              history.push(`/admin/edit-categories/${categoryid}`)
            }
            console.log(response)
          }).catch(error => {
            console.error("Error deleting faq:", error);
          });
        }
      })
 }


useEffect(() => {
    GetFaqById(faqid).then((response) => {
        console.log(response.data, "line63")
        setContent(response.data.content)
    }).catch(error => {
        console.error("Error fetching posts:", error);
        setFetchedFaqs("")
    })

}, [])



  return (
    <div>
    <SidebarNav />
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="page-header">
                <div className="row">
                    <div className="col">
                        <h3 className="page-title">Add FAQ</h3>
                    </div>
                    <div className="row my-2">
                        <p htmlFor="title" className="mr-2 font-weight-bold" style={{marginLeft:'2rem'}}> Please provide the FAQ and Answers, Question must be in  H3 tag</p>
                        <Editor setContent={setContent} content={content} />
                    </div>

                    <div className="col">
                        <button className ="btn btn-primary" style={{marginRight: '1rem'}} onClick={()=>handleUpdate()}>Update</button>
                        <button className ="btn btn-danger" onClick={()=>handleDelete()}>Delete</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditFaq
