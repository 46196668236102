import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'

export default function AddOptionForm({ addNewOptionHandler }) {
  const [inputValue, setinputValue] = useState('')
  return (
    <div>
        <Form inline className='d-flex'>
            <Form.Label htmlFor="inlineFormInputName2" srOnly>Option</Form.Label>
            <Form.Control
                style={{ width:'90%'}}
                className="mb-2 mr-sm-2 w-90"
                id="inlineFormInputName2"
                placeholder=""
                value={inputValue}
                onChange={(e)=>setinputValue(e.target.value)}
            />
            <Button 
                type="button" 
                className="mb-2" 
                onClick={()=>{addNewOptionHandler(inputValue);setinputValue('');}}
                >
                    Add
            </Button>
        </Form>
    </div>
  )
}
