export const Env = {
    API_HOSTNAME_DEV: "http://localhost:8000/solvo-api",
    API_HOSTNAME_EC2: "http://ec2-65-1-76-244.ap-south-1.compute.amazonaws.com/solvo-api",
    API_HOSTNAME: "https://mrsolvoapi.net/solvo-api",
    // API_HOSTNAME: "http://localhost:8000/solvo-api",
    S3_HOSTNAME: "https://mrsolvo.s3.ap-south-1.amazonaws.com/",
    X_SECRET_KEY: "sY7RzUEeZ3QxV1O23F3CJvGPdzF6FTChv4ADzeoSeyVWiQrfJmc58vjjR4RCrjPg"
}

export const AgoraEnv = {
    APP_ID:'cfb3141e185b4db5b1f0958045ec3fa7'
}
