import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetAllContacts(){
    try{
        const response = await axiosInstanceWithSecret.get(`/get-all-contacts`);
        return response;
    }
    catch(err){
        return err.response;
    }

 }

 export async function CreateNewContact(payload){
    try{
        const body = {
           ...payload
        }
        const response = await axiosInstanceWithSecret.post(`/create-new-contact`, body);
        return response;
    }
    catch(err){
        return err.response;
    }

 }