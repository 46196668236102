import React from 'react';
// import BoostPointsIButtonContent from "../client/components/provider/completeProfile/elems/BoostPointsIButtonContent";

export const CountriesList = [
    'Australia',
    'Bangladesh',
    'Brazil',
    'Canada',
    'China',
    'France',
    'Germany',
    'India',
    'Indonesia',
    'Italy',
    'Japan',
    'Malaysia',
    'Mexico',
    'Nigeria',
    'Pakistan',
    'Philippines',
    'Russia',
    'Saudi Arabia',
    'Singapore',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'UAE',
    'United Kingdom',
    'United States',
    'others'
];

export const LanguagesList = [
    'English',
    'Hindi',
    'Arabic',
    'Bengali',
    'Bihari',
    'Chinese',
    'Danish',
    'Dutch',
    'French',
    'German',
    'Greek',
    'Gujrati',
    'Hungarian',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Kashmiri',
    'Korean',
    'Latin',
    'Malayalam',
    'Marathi',
    'Nepali',
    'Punjabi',
    'Romani',
    'Scottish',
    'Spanish',
    'Swedish',
    'Tamil',
    'Telugu',
    'Urdu',
    'Vietnamese',
    'Others'
];

export const ServicesTypes = [
    'Consultation',
    'Service',
    'Retainership',
    'AddOnService'
]

export const ServiceTypesObj = {
    SERVICE: 'Service',
    CONSULTATION: 'Consultation',
    RETAINERSHIP: 'Retainership',
    ADDONSERVICE: 'AddOnService'
}

export const SettingNames = {
    SERVICE_PROVIDER_FORM: 'CategoryServiceProviderFormSetting',
    GENERAL_SETTING: 'GeneralSetting',
    PRICING_SETTING: 'PricingSetting',
    DYNAMIC_CONTENT: 'DynamicContent',
    CREATE_SERVICES_SETTING: 'CreateServicesSetting'
}

export const OrderStatus = {
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    INPROGRESS: 'InProgress',
    SCHEDULED: 'ScheduledConsultation',
    PARTIALLY_COMPLETED: 'PartiallyCompleted',
    COMPLETED: 'Completed',
    TICKETS: 'Tickets',
}


export const OrderStatusColor = {
    Pending: 'warning',
    Cancelled: 'danger',
    InProgress: 'primary',
    ScheduledConsultation: 'primary',
    PartiallyCompleted: 'info',
    Completed: 'success',
    Tickets: 'secondary',
}

export const ProviderCardTabs = {
    ABOUT:'About',
    DETAIL_PROFILE:'Profile',
    SERVICE_CHARGE: 'Services',
    PORTFOLIO: 'Portfolio',
    GET_QUOTE: 'get-quote',
    PRICING: 'Pricing'
}

export const SaveSPFormModel = [
    'categoryForm',
    'createServicesForm',
    'serviceDetailsForm',
    'profileDocumentForm',
    'pricePlanForm'
]

export const deliveryTimeArr = [
    '1 day',
    '2 days',
    '3 days',
    '4 days',
    '5 days',
    '6 days',
    '7 days',
    '8 days',
    '9 days',
    '10 days',
    '2 weeks',
    '3 weeks',
    '1 month',
    '1.5 month',
    '2 months',
    '3 months',
    '6 months',
    '12 months'
  ];



  export const AccessSettings = {
    ALL_ACCESS: '*',
    CATEGORY: 'Category',
    SERVICE_PROVIDER: 'ServiceProvider',
    DOCUMENTS: 'Documents',
    ADMIN_USERS:'AdminUsers',
    BOOKINGS: 'Bookings',
    SUBSCRIPTION_PLAN: 'SubscriptionPlan',
    RATINGS:'Ratings',
    USERS: 'Users',
    SETTINGS:'Settings',
    PAYMENT:'Payment',
    CONTACTS: 'Contacts',
    TRACK_BOOKING_JOURNEY: 'TrackBookingJourney'
  }

  export const MetaTitle = 'Consult Online/ Hire Experts- Business, IT, Legal, Health etc';