import React, { useContext,} from "react";
import { useParams } from "react-router-dom";
// import CompleteProfile from "../../../client/components/provider/completeProfile";
import AdminContext from "../../AdminContext/AdminContext";
import CompleteProfile from "../completeProfile";
import SidebarNav from "../sidebar";

export default function EditProfileForm() {
    const { providerId } = useParams();
    const adminContext = useContext(AdminContext);
    
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper settings">
			<div className="content container-fluid">
			
				
				<div className="page-header">
					<div className="row">
						<div className="col-12">
							<h3 className="page-title">Edit Form </h3>
						</div>
					</div>
				</div>

            <div>
                <CompleteProfile adminView={true} settingsFromAdmin={adminContext} PropProviderId={providerId} mode={'edit'}/>
            </div>
				
          </div>
        </div>        
      </>
    );
}
