import React, { Component } from "react";
import SidebarNav from "../sidebar";
import { Table } from "antd";
// import 'antd/dist/antd.css';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import {Link} from 'react-router-dom';
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
// import "../../assets/css/antdstyle.css";
import { DeleteCategory, GetAllCategories } from "../../../API/CategoryAPI";
class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
          open: false,
          show: null,
          data: [],
          loading: false
        };
      }

      handleShow = () => {
        this.setState({
          open: !this.state.open
        })
      }

    deleteCategory = async (catId) => {
      const resp = await DeleteCategory(catId);
      if(resp.status === 200){
        alert("Category Delete Successful");
        this.fetchAllCategories();
      }
      else{
        alert("Some Error Occured");
      }
    }

    fetchAllCategories = async () => {
      this.setState({ loading: true })
      const resp = await GetAllCategories();
        const data = resp.data;
        this.setState({data: [...data, ...resp.Business, ...resp.WebIt], loading: false });
    }

    componentDidMount = async () => {
      this.fetchAllCategories();
    }

    
  render() {

    const { data } = this.state;
    const columns = [
        {
          title: "Category Name",
          dataIndex: "categoryName",
          render: (text, record) => (
           <>
                <img alt="" src={record.PatientImg} />{text}
            </>
          ),
          sorter: (a, b) => a.category.length - b.category.length,
        },
        {
          title: "Description",
          dataIndex: "desc",
          render: (text, record) => (
           <>
             {text.slice(0, 30)}
             { text.length > 30 ? "...":""}
            </>
          )
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (text, record) => (
           <>
             {text}
            </>
          )
        },
        {
          title: "Subcategories",
          dataIndex: "",
          render: (text, record) => (
           <>
             <Link to={`/admin/sub-categories/${record?.category_id}`} className="text-primary" >View</Link>
            </>
          )
        },
        {
          title: 'Actions',
          render: (text, record) => (
                    <div className="actions">
                        <Link to={`/admin/edit-categories/${record?.category_id}`} className="btn btn-sm bg-success-light" ><i className="far fa-edit mr-1"></i> Edit</Link>
                        <div onClick={()=>this.deleteCategory(record?.category_id)} className="btn btn-sm bg-danger-light" ><i className="fas fa-trash-alt mr-1"></i> Delete</div>
                    </div>
                ),
        },
      
      ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			<div className="content container-fluid">
			
		
				<div className="page-header">
					<div className="row">
						<div className="col">
							<h3 className="page-title">Categories</h3>
						</div>
						<div className="col-auto text-right">
					  	  <a className="btn btn-white filter-btn ml-3" onClick={() => this.handleShow()} href={"#"} id="filter_search">
								<i className="fas fa-filter"></i>
							</a>
							<Link to="/admin/add-categories" className="btn btn-primary add-button">
								<i className="fas fa-plus"></i>
							</Link>
						</div>
					</div>
				</div>
      {
        !this.state.loading
        ?<>
          <Collapse in={this.state.open}>
          <div id="example-collapse-text">
            <div className="card filter-card">
              <div className="card-body pb-0">
                <form>
                  <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Category</label>
                        <select className="form-control select">
                          <option>Select category</option>
                          <option>Automobile</option>
                          <option>Construction</option>
                          <option>Interior</option>
                          <option>Cleaning</option>
                          <option>Electrical</option>
                          <option>Carpentry</option>
                          <option>Computer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>From Date</label>
                        <div className="cal-icon">
                          <input className="form-control datetimepicker" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>To Date</label>
                        <div className="cal-icon">
                          <input className="form-control datetimepicker" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <button className="btn btn-primary btn-block" type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        
          </Collapse>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                    { data.length > 0 &&
                      <Table
                            className="table table-hover table-center mb-0 datatable"
                            style={{ overflowX: "auto", width:"100%"}}
                            columns={columns}
                            bordered
                            dataSource={data}
                            rowKey={(record) => record.id}
                            showSizeChanger={true}
                            pagination={{
                              total: data.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                          />
                    }
                    </div>
                  </div> 
              </div>
            </div> 
        </div>
        </>
        :
        <div className="text-center py-5" style={{ width: '100%'}}>
            <h3 className="text-secondary">Loading...</h3>
        </div>
      }
        </div>
        </div>
      </>
    );
  }
}

export default Categories;
