import React, { Component } from "react";
import SidebarNav from "../../sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IMG04 from "../../../assets/images/provider/provider-01.jpg";
import { Link, withRouter } from "react-router-dom";
import { GetServiceProviderById, UpdateServiceProvider } from "../../../../API/ServiceProviderAPI";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { Env } from './../../../../Env'
import PreviewFile from "../../../../GlobalComps/PreviewFile";
import { Button } from "@mui/material";
import { Launch } from "@mui/icons-material";

class ServicesDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ServicesDetail: {},
			BoosterPoints: 0
		}
	}

	GetProviderInfo = async () => {
		const {providerId} = this.props.match.params;
		console.log(providerId);
		if(providerId){
			const resp = await GetServiceProviderById(providerId);
			this.setState({ProviderId:providerId, BoosterPoints:resp.data?.data?.boosterPoints || 0, ServicesDetail: resp.data.data});
		}
	}

	UpdatePoints = async () => {
		const {providerId} = this.props.match.params;
		const body = {
			boosterPoints: this.state.BoosterPoints
		}
		const resp = await UpdateServiceProvider(body, providerId);
		if(resp){
			Swal.fire({
				title:'Booster Points Updated Successfully',
				icon:'success'
			})
		}
	}

	componentDidMount = () => {
		this.GetProviderInfo();
	}

	componentDidUpdate = (prevProps) => {
		console.log(this.props.match.params, prevProps.match.params)
		if(this.props.match.params.providerId !== prevProps.match.params.providerId){
			this.GetProviderInfo();
		}
	}
  render() {
    var settings = {
        dots: true,
        infinite: true,
        loop:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
	const data = this.state.ServicesDetail;
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			<div className="content container-fluid">
				<div className="row">
				{/* ============= Section - 1 =================== */}
					<div className="col-lg-8">
						<div className="card">
							<div className="card-body">
								<div className="service-header">
									<div className="service-inner">
										<h2>{data?.userId?.username}</h2>
										<address className="service-location"><i className="fas fa-location-arrow"></i> {data?.city}, {data?.state}, {data?.country}</address>
										<div className="rating">
										{ Array.from({length: this.state.ServicesDetail?.userId?.rating}, (_, i) => i + 1)?.map(star => 
											<i className="fas fa-star filled"></i>
										)}
										{ Array.from({length: 5-this.state.ServicesDetail?.userId?.rating}, (_, i) => i + 1)?.map(star => 
											<i className="fas fa-star"></i>
										)}	
											<span className="d-inline-block average-rating">({data?.userId?.rating})</span>
										</div>
										<div className="service-cate">
											<a href={"#!"}>{data?.category?.categoryName}</a>
										</div>
									</div>
									<div className="service-amount">
										<span style={{fontSize:'12px'}}>{data?.catchyLine}</span>
									</div>
								</div>
								<div className="service-description">
									<div className="service-images">
								
									</div>
									<h5 className="card-title">Service Details</h5>
									<b>Language Proficiency</b>
									{ data?.languageProficiency &&
										<ul>
											{data?.languageProficiency?.map(lng =>
												<li>{lng?.language} : {lng?.rating}</li>
											)}
										</ul>
									}
								</div>
							</div>
						</div>
						<div className="card available-widget">
							<div className="card-body">
								<h5 className="card-title">Service Availability</h5>
								<ul>
								{ data?.specialization?.map((spc, index) =>
									<li><span><b>{spc?.categoryName}</b></span>Exp:{spc?.experience} <br/><span>SubSpz:{spc?.subSpecialization}</span></li>
								)}
								</ul>
							</div>
						</div>

						<div className="card available-widget">
							<div className="card-body">
								<h5 className="card-title">Profile Documents</h5>
								<table style={{width:'100%'}}>
									<tbody>
										<tr style={{borderBottom: '1px solid gray'}}>
											<td style={{ padding: '0.7rem 0px', textTransform: 'uppercase'}}><b>Address</b></td>
											<td>{`${data?.locality}, ${data?.city}, ${data?.state}, ${data?.country}, ${data?.pincode}`}</td>
										</tr>
									{ ["ProfileDocument_id", "profilePhoto", "portfolio", "companyProfile", "websiteLink", "created_at", "updated_at"]?.map((dKey, index) =>
										<tr style={{borderBottom: '1px solid gray'}} key={index}>
											<td style={{ padding: '0.7rem 0px', textTransform: 'uppercase'}}><b>{dKey}</b></td>
											<td>{
												(String(data?.documents?.[dKey])?.includes('Document'))
												? <>
												   <PreviewFile fileData={data?.documents?.[dKey]}/>
													{/* <a rel="noopener noreferrer" target="_blank" href={`${Env.S3_HOSTNAME}${data?.documents?.[dKey]}`}>
														<FontAwesomeIcon icon={faFileDownload} /> &nbsp;&nbsp;View File
												   </a> */}
												   </>
												: 
												<>{String(data?.documents?.[dKey])}</>
											}</td>
										</tr>
									)}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{/* ============ Section - 2 =========== */}
					<div className="col-lg-4">
						<div className="card provider-widget clearfix">
							<div className="card-body">
								<h5 className="card-title">Provider Detail</h5>
								<div className="about-author">
									<div className="about-provider-img">
										<div className="provider-img-wrap">
											<a href={"#!"}>
												<img className="img-fluid rounded-circle" alt="" src={IMG04} />
											</a>
										</div>
									</div>
									<div className="provider-details">
										<a href={"#!"} className="ser-provider-name">{data?.userId?.firstName} {data?.userId?.lastName}</a>
										<p className="last-seen">
										{
											(data?.userId?.online)
											?<><i className="fas fa-circle online"></i> Online</>
											:<><i style={{color:'gray'}} className="fas fa-circle online"></i> Offline</>
											
										}
										</p>
										{/* <p className="text-muted mb-1">{data?.userId?.email}</p> */}
									</div>
								</div>
								<hr />
								<div className="provider-info">
									<p className="mb-1"><i className="far fa-envelope"></i>  <a href="#!">{data?.userId?.email}</a>
									</p>
									<p className="mb-0"><i className="fas fa-phone-alt"></i> {data?.userId?.phone}</p>
								</div>
								<a href={'https://mrsolvo.com/spd/'+data?.serviceProvider_id} target="_blank" rel="noopener noreferrer">
									<Button endIcon={<Launch/>} className="mx-auto mt-5 btn btn-sm btn-primary">Open Profile On MrSolvo</Button>
								</a>
							</div>
						</div>
						
						<div className="card">
							<div className="card-body">
							<h5>Booster Points</h5>
								<form>
									<input 
										value={this.state.BoosterPoints}
										onChange={(e)=>this.setState({BoosterPoints: e.target.value})} 
										type="number" 
										className="form-control" 
										placeholder="Enter Booster Points"/>
										<br/>
									<button onClick={this.UpdatePoints} type="button" className="btn btn-sm btn-primary">Update Points</button>
								</form>
							</div>
						</div>

						<div className="card">
							<div className="card-body">
							<h5><FontAwesomeIcon icon={faMoneyBillWave}/> Selected Plan</h5>
								<form>
									<input 
										value={this.state?.plan?.plan_name || 'Not Selected'}
										readOnly
										type="Text" 
										className="form-control" 
										placeholder="Plan Name"/>
										<br/>
								</form>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<h5>Edit Provider Profile</h5>
								<Link to={`/admin/edit-provider-details/${this.state.ProviderId}`}><button className="btn btn-primary btn-sm">Edit Profile</button></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

      </>
    );
  }
}

export default withRouter(ServicesDetail);
