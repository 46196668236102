import React, { useEffect, useState } from "react";
import SidebarNav from "../sidebar";
import { Link } from "react-router-dom";
import { GetAllTermsAndConditions } from "../../../API/TermsAndConditionsAPI";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

function TermsAndConditionsLists() {
  const history = useHistory();
  const [fetchedTermsAndConditions, setFetchedTermsAndConditions] = useState(
    []
  );


  useEffect(() => {
    GetAllTermsAndConditions()
      .then((response) => {
        setFetchedTermsAndConditions(response?.data?.results);
      })
      .catch((error) => {
        console.error("Error fetching list:", error);
        setFetchedTermsAndConditions([]);
      });
  }, []);


  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h1 className="page-title">Terms And Conditions</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <Button
                  variant="primary"
                  onClick={() => history.push("/admin/clickwrap-agreement/add")}
                >
                  Add Terms And Conditions
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Version</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchedTermsAndConditions &&
                        fetchedTermsAndConditions.map((terms, index) => (
                          <tr key={`terms-${terms.id}`}>
                            <td>{index + 1}</td>
                            <td>{terms.version}</td>
                            <td>{terms.status ? "Published" : "Draft"}</td>
                            <td>{terms.created_at}</td>
                            <td>
                              <Link
                                to={`/admin/clickwrap-agreement/add?terms_id=${terms.id}`}
                              >
                                <Button variant="primary" size="sm">
                                  Edit
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditionsLists;
