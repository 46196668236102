import axios from "axios";
import { Env } from "../src/Env";

console.log(Env);

const axiosInstanceWithSecret = axios.create({
  baseURL: Env.API_HOSTNAME,
});

// Request interceptor to add the X-SECRET-KEY to the headers
axiosInstanceWithSecret.interceptors.request.use(
  (config) => {
    config.headers["X-SECRET-KEY"] = Env.X_SECRET_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstanceWithSecret;