import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Table } from "antd";
import { Collapse, Badge, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import SidebarNav from "../sidebar";
import {
  DeleteServiceProvider,
  GetAllServiceProviders,
} from "../../../API/ServiceProviderAPI";
import { UpdateUser } from "../../../API/UserAPI";
import { GetAllParentCategories } from "../../../API/CategoryAPI";
import EmailModal from "./EmailModal";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      show: null,
      data: [],
      SearchFieldInput: "",
      start_date: "",
      end_date: "",
      filters: {
        plan: "All",
        stage: "All",
        // actions: "All",
        status: "All",
        verification: "All",
        parentCategory: "All",
      },
      filterOptions: {
        plan: ["All", "Free", "Basic", "Elite", "Pro"],
        stage: ["All", "Free", "Partial", "Basic", "Pending", "Received"], // Update with actual stage options if any
        // actions: ["All"], // Update with actual action options if any
        status: ["All", "Active", "Inactive", "Hold"],
        verification: ["All", "Verified", "Unverified"],
        parentCategory: ['All'],
      },
      checkedUsers: [],
      checkedUsersUserName: [],
      totalResults: 0,
      currentPage: 1,
      page_size: 50,
      sortField: "",
      sortOrder: "",
      openEmailModal: false,
    };
  }

  componentDidMount() {
    this.getAllProvidersData();
    this.getMainCategories();
  }

  handleShow = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleCheckboxChange = (e, record) => {
    const { value, checked } = e.target;
    this.setState((prevState) => {
      const updatedCheckedUsers = new Set(prevState.checkedUsers);
      const updatedCheckedUsersUserName = new Set(prevState.checkedUsersUserName);
  
      if (checked) {
        updatedCheckedUsers.add(value); // Add value to the set
        updatedCheckedUsersUserName.add(record.userId.username);
      } else {
        updatedCheckedUsers.delete(value); // Remove value from the set
        updatedCheckedUsersUserName.delete(record.userId.username);
      }
  
      return {
        ...prevState,
        checkedUsers: [...updatedCheckedUsers], // Convert back to array
        checkedUsersUserName: [...updatedCheckedUsersUserName],
      };
    });
  };

  handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      this.setState({ checkedUsers: this.state.data.map((user) => user.userId.id),
        checkedUsersUserName: this.state.data.map((user) => user.userId.username),
       });
    } else {
      this.setState({ 
        checkedUsers: [],
        checkedUsersUserName: [],
       });
    }
  };

  getMainCategories = async () => {
    const resp = await GetAllParentCategories(true);
    if (resp.status === 200) {
      this.setState({
        filterOptions: {
          ...this.state.filterOptions,
          parentCategory: ["All", ...resp.data.data],
        },
      });
    } else {
      toast.error("Error fetching categories");
    }
  };

  getAllProvidersData = async (
    page = 1,
    page_size = 50,
    filters = {},
    sortField = "",
    sortOrder = "",
    search = "",
    start_date = "",
    end_date = ""
  ) => {
    const query = new URLSearchParams({
      page,
      page_size,
      sortField,
      sortOrder,
      search,
      start_date,
      end_date,
      ...filters,
    }).toString();

    const resp = await GetAllServiceProviders(`?${query}`);

    this.setState({
      data: resp.data.data,
      totalResults: resp.data.total_results,
      currentPage: resp.data.current_page,
      page_size: resp.data.page_size,
      sortField,
      sortOrder,
    });
  };

  toggleVerification = async (verified, userId) => {
    const resp = await UpdateUser({ verified }, userId);
    if (resp.status === 200) {
      this.getAllProvidersData(
        this.state.currentPage,
        this.state.page_size,
        this.state.filters,
        this.state.sortField,
        this.state.sortOrder,
        this.state.SearchFieldInput,
        this.state.start_date,
        this.state.end_date
      );
      toast.success(
        `Service Provider ${verified ? "verified" : "unverified"} successfully`
      );
    } else {
      toast.error("Some error occurred");
    }
  };

  handleChangeStatus = async (userId, currentStatus) => {
    const newStatus = currentStatus === "Active" ? "Inactive" : "Active";
    const resp = await UpdateUser({ status: newStatus }, userId);
    if (resp.status === 200) {
      this.getAllProvidersData(
        this.state.currentPage,
        this.state.page_size,
        this.state.filters,
        this.state.sortField,
        this.state.sortOrder,
        this.state.SearchFieldInput,
        this.state.start_date,
        this.state.end_date
      );
      toast.success("Status updated successfully");
    }
  };

  deleteProvider = async (providerId) => {
    Swal.fire({
      title: "Delete this Service Provider?",
      text: "This will disable the user to log in and they will have to register again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteServiceProvider(providerId).then((res) => {
          if (res.status === 200) {
            this.getAllProvidersData(
              this.state.currentPage,
              this.state.page_size,
              this.state.filters,
              this.state.sortField,
              this.state.sortOrder,
              this.state.SearchFieldInput,
              this.state.start_date,
              this.state.end_date
            );
            toast.success("Service Provider deleted successfully");
          } else {
            toast.error("Some error occurred");
          }
        });
      }
    });
  };

  handleFilterChange = (field, value) => {
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, [field]: value },
      }),
      () => {
        this.getAllProvidersData(
          1,
          this.state.page_size,
          this.state.filters,
          this.state.sortField,
          this.state.sortOrder,
          this.state.SearchFieldInput,
          this.state.start_date,
          this.state.end_date
        );
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortField = sorter.field || "";
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    this.getAllProvidersData(
      pagination.current,
      pagination.pageSize,
      this.state.filters,
      sortField,
      sortOrder,
      this.state.SearchFieldInput,
      this.state.start_date,
      this.state.end_date
    );
  };

  render() {
    const { data, filterOptions, filters, checkedUsers } = this.state;
    const columns = [
      {
        title: <input type="checkbox" onChange={this.handleSelectAll} />,
        // title: "checkbox",
        dataIndex: "id",
        render: (text, record, index) => {
          return (
          <>
            <label className="checkbox checkbox-single" for="checkedUsers" />
            <input type="checkbox"  className="form-check-input" value={record?.userId?.id} checked={checkedUsers.includes(record?.userId?.id)} name="checkedUsers" onChange={(e) => this.handleCheckboxChange(e, record)} />
          </>
          )
        }
      },
      {
        title: "Provider Username",
        dataIndex: "username",
        render: (text, record) => record?.userId?.username,
        sorter: true,
      },
      {
        title: "Category",
        dataIndex: "category",
        render: (text, record) => record?.category?.categoryName,
      },
      {
        title: "Contact No",
        dataIndex: "contact",
        render: (text, record) => record?.userId?.phone,
        // sorter: true,
      },
      {
        title: "Reg Date",
        dataIndex: "created_at",
        render: (text, record) =>
          moment(record?.created_at).format("DD-MMM-yyyy"),
        sorter: true,
      },
      {
        title: "Update Date",
        dataIndex: "updated_at",
        render: (text, record) =>
          moment(record?.updated_at).format("DD-MMM-yyyy"),
        sorter: true,
      },
      {
        title: "Plan",
        dataIndex: "plan",
        render: (text, record) => record?.plan?.plan_name,
        // sorter: true,
      },
      {
        title: "Stage",
        dataIndex: "stage",
        // render: (text) => text,
      },
      {
        title: "Actions",
        render: (text, record) => (
          <div className="actions">
            <Link
              to={`/admin/provider-details/${record?.serviceProvider_id}`}
              className="btn btn-sm bg-success-light"
            >
              <i className="fe fe-eye"></i> View
            </Link>
            <Button
              onClick={() => this.deleteProvider(record?.serviceProvider_id)}
              variant="link"
              style={{ cursor: "pointer", color: "red" }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => (
          <>
            <Badge
              className={`fs-6 bg-${
                record?.userId?.status === "Active"
                  ? "success"
                  : record?.userId?.status === "Hold"
                  ? "warning"
                  : "secondary"
              }`}
            >
              {record?.userId?.status}
            </Badge>
            <Button
              size="sm"
              onClick={() =>
                this.handleChangeStatus(
                  record?.userId?.userId,
                  record?.userId?.status
                )
              }
              variant="link"
            >
              Make {record?.userId?.status === "Active" ? "Inactive" : "Active"}
            </Button>
          </>
        ),
        // sorter: true,
      },
      {
        title: "Verification",
        dataIndex: "verified",
        render: (text, record) => (
          <>
            {record?.userId?.verified ? (
              <Button
                onClick={() =>
                  this.toggleVerification(false, record?.userId.userId)
                }
                variant="secondary"
              >
                Un-verify
              </Button>
            ) : (
              <Button
                onClick={() =>
                  this.toggleVerification(true, record?.userId.userId)
                }
                variant="primary"
              >
                Verify Now
              </Button>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Service Providers</h3>
                </div>
                <div className="col-auto text-right">
                  <Button
                    className="btn btn-white filter-btn"
                    onClick={this.handleShow}
                  >
                    <i className="fas fa-filter"></i>
                  </Button>
                </div>
              </div>
            </div>

            <Collapse in={this.state.open}>
              <div id="filter-collapse">
                <div className="card filter-card">
                  <div className="card-body pb-0">
                    <form>
                      <div className="row filter-row">
                        {Object.keys(filters).map((field) => (
                          <div key={field} className="col-sm-6 col-md-3">
                            <div className="form-group">
                              <label>
                                {field.charAt(0).toUpperCase() + field.slice(1)}
                              </label>
                              <select
                                className="form-control select"
                                // value={filters[field]}
                                defaultValue={""}
                                onChange={(e) =>
                                  this.handleFilterChange(field, e.target.value)
                                }
                              >
                                {filterOptions[field]?.map((option) =>
                                  field !== "parentCategory" ? (
                                    <option value={option} key={option}>
                                      {option}
                                    </option>
                                  ) : (
                                    <option
                                      value={option.category_id || "All"}
                                      key={option.category_id || "All"}
                                    >
                                      {option.categoryName || "All"}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        ))}

                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label id="start_date">Registration From Date</label>
                            <input
                              htmlFor="start_date"
                              className="form-control datetimepicker"
                              type="date"
                              value={this.state.start_date}
                              onChange={(e) =>
                                this.setState({ start_date: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <label id="end_date">Registration Till Date</label>
                            <input
                              value={this.state.end_date}
                              htmlFor="end_date"
                              className="form-control datetimepicker"
                              type="date"
                              onChange={(e) =>
                                this.setState({ end_date: e.target.value })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-3">
                          <div className="form-group">
                            <Button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() =>
                                this.getAllProvidersData(
                                  1,
                                  this.state.page_size,
                                  this.state.filters,
                                  this.state.sortField,
                                  this.state.sortOrder,
                                  this.state.SearchFieldInput,
                                  this.state.start_date,
                                  this.state.end_date
                                )
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Collapse>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <TextField
                      variant="standard"
                      type="text"
                      label="Search Service Provider"
                      placeholder="Search Service Provider"
                      className="w-25"
                      name="SearchFieldInput"
                      value={this.state.SearchFieldInput}
                      onChange={(e) =>
                        this.setState({ SearchFieldInput: e.target.value })
                      }
                    />
                    <Button
                      onClick={() =>
                        this.getAllProvidersData(
                          this.state.currentPage,
                          this.state.page_size,
                          this.state.filters,
                          this.state.sortField,
                          this.state.sortOrder,
                          this.state.SearchFieldInput,
                          this.state.start_date,
                          this.state.end_date
                        )
                      }
                      className="bg-primary text-light"
                      variant="contained"
                      color="secondary"
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() =>(this.setState({ openEmailModal: true }))}
                      disabled={this.state.checkedUsers.length === 0}
                    >
                      Email &nbsp;{this.state.checkedUsers.length}
                    </Button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table table-hover table-center mb-0 datatable"
                        style={{ overflowX: "auto", width: "100%" }}
                        columns={columns}
                        dataSource={data}
                        rowKey={(record) => record.serviceProvider_id}
                        pagination={{
                          total: this.state.totalResults,
                          current: this.state.currentPage,
                          page_size: this.state.page_size,
                          defaultPageSize: 50,
                          onChange: (page, page_size) =>
                            this.getAllProvidersData(
                              page,
                              page_size,
                              this.state.filters,
                              this.state.sortField,
                              this.state.sortOrder,
                              this.state.SearchFieldInput,
                              this.state.start_date,
                              this.state.end_date
                            ),
                          showSizeChanger: true,
                          onShowSizeChange: (current, size) =>
                            this.getAllProvidersData(
                              this.state.currentPage,
                              size,
                              this.state.filters,
                              this.state.sortField,
                              this.state.sortOrder,
                              this.state.SearchFieldInput,
                              this.state.start_date,
                              this.state.end_date
                            ),
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        }}
                        onChange={this.handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EmailModal openModal={this.state.openEmailModal} 
          handleClose={() => this.setState({ openEmailModal: false })} 
          handleOpen={() => this.setState({ openEmailModal: true })} 
          checkedUsers={this.state.checkedUsers}
          emptyCheckedUsers={() => this.setState({ checkedUsers: [] })}
          checkedUsersUserName={this.state.checkedUsersUserName}
        />
      </>
    );
  }
}

export default Services;
