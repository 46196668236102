import React, { Component } from "react";
import SidebarNav from "../sidebar";
import { Table } from "antd";
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
// import "../../assets/css/antdstyle.css";
import { DeleteCategory, GetCategoryById } from "../../../API/CategoryAPI";

class SubSubCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
        
          show: null,
          open: false,
          data: [],
        };
      }

      handleShow = () => {
        this.setState({
          open: !this.state.open
        })
      }

      deleteCategory = async (catId) => {
        const resp = await DeleteCategory(catId);
        if(resp.status === 200){
          alert("Category Delete Successful");
          this.fetchAllCategories();
        }
        else{
          alert("Some Error Occured");
        }
      }

      componentDidMount =async () => {
        const catId = this.props.match.params.categoryid;
        const resp = await GetCategoryById(catId);
        if(resp.status === 200){
          this.setState({ CatData:{...resp.data?.Data}, CategoryId: catId, data:[...resp.data?.Data?.SubCategories ]});
        }
      }
    
  render() {

    const { data } = this.state;
    const columns = [
      {
        title: "Category Name",
        dataIndex: "categoryName",
        render: (text, record) => (
         <>
              <img alt="" src={record.PatientImg} />{text}
          </>
        ),
        sorter: (a, b) => a.category.length - b.category.length,
      },
      {
        title: "Description",
        dataIndex: "desc",
        render: (text, record) => (
         <>
           {text.slice(0, 30)}
           { text.length > 30 ? "...":""}
          </>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => (
         <>
           {text}
          </>
        )
      },
      {
        title: 'Actions',
        render: (text, record) => (
                  <div className="actions">
                      <Link to={`/admin/edit-categories/${record?.category_id}`} className="btn btn-sm bg-success-light" ><i className="far fa-edit mr-1"></i> Edit</Link>
                      <div onClick={()=>this.deleteCategory(record?.category_id)} className="btn btn-sm bg-danger-light" ><i className="fas fa-trash-alt mr-1"></i> Delete</div>
                  </div>
              ),
      },
    
    ];
  
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
			<div className="content container-fluid">
      <h4 style={{color:'blue'}}>Sub-Category: <b>{this.state.CatData?.categoryName}</b></h4>
		
				<div className="page-header">
					<div className="row">
						<div className="col">
							<h3 className="page-title">Sub-Sub Categories</h3>
						</div>
						<div className="col-auto text-right">
        	    <button className="btn btn-white filter-btn" onClick={() => this.handleShow()}  id="filter_search">
								<i className="fas fa-filter"></i>
							</button>
							<Link to={`/admin/add-subcategories/${this.state?.CategoryId}`} className="btn btn-primary add-button ml-3">
								<i className="fas fa-plus"></i>
							</Link>
						</div>
					</div>
				</div>

        <Collapse in={this.state.open}>
        <div id="example-collapse-text">
        <div className="card filter-card">
          <div className="card-body pb-0">
            <form>
								<div className="row filter-row">
									<div className="col-sm-6 col-md-3">
										<div className="form-group">
											<label>Sub Category</label>
											<input className="form-control" type="text" />
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="form-group">
											<label>From Date</label>
											<div className="cal-icon">
												<input className="form-control datetimepicker" type="text" />
											</div>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="form-group">
											<label>To Date</label>
											<div className="cal-icon">
												<input className="form-control datetimepicker" type="text" />
											</div>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="form-group">
											<button className="btn btn-primary btn-block" type="submit">Submit</button>
										</div>
									</div>
								</div>
							</form>
              </div>
              </div>
          </div>
      </Collapse>
   


        <div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
								{ data.length > 0 ?
                  <Table
                        className="table table-hover table-center mb-0 datatable"
                        style={{ overflowX: "auto", width:"100%"}}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                  : <div className="text-center">
                    <h4>No Data Found</h4>
                    <p className="text-default">Add Category from above "+" button</p>

                  </div>
                }
                 </div>
              </div> 
           </div>
        </div> 
     </div>               


        </div>
        </div>
      </>
    );
  }
}

export default SubSubCategories;
