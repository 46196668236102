import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function AddNewServiceProvider(body){
    const response = await axiosInstanceWithSecret.post(`/create-serviceProvider`, body);
    return response;
 }

 export async function CreateNewDocument(body){
    const response = await axiosInstanceWithSecret.post(`/create-document`, body,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
 }

 export async function UpdateDocumentById(body, docId){
    try{
        const response = await axiosInstanceWithSecret.put(`/update-document`, body,{
            params:{
                document_id: docId
            },
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }
 }

 export async function UpdateServiceProvider(body, providerid){
    try{
        const response = await axiosInstanceWithSecret.put(`/update-serviceProvider`, body, { 
            params:{
                'serviceProvider_id': providerid
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }
 }

 export async function DeleteServiceProvider(providerid){
    try{
        const response = await axiosInstanceWithSecret.delete(`/delete-serviceProvider`, { 
            params:{
                'serviceProvider_id': providerid
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }
 }



export const GetAllServiceProviders = async (params) => {
//   const queryParams = new URLSearchParams(filters);
  const response = await axiosInstanceWithSecret.get(
    `/get-all-serviceProvider${params}`
  );
  return response;
};


 export async function GetServiceProviderById(providerid){
    const response = await axiosInstanceWithSecret.get(`/get-serviceProvider-by-id`, { 
        params:{
            'serviceProvider_id': providerid
        }
    });
    return response;
 }

 export async function GetServiceProviderByCatId(catid){
    const response = await axiosInstanceWithSecret.get(`/get-serviceProvider-by-category-id`, { 
        params:{
            'category_id': catid
        }
    });
    return response;
 }

 export async function GetServiceProviderByCatName(catname){
    const response = await axiosInstanceWithSecret.get(`/get-serviceProvider-by-category-id`, { 
        params:{
            'category_name': catname,
        }
    });
    return response;
 }

 export async function SearchProviders(body){
    const response = await axiosInstanceWithSecret.post(`/search-service-provider`, body);
    return response;
 }


 export async function GetFilterKeys(){
    const response = await axiosInstanceWithSecret.get(`/get-filter-keys`);
    return response;
 }


// =========== Save Form APIS ==============
export async function GetProviderFormData(servId){
    try{
        const response = await axiosInstanceWithSecret.get(`/get-saved-form-data`,{
            params:{
                serviceProviderId: servId
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }
}


export async function SaveProviderFormData(body, servId){
    try{
        const response = await axiosInstanceWithSecret.put(`/save-service-provider-form`, body,{
            params:{
                serviceProviderId: servId
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }
}

 