import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function AddNewService(body){
    const response = await axiosInstanceWithSecret.post(`/create-service`, body, {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
 }

 export async function UpdateService(serviceId, body){
    try{
        const response = await axiosInstanceWithSecret.patch(`/update-service`, body, {
            params:{
                service_id: serviceId
            },
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }
    catch(err){
        return err.response;
    }

 }

 export async function GetAllServices(){
    const response = await axiosInstanceWithSecret.get(`/get-all-services`);
    return response;
 }

 export async function GetServiceById(serviceId){
    const response = await axiosInstanceWithSecret.get(`/get-service-by-id`, { params: { service_id: serviceId}});
    return response;
 }

 export async function GetServicesByServiceType(spid){
    const response = await axiosInstanceWithSecret.get(`/get-services-by-servicetype`, {
        params:{
            serviceProviderId: spid
        }
    });
    return response;
 }

 export async function GetServicesByCategoryId(catId, mode='all'){
    const response = await axiosInstanceWithSecret.get(`/get-service-by-category`, 
        { 
            params: {
                category_id: catId,
                mode: mode
            }
    });
    return response;
 }

 export async function UpsertBulkServices(serviceFormObj, providerId, category){
    try{
        const response = await axiosInstanceWithSecret.post(`/upsert-service-bulk`, serviceFormObj, {
            params: {
                serviceProviderId: providerId,
                categoryId: category
            }
        })
        return response
    }
    catch(err){
        return err.response
    }
 }