import React, { useState } from "react";
import solvo_logo from '../../assets/images/solvo_logo.png'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { jsPDF } from "jspdf";

const AgreementCard = ({ version, acceptedAt, acceptedIP }) => (
  <div
    style={{
      borderTop: "1px solid #e0e0e0",
      paddingTop: "16px",
      marginTop: "16px",
    }}
  >
    <Typography variant="subtitle1">
      Agreement Version: <strong>{version}</strong>
    </Typography>
    <Typography variant="subtitle1">
      Accepted at: <strong>{new Date(acceptedAt).toLocaleString()}</strong>
    </Typography>
    <Typography variant="subtitle1">
      Accepted IP: <strong>{acceptedIP}</strong>
    </Typography>
  </div>
);

const UserAgreementModal = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const user = {
    userName: data[0].username,
    email: data[0].email,
    phone: data[0].phone,
  };

  const agreements = data;

const printToPDF = () => {
  const doc = new jsPDF();
  // const logoUrl =
  //   "https://cdn.pixabay.com/photo/2016/12/27/13/10/logo-1933884_640.png"; // Replace with your logo URL
  const currentUrl = window.location.href;

  // Adding URL to the top left corner in small size
  doc.setFontSize(10);
  doc.text(currentUrl, 10, 10);

  // Adding logo to the center
  const logoWidth = 50;
  const logoHeight = 20;
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.addImage(
    solvo_logo,
    "PNG",
    (pageWidth - logoWidth) / 2,
    20,
    logoWidth,
    logoHeight
  );

  // Setting the body text size to 17px
  doc.setFontSize(17);

  // Adding user information to the PDF
  doc.text(`User Name: ${user.userName}`, 10, 60);
  doc.text(`Email: ${user.email}`, 10, 70);
  doc.text(`Phone: ${user.phone}`, 10, 80);

  // Add horizontal line
  doc.setLineWidth(0.5);
  doc.line(10, 85, pageWidth - 10, 85);
  doc.setFontSize(14);
  // Adding agreements to the PDF
  let yOffset = 100;
  agreements.forEach((agreement) => {
    doc.text(`Agreement Version: ${agreement.version}`, 10, yOffset);
    doc.text(
      `Accepted at: ${new Date(agreement.accepted_at).toLocaleString()}`,
      10,
      yOffset + 10
    );
    doc.text(`Accepted IP: ${agreement.accepted_ip}`, 10, yOffset + 20);
    yOffset += 30;
  });

  // Saving the PDF
  doc.save("user_agreement.pdf");
};

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsVisible(true)}
      >
        View User Agreement
      </Button>
      <Dialog
        open={isVisible}
        onClose={() => setIsVisible(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          User Information
          <Button
            onClick={() => setIsVisible(false)}
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              backgroundColor: "red",
              color: "white",
            }}
          >
            &times;
          </Button>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                User Name: <strong>{user.userName}</strong>
              </Typography>
              <Typography variant="h6">
                Email: <strong>{user.email}</strong>
              </Typography>
              <Typography variant="h6">
                Phone: <strong>{user.phone}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {agreements.map((agreement, index) => (
                <AgreementCard
                  key={index}
                  version={agreement.version}
                  acceptedAt={agreement.accepted_at}
                  acceptedIP={agreement.accepted_ip}
                />
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={printToPDF} color="primary">
            Print to PDF
          </Button>
          <Button onClick={() => setIsVisible(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAgreementModal;